import {Badge, Box, Grid, MenuItem} from "@mui/material";
import React from "react";

export function MenuItemAdorned(props) {
    let left = <></>;
    if (props.startIcon) {
        const Icon = props.startIcon;
        if ('startIconErrors' in props) {
            left = (
                <Badge badgeContent={props.startIconErrors} color={props.startIconErrors > 0 ? "error" : "primary"}>
                    <Icon size="small" color="neutral" />
                </Badge>
            );
        } else {
            left = <Icon size="small" color="neutral" />;
        }
    } else if (props.startAdornment) {
        left = props.startAdornment;
    }

    return (
    <MenuItem {...props.MenuItemProps}>
        <Grid container sx={{width: 1}} spacing={2}>
            <Grid item xs={3}>
                <Box justifyContent="center" display="flex">
                    {left}
                </Box>
            </Grid>
            <Grid item xs={9}>
                {props.children}
            </Grid>
        </Grid>
    </MenuItem>
    );
}