import * as React from 'react';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {Box, Paper} from "@mui/material";

const drawerBleeding = 56;

const StyledBox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800]
}));

const Puller = styled('div')(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

function SwipeableEdgeDrawer(props) {
    return (
        <>
            <Box sx={{display: {md: 'none'}, height: drawerBleeding + 24}}></Box>
            <Paper elevation={12} sx={{height: '100%'}}>
                <SwipeableDrawer
                    anchor="bottom"
                    open={props.open}
                    onClose={props.toggleDrawer(false)}
                    onOpen={props.toggleDrawer(true)}
                    swipeAreaWidth={drawerBleeding}
                    disableSwipeToOpen={false}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        ...props.sx,
                        '.MuiPaper-root': {
                            height: `calc(50% - ${drawerBleeding}px)`,
                            overflow: 'visible',
                        },
                    }}
                >
                    <StyledBox
                        sx={{
                            boxShadow:
                                '1px -2px 2px rgba(0, 0, 0, 0.02),' +
                                '2px -4px 4px rgba(0, 0, 0, 0.02),' +
                                '3px -6px 6px rgba(0, 0, 0, 0.02)',
                            position: 'absolute',
                            top: -drawerBleeding,
                            height: drawerBleeding,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            visibility: 'visible',
                            right: 0,
                            left: 0,
                        }}
                    >
                        <Puller />
                        {props.preview}
                    </StyledBox>
                    <StyledBox
                        sx={{
                            px: 2,
                            pb: 2,
                            height: '100%',
                            overflow: 'auto',
                        }}
                    >
                        {props.content}
                    </StyledBox>
                </SwipeableDrawer>
            </Paper>
        </>
    );
}

export default SwipeableEdgeDrawer;