import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import moment from "moment";
import 'moment/locale/it';

i18next
    .use(HttpApi).init({
    backend: {
        loadPath: process.env.PUBLIC_URL + '/locales/{{lng}}/{{ns}}.json',
        addPath: process.env.PUBLIC_URL + '/locales/add/{{lng}}/{{ns}}'
    }
});

i18n
    .use(initReactI18next)
    .init({
        debug: false, // devEnv()
        load: 'languageOnly',
        lng: "it",
        fallbackLng: "en",
        ns: 'locale',
        interpolation: {
            escapeValue: false
        }
    });

moment.locale("it");