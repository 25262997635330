import React, {useCallback, useEffect, useState} from "react";
import AppConsts from "../app/AppConsts";
import {
    Alert, Box, Card, CardActionArea, CircularProgress,
    Collapse, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    IconButton, Stack,
    TextField, Typography
} from "@mui/material";
import {
    AddOutlined as AddOutlinedIcon,
    ArrowBack as ArrowBackIcon, CropFreeOutlined, UploadFileOutlined
} from "@mui/icons-material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import LargeButton from "../components/LargeButton";
import {useErrors} from "../hooks/useErrors";
import {useHistory} from "react-router";
import {PathGenerator} from "../app/PageRouting";
import {useDialog} from "../providers/DialogProvider";
import {FORM} from "../utils/formUtils";
import {logError} from "../utils/logging";
import {FORM_TEMPLATES} from "./FormTemplates";
import {Theme} from "../app/Theme";
import {useSnackbar} from "../providers/SnackbarProvider";
import {httpClient} from "../utils/httpClient";
import {t} from "i18next";

function FormCreateDialogFile(props) {
    const goBack = props.onGoBack;

    const {closeDialog} = useDialog();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const {errors, setErrors} = useErrors({
        mapper: ({details, message}) => {
            return {
                key: 'import',
                message: 'questionNum' in details
                    ? `[question #${details.questionNum}] ${message}`
                    : message
            }
        }
    });

    const sendFile = (event) => {
        const formData = new FormData();
        const inputFile = event.target;
        formData.append("file", inputFile.files[0]);

        setLoading(true);

        httpClient.post(`${AppConsts.API_PATH}/forms/import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            setLoading(false);
            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                closeDialog();
                history.push(PathGenerator.formEditor(response.data.id));
            }
        }).catch((e) => {
            logError(e);
            setLoading(false);
        });
    };

    return <>
        <DialogTitle>
            <IconButton onClick={goBack} sx={{mr: 1}}>
                <ArrowBackIcon/>
            </IconButton>
            {t('new_form.new_form_title')}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {t('new_form.please_select_a_json_file')}
            </DialogContentText>
            <Stack sx={{my: 5}} alignItems="center">
                <input
                    accept="*"
                    style={{display: 'none'}}
                    id="upload-button-file"
                    type="file"
                    onChange={(e) => sendFile(e)}
                    disabled={loading}
                />
                <label htmlFor="upload-button-file">
                    <Button variant="contained" component="span" disabled={loading}>
                        {t('common.select_a_file')}
                    </Button>
                </label>
                {loading &&
                    <Box sx={{mt: 4}}><CircularProgress/></Box>}
                {errors?.import && errors.import.map((error, errorKey) =>
                    <Alert severity="error" key={errorKey} sx={{mt: 4}}>{error}</Alert>)}
            </Stack>
        </DialogContent>
    </>
}

function FormCreateDialogScratch(props) {
    const goBack = props.onGoBack;

    const CLOSE_DIRTY_CONFIRM = t('common.any_unsaved_data_will_be_lost');
    const {setCanCloseDialog, closeDialog, closeDialogWithConfirm} = useDialog();
    const [loading, setLoading] = useState(false);
    const {errors, setErrors, resetErrors} = useErrors({
        mapper: ({details, message}) => {
            return {
                key: 'inputName' in details ? details.inputName : 'create',
                message: message
            };
        }
    });
    
    const [form, setForm] = useState({
        title: "",
        description: "",
        dirty: false
    });
    const history = useHistory();

    useEffect(() => {
        setCanCloseDialog({
            active: !form.dirty,
            message: CLOSE_DIRTY_CONFIRM
        });
    }, [form.dirty, setCanCloseDialog, CLOSE_DIRTY_CONFIRM]);

    const goBackWithConfirm = () => {
        if (!form.dirty || window.confirm(CLOSE_DIRTY_CONFIRM)) {
            goBack();
        }
    };
    
    const handleChange = useCallback((name, value) => {
        switch (name) {
            case 'title':
                setForm(form => ({...form, title: value, dirty: true}));
                resetErrors('title');
                break;
            case 'description':
                setForm(form => ({...form, description: value, dirty: true}));
                resetErrors('description');
                break;
            default:
        }
    }, [resetErrors]);

    const onSubmit = (e) => {
        setLoading(true);

        httpClient.post(`${AppConsts.API_PATH}/forms`, {
            title: form.title,
            description: form.description
        }).then(response => {
            setLoading(false);
            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                setForm(form => ({...form, dirty: false}));
                closeDialog();
                history.push(PathGenerator.formEditor(response.data.form.id));
            }
        }).catch((e) => {
            logError(e);
            setLoading(false);
        });

        e.preventDefault();
    };

    return <>
        <DialogTitle>
            <IconButton onClick={goBackWithConfirm} sx={{mr: 1}}>
                <ArrowBackIcon/>
            </IconButton>
            {t('new_form.new_form_title')}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {t('new_form.please_choose_a_title_for')}
            </DialogContentText>
            <Box sx={{mt: 2}}>
                <form onSubmit={onSubmit}>
                    <TextField
                        autoFocus
                        margin="dense"
                        label={t('form.title')}
                        type="text"
                        fullWidth
                        error={'title' in errors}
                        helperText={errors?.title?.join(' - ')}
                        inputProps={{maxLength: FORM.TITLE_MAX_LENGTH}}
                        onChange={(e) => handleChange('title', e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label={t('form.description')}
                        type="text"
                        fullWidth
                        multiline
                        rows={3}
                        error={'description' in errors}
                        helperText={errors?.description?.join(' - ')}
                        inputProps={{maxLength: FORM.DESCRIPTION_MAX_LENGTH}}
                        onChange={(e) => handleChange('description', e.target.value)}
                    />
                </form>
            </Box>
            {errors?.create && errors.create.map((error, errorKey) =>
                <Alert severity="error" key={errorKey} sx={{mt: 2}}>{error}</Alert>)}
        </DialogContent>
        <DialogActions>
            <LoadingButton onClick={onSubmit}
                           loading={loading}
                           loadingIndicator="Loading..."
                           startIcon={<AddOutlinedIcon/>}>
                {t('new_form.create_form_button')}
            </LoadingButton>
            <Button onClick={closeDialogWithConfirm} color="secondary">{t('common.cancel')}</Button>
        </DialogActions>
    </>
}

function FormCreateDialog() {
    const [modeState, setModeState] = useState('init');
    const {setCanCloseDialog, closeDialog} = useDialog();

    const setMode = (mode) => {
        setCanCloseDialog({
            active: true
        });
        setModeState(mode);
    };

    const {createSnackbar} = useSnackbar();
    const history = useHistory();

    const handleCreateFromTemplate = (data) => {
        httpClient.post(`${AppConsts.API_PATH}/forms`, data).then(response => {
            if (response.data.errors) {
                response.data.errors.forEach(e =>
                    createSnackbar({
                        message: e.message,
                        autoHideDuration: 2000,
                        type: "error"
                    })
                );

            } else {
                createSnackbar({
                    message: t('new_form.form_created_'),
                    autoHideDuration: 2000,
                    type: "success"
                });
                closeDialog();
                history.push(PathGenerator.formEditor(response.data.form.id));
            }
        }).catch((e) => {
            logError(e);
        });
    };

    return <>
        <Collapse in={modeState === 'file'}>
            <Box>
                <FormCreateDialogFile onGoBack={() => setMode('init')} />
            </Box>
        </Collapse>
        <Collapse in={modeState === 'scratch'}>
            <Box>
                <FormCreateDialogScratch onGoBack={() => setMode('init')} />
            </Box>
        </Collapse>
        <Collapse in={modeState === 'init'}>
            <Box>
                <DialogTitle>{t('new_form.new_form_title')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <LargeButton onClick={() => setMode('file')}>
                                <Stack spacing={2} alignItems="center">
                                    <UploadFileOutlined sx={{fontSize: 40}} />
                                    <Typography>{t('new_form.create_from_file')}</Typography>
                                </Stack>
                            </LargeButton>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LargeButton onClick={() => setMode('scratch')}>
                                <Stack spacing={2} alignItems="center">
                                    <CropFreeOutlined sx={{fontSize: 40}} />
                                    <Typography>{t('new_form.create_from_scratch')}</Typography>
                                </Stack>
                            </LargeButton>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogContent sx={{bgcolor: Theme.palette.background.default, py: 4, display: 'none'}}>
                    <Typography color="text.primary" sx={{mb: 2}}>
                        {t('new_form.start_from_a_template_form')}
                    </Typography>
                    <Grid container spacing={2}>
                        {FORM_TEMPLATES.map((form, index) => {
                            const FormIcon = form.icon;
                            return (
                                <Grid item xs={12} md={6} key={index}>
                                    <Card sx={{width: 1}} variant="outlined">
                                        <CardActionArea onClick={() => handleCreateFromTemplate(form.data)} sx={{p: 1}}>
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <Box sx={{color: '#fff', borderRadius: 2, p: 1}}>
                                                    <FormIcon color="secondary" />
                                                </Box>
                                                <Typography variant="h4" noWrap>
                                                    {form.data.title}
                                                </Typography>
                                            </Stack>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                </DialogContent>
            </Box>
        </Collapse>
    </>
}

export function useFormCreateDialog() {
    const {openDialog, closeDialog} = useDialog();

    const open = () => {
        openDialog({
            children: <FormCreateDialog />,
            maxWidth: 'sm',
            fullWidth: true
        });
    };

    return [open, closeDialog];
}