import {THEME_PALETTES} from "../app/Theme";
import {CardMedia} from "@mui/material";
import React from "react";

export function CardMediaBordered({theme}) {
    const palette = THEME_PALETTES[theme];

    return (
        <CardMedia
            component="div"
            sx={{
                background: `rgba(0, 0, 0, 0) linear-gradient(to right bottom, ` +
                    `${palette.light}, ${palette.main} 120%) repeat scroll 0% 0%`,
                height: '5px'
            }}>
        </CardMedia>
    );
}