import Box from "@mui/material/Box";
import {Alert, Grow, Paper, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {LoadingButton} from "@mui/lab";
import {useState} from "react";
import AppConsts from "../app/AppConsts";
import {logError} from "../utils/logging";
import Grid from "@mui/material/Grid";
import {TransitionGroup} from "react-transition-group";
import {SaveOutlined, SendOutlined} from "@mui/icons-material";
import {useSnackbar} from "../providers/SnackbarProvider";
import {useUser} from "../user-auth/UserProvider";
import {getDefaultErrorsMapper, useErrors} from "../hooks/useErrors";
import {httpClient} from "../utils/httpClient";
import {t} from "i18next";
import {useProfileData} from "./UserSettings";

export function UserSettingsEmail() {
    const user = useUser();
    const {createSnackbar} = useSnackbar();
    const {profileData, loadProfileData} = useProfileData();

    const [formState, setFormState] = useState('none');
    const {errors, setErrors, resetErrors} = useErrors({
        mapper: getDefaultErrorsMapper(['email'])
    });

    const [sendEmailState, setSendEmailState] = useState('none');
    const handleSendLink = () => {
        setSendEmailState('loading');
        httpClient.post(`${AppConsts.API_PATH}/users/email/verification`).then(response => {
            if (response.data.errors) {
                setSendEmailState('error');
                response.data.errors.forEach(e => {
                    createSnackbar({
                        message: e.message,
                        autoHideDuration: 4000,
                        type: 'error'
                    });
                });
            } else {
                setSendEmailState('sent');
                createSnackbar({
                    message: t('user_settings.verification_email_has_been_sent'),
                    autoHideDuration: 4000,
                    type: 'success'
                });
            }
        }).catch((e) => {
            setSendEmailState('error');
            logError(e);
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        setFormState('loading');

        httpClient.post(`${AppConsts.API_PATH}/users/edit`, {
            email: data.get('email')
        }).then(response => {
            if (response.data.errors) {
                setErrors(response.data.errors);
                setFormState('unsaved');
            } else {
                setErrors([]);
                user.update(response.data.user);
                createSnackbar({
                    message: t('common.changes_saved_successfully'),
                    autoHideDuration: 4000,
                    type: 'success'
                });
                setFormState('saved');
                loadProfileData();
            }
        }).catch((e) => {
            logError(e);
            setFormState('unsaved');
        });
    };

    return (
        <Paper variant="outlined" sx={{p: 3}}>
            <Typography variant="h5" sx={{mb: 4}}>
                {t('user_settings.edit_account_email_addres')}
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{width: 1, maxWidth: 720}}>
                {profileData && !profileData.emailVerified ?
                    <Alert severity="error" sx={{mb: 4, p: 3}}>
                        <Box sx={{mb: 2}}>
                            {t('user_settings.your_email_address_must_be_verified', {email: user.data.email})}
                        </Box>
                        <Box sx={{mb: 2}}>
                            {t('user_settings.if_you_didn_t_receive_any')}
                        </Box>
                        <LoadingButton
                            startIcon={<SendOutlined/>}
                            color="error"
                            variant="outlined"
                            loading={sendEmailState === 'loading'}
                            onClick={handleSendLink}>
                            {t('user_settings.send_verification_link')}
                        </LoadingButton>
                    </Alert>
                    :
                    <Alert severity="success" sx={{mb: 4}}>
                        {t('user_settings.your_email_address_is_verified', {email: user.data.email})}
                    </Alert>}
                <Grid container sx={{width: 1, mb: 4}} rowSpacing={{xs: 2, md: 0}} columnSpacing={{md: 4}} alignItems="center">
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" color={!!errors?.email ? 'error' : ''} sx={{textAlign: {md: 'right'}}}>
                            {t('user.email_address')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField
                            fullWidth
                            name="email"
                            defaultValue={user.data.email}
                            error={!!errors?.email}
                            helperText={errors?.email}
                            onChange={() => resetErrors('email')}
                        />
                    </Grid>
                </Grid>
                <TransitionGroup>
                    {errors?.main && errors.main.map((error, errorKey) =>
                        <Grow in={true} key={errorKey}>
                            <Alert severity="error" sx={{mt: 2}}>
                                {error}
                            </Alert>
                        </Grow>)}
                </TransitionGroup>
                <LoadingButton
                    size="large"
                    startIcon={<SaveOutlined />}
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3 }}
                    loading={formState === 'loading'}
                >
                    {t('common.save')}
                </LoadingButton>
            </Box>
        </Paper>
    );
}