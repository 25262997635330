import * as React from 'react';
import {Page} from "../app/Page";
import {useCallback, useEffect, useRef} from "react";
import {httpClient} from "../utils/httpClient";
import AppConsts from "../app/AppConsts";
import {logError} from "../utils/logging";
import {Box, CircularProgress} from "@mui/material";
import {PathsMap} from "../app/PageRouting";
import {useUser} from "./UserProvider";
import {useHistory} from "react-router";
import {useSnackbar} from "../providers/SnackbarProvider";
import {t} from "i18next";

export function OAuthCallback() {
    const user = useUser();
    const history = useHistory();
    const callbackExecuted = useRef(false);
    const {createSnackbar} = useSnackbar();

    const handleError = useCallback(() => {
        createSnackbar({
            message: t('sign_in.oauth_error_occurred'),
            type: "error",
            autoHideDuration: 2000
        });
        history.push(PathsMap.SIGN_IN);
    }, [createSnackbar, history]);

    useEffect(() => {
        if (callbackExecuted.current) {
            return;
        }
        callbackExecuted.current = true;

        const urlParams = new URLSearchParams(window.location.search);
        httpClient.post(`${AppConsts.API_PATH}/users/auth/oauth-callback`, {
            code: urlParams.get('code')
        }).then(response => {
            if (response.data.accessToken) {
                user.set({
                    tokens: {
                        accessToken: response.data.accessToken,
                        refreshToken: response.data.refreshToken,
                        expiresAt: response.data.expiresAt
                    }
                }).then(() => {
                    setTimeout(() => history.push(PathsMap.HOME), 0);
                });
            } else {
                handleError();
            }
        }).catch((e) => {
            logError(e);
            handleError();
        });
    }, [history, user, createSnackbar, handleError]);

    return (
        <Page centered>
            <Box justifyContent="center" display="flex">
                <CircularProgress/>
            </Box>
        </Page>
    );
}
