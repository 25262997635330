import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    Alert,
    Autocomplete,
    Avatar,
    Backdrop,
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    IconButton,
    Input, InputAdornment, LinearProgress, List, ListItem, ListItemAvatar,
    ListItemIcon, ListItemText,
    MenuItem, OutlinedInput, Radio,
    Select,
    Stack, Switch,
    TextField, ToggleButton, ToggleButtonGroup,
    Typography
} from "@mui/material";
import LeaveConfirm from "../../components/LeaveConfirm";
import AppConsts from "../../app/AppConsts";
import {useErrors} from "../../hooks/useErrors";
import {useSnackbar} from "../../providers/SnackbarProvider";
import useFormEditorStateManager from "./useFormEditorStateManager";
import {FORM} from "../../utils/formUtils";
import {autocompleteStyles, ICON_STYLES, Theme, THEME_PALETTES} from "../../app/Theme";
import {debounce} from "../../utils/debounce";
import {scrollToElementById} from "../../utils/scroll";
import {logError} from "../../utils/logging";
import {FormEditorMenu} from "./FormEditorMenu";
import {FormEditorQuestion} from "./FormEditorQuestion";
import {useMdEditorDialog} from "../../components/MdEditorDialog";
import {EditOutlined} from "@mui/icons-material";
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import {httpClient} from "../../utils/httpClient";
import {t} from "i18next";
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import styled from "@emotion/styled";
import {translateUserCategoryName} from "../../utils/userCategories";
import {DatePicker, TimePicker} from "@mui/x-date-pickers";
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import moment from "moment";

export function FormEditor({formId}) {
    const [viewState, setViewState] = useState({code: "loading"});
    const {form, managers, events} = useFormEditorStateManager();

    useEffect(() => {
        httpClient.get(`${AppConsts.API_PATH}/forms/${formId}/editor`).then(response => {
            if (response.data.errors) {
                setViewState({
                    code: "loadingError",
                    text: response.data.errors[0].message
                });
            } else {
                managers.form.loadData(response.data.form);
                setViewState({
                    code: "loaded"
                });
            }
        }).catch((e) => {
            logError(e);
            setViewState({
                code: "loadingError",
                text: t('common.sorry_something_went_wrong')
            });
        });
    }, [managers.form, formId]);

    if (viewState.code === "loading") {
        return <CircularProgress/>;
    }

    if (viewState.code === "loadingError") {
        return <Alert severity="error">{viewState.text}</Alert>;
    }

    return <FormEditorLoaded
        form={form}
        events={events}
        managers={managers}/>;
}

function FormEditorLoaded({form, managers, events}) {
    const {createSnackbar} = useSnackbar();
    const [editLoadingState, setEditLoadingState] = useState(false);

    // Errors
    const {errors, resetErrors, setErrors, hasErrors, countErrors} = useErrors({
        mapper: ({details, message}) => {
            let errorKey = [];

            if ('questionRef' in details) {
                errorKey.push('questions[' + details.questionRef + ']');

                if ('choiceRef' in details) {
                    errorKey.push('choices[' + details.choiceRef + ']');
                } else if ('constraintRef' in details) {
                    errorKey.push('constraints[' + details.constraintRef + ']');
                } else if ('exitConditionRef' in details) {
                    errorKey.push('exitConditions[' + details.exitConditionRef + ']');
                } else if ('topicRef' in details) {
                    errorKey.push('topics');
                }
            } else if ('topicRef' in details) {
                errorKey.push('topics');
            } else if ('questionQuizRef' in details) {
                errorKey.push('questionsQuiz[' + details.questionQuizRef + ']');

                if ('choiceRef' in details) {
                    errorKey.push('choices[' + details.choiceRef + ']');
                }
            } else if ('permission' in details) {
                errorKey.push('permissions');
            }

            if ('inputName' in details) {
                errorKey.push(details.inputName);
            }
            return {
                key: errorKey.length > 0 ? errorKey.join('.') : 'main',
                message: message
            };
        }
    });

    const errorsManager = useMemo(() => ({
        hasErrors: hasErrors,
        resetErrors: resetErrors,
        countErrors: countErrors
    }), [hasErrors, resetErrors, countErrors]);

    // Focused question
    const scrollToQuestion = (questionRef) => {
        setTimeout(() => {
            scrollToElementById('q-' + questionRef, {
                centered: true
            });
        }, 0);
    };

    const [focusedQuestion, setFocusedQuestion] = useState(null);

    events.addListener('questionAdded', (q) => setFocusedQuestion(q));
    events.addListener('questionMoved', (q) => setFocusedQuestion(q));

    useEffect(() => {
        if (focusedQuestion) {
            scrollToQuestion(focusedQuestion.ref);
            setFocusedQuestion(null);
        }
    }, [focusedQuestion, setFocusedQuestion]);

    // Save
    const saveDisabled = editLoadingState || !form.dirty;
    const sendSaveRequest = useCallback(() => {
        return new Promise((resolve, reject) => {
            setEditLoadingState(true);

            httpClient.put(`${AppConsts.API_PATH}/forms/${form.id}`, managers.form.getSaveData(form)).then(response => {
                setTimeout(() => setEditLoadingState(false), 300);

                if (response.data.errors) {
                    setErrors(response.data.errors);
                    createSnackbar({
                        message: response.data.errors.length === 1
                            ? t('common.1_error_occurred')
                            : t('common.n_errors_occurred', {n: response.data.errors.length}),
                        autoHideDuration: 2000,
                        type: "error"
                    });
                    resolve(true);
                } else {
                    resetErrors();
                    managers.form.updateData(response.data.form);
                    createSnackbar({
                        message: t('common.changes_saved_successfully'),
                        autoHideDuration: 2000,
                        type: "success"
                    });
                    resolve(false);
                }
            }).catch((e) => {
                logError(e);
                setEditLoadingState(false);
                reject();
            });
        });
    }, [createSnackbar, form, managers.form, resetErrors, setErrors]);

    const initialTitle = useRef(form.title);
    const initialDescription = useRef(form.description);
    const initialTheme = useRef(form.theme);
    const initialRecipients = useRef(form.recipients);

    return (
        <Box sx={{pb: 7}}>
            <LeaveConfirm message={t('common.any_unsaved_data_will_be_lost')} when={form.dirty}/>
            {errors?.main && errors.main.map((error, errorKey) =>
                <Alert severity="error" key={errorKey} sx={{mb: 2}}>{error}</Alert>)}
            <FormEditorTitle
                managers={managers}
                errors={errors}
                errorsManager={errorsManager}
                title={initialTitle}
                theme={initialTheme} />
            <FormEditorDescription
                managers={managers}
                errors={errors}
                errorsManager={errorsManager}
                description={initialDescription} />
            <FormEditorRecipients
                managers={managers}
                errorsManager={errorsManager}
                recipients={initialRecipients} />
            <FormEditorSchedulingAnonymous
                form={form}
                managers={managers} />
            <FormEditorQuestionsListMemo
                legacy={form.legacy}
                questions={form.questions}
                errors={errors}
                managers={managers}
                errorsManager={errorsManager} />
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={editLoadingState}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <FormEditorMenuMemo
                form={form}
                errors={errors}
                errorsManager={errorsManager}
                sendSaveRequest={sendSaveRequest}
                saveDisabled={saveDisabled}
                managers={managers} />
        </Box>
    );
}

function FormEditorTitle({errors, errorsManager, managers, title ,theme}) {
    return (
        <>
            <Grid container sx={{width: '100%', mb: 3}} alignItems="flex-end" spacing={{xs: 4, md: 2}}>
                <Grid item xs={12} md={9}>
                    <Input
                        variant="outlined"
                        placeholder={t('form_editor.form_title')}
                        defaultValue={title.current}
                        fullWidth
                        inputProps={{
                            maxLength: FORM.TITLE_MAX_LENGTH,
                            style: {fontSize: Theme.typography.h1.fontSize}
                        }}
                        onChange={(e) => {
                            errorsManager.resetErrors('title');
                            debounce(e, () => managers.form.editTitle(e.target.value));
                        }} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Select
                        inputProps={{MenuProps: {disableScrollLock: true}}}
                        onChange={(e) => managers.form.editTheme(e.target.value)}
                        defaultValue={theme.current}
                        size="small"
                        fullWidth
                        displayEmpty>
                        {FORM.THEMES_DETAILS.map(td => {
                            const palette = THEME_PALETTES[FORM.THEME_2_PALETTE[td.id]];
                            return (
                                <MenuItem value={td.id} key={td.id}>
                                    <ListItemIcon>
                                        <Avatar sx={{bgcolor: palette.main, width: '16px', height: '16px'}}>&nbsp;</Avatar>
                                    </ListItemIcon>
                                    {td.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Grid>
            </Grid>
            {errors?.title && errors.title.map((error, errorKey) =>
                <Alert severity="error" key={errorKey} sx={{mb: 2}}>{error}</Alert>)}
        </>
    );
}

function FormEditorDescription({errors, errorsManager, managers, description}) {
    const descriptionRef = useRef(null);
    const [descriptionDialogComponent, openDescriptionDialog] = useMdEditorDialog({
        title: t('form_editor.form_description'),
        content: () => descriptionRef.current?.value,
        onChange: (value) => {
            descriptionRef.current.value = value;
        },
        onChangeDebounced: (value) => {
            errorsManager.resetErrors('description');
            managers.form.editDescription(value);
        }
    });

    return (
        <>
            <Input
                placeholder={t('form_editor.form_description')}
                defaultValue={description.current}
                sx={{mb: 2}}
                maxRows={5}
                variant="standard"
                fullWidth
                multiline
                inputRef={descriptionRef}
                error={'description' in errors}
                inputProps={{
                    maxLength: FORM.DESCRIPTION_MAX_LENGTH
                }}
                onChange={(e) => {
                    errorsManager.resetErrors('description');
                    debounce(e, () => managers.form.editDescription(e.target.value));
                }}
                startAdornment={
                    <IconButton
                        onClick={() => {
                            openDescriptionDialog();
                        }}
                        onMouseDown={(e) => {
                            e.preventDefault()
                        }}
                        sx={{mr: 1}}
                    >
                        <EditOutlined sx={ICON_STYLES.icon21} color="primary" />
                    </IconButton>
                }/>
            {errors?.description && errors.description.map((error, errorKey) =>
                <Alert severity="error" key={errorKey} sx={{mb: 2}}>{error}</Alert>)}
            {descriptionDialogComponent}
        </>
    );
}

const GroupHeader = styled('div')({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    backgroundColor: '#fff'
});

const GroupItems = styled('ul')({
    padding: 0,
});

function FormEditorRecipients({errorsManager, managers, recipients}) {
    const classes = autocompleteStyles();

    const [loadingSearchRecipients, setLoadingSearchRecipients] = useState(false);
    const [filteredRecipients, setFilteredRecipients] = useState([]);
    const xhrTimeout = useRef(null);

    const searchRecipients = useCallback((e = null) => {
        clearTimeout(xhrTimeout.current);
        setLoadingSearchRecipients(true);
        xhrTimeout.current = setTimeout(() => {
            httpClient.get(`${AppConsts.API_PATH}/forms/recipients`, {
                params: {
                    query: e ? e.target.value : ""
                }
            }).then(response => {
                setLoadingSearchRecipients(false);
                if (response.data.recipients) {
                    setFilteredRecipients(response.data.recipients.map(r => ({
                        type: r.type,
                        id: r.id,
                        name: translateUserCategoryName(r.name)
                    })));
                }
            }).catch((e) => {
                setLoadingSearchRecipients(false);
                logError(e);
            });
        }, 350);
    }, []);

    useEffect(() => {
        searchRecipients();
    }, [searchRecipients]);

    return (
        <Box sx={{mt: 2}}>
            <Grid container sx={{width: '100%', mb: 3}} spacing={{xs: 4, md: 2}} alignItems="center">
                <Grid item xs={12} md={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <ReplyOutlinedIcon />
                        <Typography>{t('form_editor.recipients')}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={10}>
                    <Autocomplete
                        multiple
                        options={filteredRecipients}
                        defaultValue={recipients.current}
                        groupBy={(option) => option.type}
                        renderGroup={(params) => (
                            <li key={params.key}>
                                <GroupHeader>{t('form_editor.recipient_types.' + params.group)}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                            </li>
                        )}
                        onChange={(e, value) => {
                            errorsManager.resetErrors('recipients');
                            debounce(e, () => managers.form.editRecipients(value));
                        }}
                        isOptionEqualToValue={(option, value) => {
                            return option.id === value.id && option.type === value.type;
                        }}
                        getOptionLabel={(option) => option.name}
                        filterOptions={(x) => x}
                        classes={{ paper: classes.paper }}
                        openOnFocus={true}
                        noOptionsText={t('common.no_results')}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                {option.type === 'category' && <GroupsOutlinedIcon sx={{ mr: 2, flexShrink: 0 }}/>}
                                {option.type === 'user' && <PersonOutlineOutlinedIcon sx={{ mr: 2, flexShrink: 0 }}/>}
                                {option.name}
                            </Box>
                        )}
                        clearOnBlur={false}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder={t('form_editor.recipient_category_or_user')}
                                onChange={(e)=> searchRecipients(e)}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: loadingSearchRecipients
                                        ? <CircularProgress color="inherit" size={20} /> : null
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

function FormEditorSchedulingAnonymous({form, managers}) {
    const [schedulingDialogOpen, setSchedulingDialogOpen] = useState(false);
    const handleClickOpen = () => setSchedulingDialogOpen(true);
    const handleClose = () => setSchedulingDialogOpen(false);

    const [preview, setPreview] = useState([]);
    const [loadingPreview, setLoadingPreview] = useState(false);
    const previewXhr = useRef(null);
    const updatePreview = useCallback((timeoutMs) => {
        if (!schedulingDialogOpen) {
            return;
        }
        clearTimeout(previewXhr.current);
        setLoadingPreview(true);
        previewXhr.current = setTimeout(() => {
            httpClient.post(`${AppConsts.API_PATH}/forms/response-scheduling-preview`, {
                everyTime: form.responseScheduling.everyTime,
                everyNTimes: form.responseScheduling.everyNTimes,
                weekDays: form.responseScheduling.weekDays,
                startDate: form.responseScheduling.startDate,
                endType: form.responseScheduling.endType,
                endDate: form.responseScheduling.endDate,
                endNTimes: form.responseScheduling.endNTimes
            }).then(response => {
                setLoadingPreview(false);
                if (response.data.dates) {
                    setPreview(response.data.dates.map(d => {
                        const momentDate = moment(d).utc();
                        return {
                            key: momentDate.toISOString(),
                            avatar: momentDate.format('D'),
                            title: momentDate.format('D MMMM YYYY'),
                            subtitle: momentDate.format('LT')
                        };
                    }));
                }
            }).catch((e) => {
                logError(e);
                setLoadingPreview(false);
            });
        }, timeoutMs || 500);
    }, [
        form.responseScheduling.endDate, form.responseScheduling.endNTimes,
        form.responseScheduling.endType, form.responseScheduling.everyNTimes,
        form.responseScheduling.everyTime, form.responseScheduling.startDate,
        form.responseScheduling.weekDays, schedulingDialogOpen]);

    useEffect(() => {
        updatePreview(0);
    }, [updatePreview]);
    
    const REPEAT_RESPONSE_OPTIONS = useRef(['ONCE', 'FREE', 'SCHEDULED']);

    const handleRepeatResponseChange = useCallback((event) => {
        managers.form.editRepeatResponse(event.target.value);
        if (event.target.value === 'SCHEDULED') {
            setSchedulingDialogOpen(true);
        }
        updatePreview();
    }, [managers.form, updatePreview]);

    const EVERY_TIME_INTERVALS = useRef(['DAY', 'WEEK', 'MONTH']);
    const handleChangeEveryNTimes = (e) => {
        let sanitizedNum = parseInt(e.target.value);
        if (isNaN(sanitizedNum)) {
            sanitizedNum = '';
        } else if (sanitizedNum > 999) {
            sanitizedNum = 999;
        }
        managers.form.editResponseScheduling({
            everyNTimes: sanitizedNum
        });
        updatePreview();
    };

    const WEEKS_DAYS = useRef([
        'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'
    ]);

    const handleChangeEndTimes = (e) => {
        let sanitizedNum = parseInt(e.target.value);
        if (isNaN(sanitizedNum)) {
            sanitizedNum = '';
        } else if (sanitizedNum > 999) {
            sanitizedNum = 999;
        }
        managers.form.editResponseScheduling({
            endNTimes: sanitizedNum
        });
        updatePreview();
    };

    return (
        <Box sx={{mt: 3}}>
            <Grid container sx={{width: '100%', mb: 3}} spacing={{xs: 4, md: 4}} alignItems="center">
                <Grid item xs={12} md={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <ScheduleSendOutlinedIcon />
                        <Typography>{t('form_editor.response_title')}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Select
                            variant="standard"
                            value={form.repeatResponse}
                            sx={{minWidth: 300}}
                            onChange={handleRepeatResponseChange}>
                            {REPEAT_RESPONSE_OPTIONS.current.map((item, itemKey) => (
                                <MenuItem value={item} key={itemKey}>
                                    {t('form_editor.repeat_response.' + item)}
                                </MenuItem>
                            ))}
                        </Select>
                        {form.repeatResponse === 'SCHEDULED' &&
                            <Button variant="outlined" onClick={handleClickOpen} sx={{ml: 1}}>
                                <EditOutlined/>
                            </Button>}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControlLabel
                        checked={!form.anonymous}
                        onChange={() => managers.form.editAnonymous(!form.anonymous)}
                        control={<Switch />}
                        label={form.anonymous ? t('form.anonymous_responses') : t('form.signed_responses')} />
                </Grid>
            </Grid>
            <Dialog
                open={schedulingDialogOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'md'}>
                <DialogTitle>
                    <Typography component="div" variant="h3" sx={{mb: 2}}>
                        {t('form_editor.custom_scheduling')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12} lg={6}>
                            <Grid container alignItems="center" spacing={3} sx={{mb: 3}}>
                                <Grid item xs={4}>
                                    {t('form_editor.repeat_every')}
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        size="small"
                                        placeholder="n"
                                        value={form.responseScheduling.everyNTimes}
                                        onChange={handleChangeEveryNTimes}
                                        sx={{width: 80}} />
                                </Grid>
                                <Grid item xs={5}>
                                    <Select size="small"
                                            value={form.responseScheduling.everyTime}
                                            onChange={(e) => {
                                                managers.form.editResponseScheduling({
                                                    everyTime: e.target.value
                                                });
                                                updatePreview();
                                            }}>
                                        {EVERY_TIME_INTERVALS.current.map((intervalId, itemKey) => (
                                            <MenuItem value={intervalId} key={itemKey}>
                                                {form.responseScheduling.everyNTimes > 1
                                                    ? t('common.time_intervals_plur.' + intervalId)
                                                    : t('common.time_intervals_sing.' + intervalId)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>

                            {form.responseScheduling.everyTime === 'WEEK' && <Box>
                                <Typography sx={{mb: 2}}>
                                    {t('form_editor.repeat_in_these_days_of_the')}
                                </Typography>
                                <ToggleButtonGroup
                                    sx={{mb: 3}}
                                    value={form.responseScheduling.weekDays}
                                    onChange={(e, newWeekDays) => {
                                        managers.form.editResponseScheduling({
                                            weekDays: newWeekDays
                                        });
                                        updatePreview();
                                    }}>
                                    {WEEKS_DAYS.current.map(index => (
                                        <ToggleButton value={index} key={index}>
                                            {t('common.week_days_short.' + index)}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Box>}

                            <Grid container alignItems="center" spacing={3} sx={{mb: 4}}>
                                <Grid item xs={4}>
                                    {t('form_editor.at_time')}
                                </Grid>
                                <Grid item xs={8}>
                                    <TimePicker
                                        value={form.responseScheduling.startDate}
                                        onChange={(value) => {
                                            managers.form.editResponseScheduling({
                                                startDate: value
                                            });
                                            updatePreview();
                                        }}
                                        sx={{width: 150}}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                size: 'small'
                                            }
                                        }}/>
                                </Grid>
                            </Grid>

                            <Typography variant="h4" sx={{mb: 2}}>
                                {t('common.start')}
                            </Typography>

                            <DatePicker
                                value={form.responseScheduling.startDate}
                                onChange={(value) => {
                                    managers.form.editResponseScheduling({
                                        startDate: value
                                    });
                                    updatePreview();
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        size: 'small'
                                    }
                                }} />

                            <Typography variant="h4" sx={{mb: 2, mt: 3}}>
                                {t('common.end')}
                            </Typography>

                            <Grid container alignItems="center" spacing={3} sx={{mb: 2}}>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        name="end"
                                        value="AFTER"
                                        checked={form.responseScheduling.endType === 'AFTER'}
                                        onChange={(e) => {
                                            managers.form.editResponseScheduling({
                                                endType: e.target.value
                                            });
                                            updatePreview();
                                        }}
                                        control={<Radio />}
                                        label={t('common.after')}
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item>
                                    <OutlinedInput
                                        placeholder="n"
                                        size='small'
                                        value={form.responseScheduling.endNTimes}
                                        onChange={handleChangeEndTimes}
                                        disabled={form.responseScheduling.endType !== 'AFTER'}
                                        endAdornment={<InputAdornment position="end">{t('common.times')}</InputAdornment>}
                                        sx={{width: 120}} />
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" spacing={3}>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        name="end"
                                        value="DATE"
                                        checked={form.responseScheduling.endType === 'DATE'}
                                        onChange={(e) => {
                                            managers.form.editResponseScheduling({
                                                endType: e.target.value
                                            });
                                            updatePreview();
                                        }}
                                        control={<Radio />}
                                        label={t('common.date')}
                                        labelPlacement="end"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item>
                                    <DatePicker
                                        sx={{width: 250}}
                                        value={form.responseScheduling.endDate}
                                        onChange={(value) => {
                                            managers.form.editResponseScheduling({
                                                endDate: value
                                            });
                                            updatePreview();
                                        }}
                                        minDate={form.responseScheduling.startDate}
                                        disabled={form.responseScheduling.endType !== 'DATE'}
                                        slotProps={{
                                            textField: {
                                                size: 'small'
                                            }
                                        }}/>
                                </Grid>
                            </Grid>
                            <Box sx={{mt: 2}}>
                                <FormControlLabel
                                    name="end"
                                    value="NEVER"
                                    checked={form.responseScheduling.endType === 'NEVER'}
                                    onChange={(e) => {
                                        managers.form.editResponseScheduling({
                                            endType: e.target.value
                                        });
                                        updatePreview();
                                    }}
                                    control={<Radio />}
                                    label={t('common.never')}
                                    labelPlacement="end"
                                    size="small"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Paper variant="outlined" sx={{position: 'relative'}}>
                                {loadingPreview && <LinearProgress sx={{position: 'absolute', 'left': 0, 'top': 0, 'right': 0}} />}
                                <List sx={{ width: '100%', maxHeight: 512, overflowY: 'auto'}}>
                                    {preview.map(p => (
                                        <ListItem key={p.key}>
                                            <ListItemAvatar>
                                                <Avatar sx={{bgcolor: 'primary.main'}}>{p.avatar}</Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={p.title} secondary={p.subtitle} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {t('common.close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>);
}

function FormEditorQuestionsList(props) {
    const legacy = props.legacy;
    const questions = props.questions;
    const managers = props.managers;
    const errors = props.errors;
    const errorsManager = props.errorsManager;

    return (
        <Box sx={{mt: 6}}>
            {questions.map(question =>
            <div key={question.ref} id={`q-${question.ref}`}>
                <FormEditorQuestionMemo
                    legacy={legacy}
                    question={question}
                    managers={managers}
                    errors={errors}
                    errorsManager={errorsManager}
                    maxPosition={questions.length}
                />
            </div>)}
        </Box>
    );
}

const FormEditorQuestionsListMemo = React.memo(FormEditorQuestionsList);
const FormEditorQuestionMemo = React.memo(FormEditorQuestion);
const FormEditorMenuMemo = React.memo(FormEditorMenu);