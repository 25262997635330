import AppConsts from "../../app/AppConsts";
import {logError} from "../../utils/logging";
import {useSnackbar} from "../../providers/SnackbarProvider";
import {httpClient} from "../../utils/httpClient";
import {t} from "i18next";

export function useEditOpenState(formId, onDone, onFail) {
    const {createSnackbar} = useSnackbar();

    return (open) => {
        httpClient.put(`${AppConsts.API_PATH}/forms/${formId}`, {open: open}).then(response => {
            if (response.data.errors) {
                createSnackbar({
                    message: response.data.errors[0].message,
                    autoHideDuration: 2000
                });
                if (typeof onFail === 'function') {
                    onFail(response.data.errors);
                }
            } else {
                createSnackbar({
                    message: response.data.form.open
                        ? t('form.accepting_responses_from_now')
                        : t('form.rejecting_all_new_responses'),
                    type:  response.data.form.open
                        ? "success"
                        : "error",
                    autoHideDuration: 2000
                });
                if (typeof onDone === 'function') {
                    onDone(response.data.form.open);
                }
            }
        }).catch((e) => {
            logError(e);
        });
    };
}