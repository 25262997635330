import React, {useCallback, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router";
import {PathGenerator} from "../app/PageRouting";
import {
    Alert,
    Box,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CircularProgress,
    Divider,
    Grid,
    Grow,
    Pagination,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import {NavLink} from "react-router-dom";
import {QuestionAnswerOutlined} from "@mui/icons-material";
import AppConsts from "../app/AppConsts";
import {scrollToElementByRef} from "../utils/scroll";
import {logError} from "../utils/logging";
import {httpClient} from "../utils/httpClient";
import {t} from "i18next";
import moment from "moment";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import {CardMediaBordered} from "../components/CardMediaBordered";
import {FORM} from "../utils/formUtils";

function FormsCardListEl(props) {
    const form = props.form;
    const history = useHistory();

    const CardActionOrBox = form.canRespond ? CardActionArea : Box;
    const cardActionsProps = {};
    if (form.canRespond) {
        cardActionsProps.onClick = () => history.push(PathGenerator.createFormResponse(form.id));
    }

    return (
    <Grow in={true}>
        <Card variant="outlined" sx={{width: 1}}>
            <Tooltip title={form.title + (form.description ? ' - ' + form.description : '')}>
                <CardActionOrBox {...cardActionsProps}>
                    <CardMediaBordered theme={FORM.THEME_2_PALETTE[form.theme]} />
                    <CardContent sx={{marginTop: '16px'}}>
                        <Typography gutterBottom variant="h2" noWrap>
                            {form.title}
                        </Typography>
                        {form.description.length > 0 ?
                            <Typography color="text.secondary" noWrap>
                                {form.description}
                            </Typography> :
                            <Typography color="text.disabled" noWrap>
                                {t('common.empty_description')}
                            </Typography>
                        }
                    </CardContent>
                </CardActionOrBox>
            </Tooltip>
            <CardActions>
                <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{width: 1}}>
                    <Grid item xs={12} md={6}>
                        <Tooltip title={t('forms_list.latest_reply')}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                {!form.latestResponseAt && <ErrorOutlineOutlinedIcon color="error" sx={{fontSize: 16}} />}
                                {form.latestResponseAt && <CheckCircleOutlineOutlinedIcon color="success" sx={{fontSize: 16}} />}
                                <Typography variant="caption" color="text.secondary">
                                    {form.latestResponseAt
                                        ? moment.unix(form.latestResponseAt).fromNow()
                                        : t('forms_list.no_replies')}
                                </Typography>
                            </Stack>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box display="flex" justifyContent="right">
                            {form.canRespond &&
                                <Button component={NavLink} to={PathGenerator.createFormResponse(form.id)} size="small">
                                    <QuestionAnswerOutlined sx={{fontSize: 14, mr: 1}}/>
                                    {t('form.respond')}
                                </Button>}
                        </Box>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    </Grow>);
}

export function FormsCardList() {
    const [viewState, setViewState] = useState({code: "loading"});
    const [pageNumber, setPageNumber] = useState(1);
    const [forms, setForms] = useState(null);
    const topRef = useRef(null);

    const [refreshCounter, setRefreshCounter] = useState(0);
    const refreshList = useCallback(() => {
        setRefreshCounter(counter => counter + 1);
    }, []);

    useEffect(() => {
        const cancelTokenSource = httpClient.cancelToken();
        let isMounted = true;

        httpClient.get(`${AppConsts.API_PATH}/forms/users/me?pageNumber=${pageNumber}&pageSize=12`, {
            cancelToken: cancelTokenSource.token
        }).then(response => {
            if (!isMounted) {
                return;
            }
            if (response.data.errors) {
                setViewState({
                    code: "loadingError",
                    text: response.data.errors[0].message
                });
            } else {
                const data = response.data;
                setForms({
                    pageNumber: data.page.pageNumber,
                    totalPages: data.pagination.totalPages,
                    totalForms: data.pagination.totalElements,
                    list: data.page.elements.map(f => ({
                        id: f.id,
                        title: f.title,
                        description: f.description,
                        latestResponseAt: f.latestResponseAt,
                        canRespond: f.canRespond,
                        theme: f.theme
                    }))
                });
                setViewState({code: "loaded"});
            }
        }).catch((e) => {
            logError(e);
            if (!isMounted) {
                return;
            }
            setViewState({
                code: "loadingError",
                text: t('common.sorry_something_went_wrong')
            });
        });

        return () => {
            isMounted = false;
            cancelTokenSource.cancel();
        }
    }, [pageNumber, refreshCounter]);

    if (viewState.code === "loading") {
        return <CircularProgress/>;
    }

    if (viewState.code === "loadingError") {
        return <Alert severity="error">{viewState.text}</Alert>;
    }

    if (!forms.list.length) {
        return <Alert severity="info" sx={{mb: 4}}>
            {t('forms_list.you_haven_any_form')}
        </Alert>
    }

    return (
        <Box ref={topRef}>
            <Grid container spacing={4}>
                {forms.list.map(form =>
                    <Grid item xs={12} sm={6} lg={4} key={form.id}>
                        <FormsCardListEl
                            form={form}
                            refreshList={refreshList} />
                    </Grid>)}
            </Grid>
            {forms.totalPages > 1 && <>
                <Divider sx={{mt: 5, mb: 2}} />
                <Pagination
                    onChange={(e, page) => {
                        setPageNumber(page);
                        scrollToElementByRef(topRef);
                    }}
                    count={forms.totalPages} /></>}
        </Box>
    );
}