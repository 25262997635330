import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import AppConsts from "../app/AppConsts";
import {useState} from "react";
import {Alert, Grow} from "@mui/material";
import {TransitionGroup} from "react-transition-group";
import {LoadingButton} from "@mui/lab";
import {logError} from "../utils/logging";
import {Page} from "../app/Page";
import {getDefaultErrorsMapper, useErrors} from "../hooks/useErrors";
import {useHistory} from "react-router";
import {PathsMap} from "../app/PageRouting";
import {useQuery} from "../hooks/useQuery";
import {httpClient} from "../utils/httpClient";
import {t} from "i18next";

export function ResetPasswordConfirm() {
    const [loading, setLoading] = useState(false);
    const {errors, setErrors, resetErrors} = useErrors({
        mapper: getDefaultErrorsMapper(['password', 'passwordConfirmation'])
    });

    const queryParams = useQuery();
    const [showDoneMessage, setShowDoneMessage] = useState(false);
    const history = useHistory();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let password = data.get('password');
        let passwordConfirmation = data.get('password_confirmation');

        if (passwordConfirmation !== password) {
            setErrors([
                {
                    details: {field: 'passwordConfirmation'},
                    message: "Password confirmation must be equal to password"
                }
            ]);
            return;
        }

        setLoading(true);

        httpClient.put(`${AppConsts.API_PATH}/users/auth/password-reset`, {
            password: password,
            resetToken: queryParams.get('t')
        }).then(response => {
            setLoading(false);
            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                setErrors([]);
                setShowDoneMessage(true);
                setTimeout(() => {
                    history.push(PathsMap.SIGN_IN);
                }, 2000);
            }
        }).catch((e) => {
            setLoading(false);
            logError(e);
        });
    };

    return (
        <Page title={t('reset_password.reset_password_title')} maxWidth="sm" centered>
            <Paper sx={{p: 4}} variant="outlined">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h4" sx={{mb: 3}}>
                        {t('reset_password.reset_password_title')}
                    </Typography>
                    {showDoneMessage ?
                        <Alert color="success" sx={{width: 1}}>
                            {t('reset_password.password_has_been_modified_successfully_please_wait')}
                        </Alert>
                        :
                        <>
                            <Typography component="p" variant="p" sx={{mb: 2}}>
                                {t('reset_password.choose_a_new_password_for_your_account')}
                            </Typography>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{width: 1}}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label={t('user.password')}
                                    type="password"
                                    autoComplete="new-password"
                                    sx={{mb: 2}}
                                    error={!!errors?.password}
                                    helperText={errors?.password}
                                    onChange={() => resetErrors('password')}
                                />
                                <TextField
                                    required
                                    fullWidth
                                    name="password_confirmation"
                                    label={t('user.password_confirmation')}
                                    type="password"
                                    autoComplete="new-password"
                                    error={!!errors?.passwordConfirmation}
                                    helperText={errors?.passwordConfirmation}
                                    onChange={() => resetErrors('passwordConfirmation')}
                                />
                                <TransitionGroup>
                                    {errors?.main && errors.main.map((error, errorKey) =>
                                        <Grow in={true} key={errorKey}>
                                            <Alert severity="error" sx={{mt: 2}}>
                                                {error}
                                            </Alert>
                                        </Grow>)}
                                </TransitionGroup>
                                <LoadingButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    loading={loading}
                                    sx={{ mt: 3 }}
                                >
                                    {t('common.reset')}
                                </LoadingButton>
                            </Box>
                        </>}
                </Box>
            </Paper>
        </Page>
    );
}
