import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {NavLink} from "react-router-dom";
import AppConsts from "../app/AppConsts";
import {useEffect, useState} from "react";
import {Alert, Button, CircularProgress} from "@mui/material";
import {logError} from "../utils/logging";
import {ArrowForwardOutlined} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {Page} from "../app/Page";
import {PathsMap} from "../app/PageRouting";
import {useQuery} from "../hooks/useQuery";
import {httpClient} from "../utils/httpClient";
import {t} from "i18next";

export function EmailConfirm() {
    const queryParams = useQuery();
    const [state, setState] = useState('none');

    useEffect(() => {
        setState('loading');

        httpClient.put(`${AppConsts.API_PATH}/users/email/verification`, {
            verificationKey: queryParams.get('k')
        }).then(response => {
            if (response.data.errors) {
                setState('error');
            } else {
                setState('verified');
            }
        }).catch((e) => {
            setState('error');
            logError(e);
        });
    }, [queryParams]);

    if (state === 'verified') {
        return (
            <EmailConfirmContainer>
                <Alert severity="success">
                    {t('user_settings.done_your_account_email_address_has_been_verified')}
                </Alert>
                <Button
                    fullWidth
                    startIcon={<ArrowForwardOutlined/>}
                    color="success"
                    variant="outlined"
                    sx={{mt: 2}}
                    to={PathsMap.HOME} component={NavLink}>
                    {t('common.continue')}
                </Button>
            </EmailConfirmContainer>
        );
    }

    if (state === 'error') {
        return (
            <EmailConfirmContainer>
                <Alert severity="error">
                    {t('user_settings.an_error_occurred_your_account_email_address_could')}
                </Alert>
            </EmailConfirmContainer>
        );
    }

    return (
        <EmailConfirmContainer>
            <Box justifyContent="center" display="flex">
                <CircularProgress size={24}/>
            </Box>
        </EmailConfirmContainer>
    );
}

function EmailConfirmContainer({children}) {
    return (
        <Page title={t('user_settings.verify_email')} maxWidth="sm" centered>
            <Paper sx={{p: 4}} variant="outlined">
                <Typography component="h1" variant="h4" sx={{mb: 2}}>
                    {t('user_settings.verify_email')}
                </Typography>
                {children}
            </Paper>
        </Page>
    );
}