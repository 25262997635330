import React from "react";
import {Dialog, Grow} from "@mui/material";

const DialogContext = React.createContext(null);

export const useDialog = () => {
    return React.useContext(DialogContext);
};

export default function DialogProvider({children}) {
    const [dialog, setDialog] = React.useState(null);

    const openDialog = (options) => {
        setDialog(() => {
            return {
                maxWidth: 'md',
                fullWidth: false,
                open: true,
                canClose: {
                    active: true,
                    message: null
                },
                ...options
            };
        });
    };

    const closeDialogWithConfirm = () => {
        setDialog((d) => {
            if (d && (d.canClose.active || (d.canClose.message && window.confirm(d.canClose.message)))) {
                return {
                    ...d,
                    open: false
                };
            }
            return d;
        });
    };

    const closeDialog = () => {
        setDialog((d) => ({
            ...d,
            open: false
        }));
    };

    const setCanCloseDialog = (canClose) => {
        setDialog((d) => ({
            ...d,
            canClose: canClose
        }));
    };

    const contextValue = React.useRef({
        openDialog, closeDialogWithConfirm, closeDialog, setCanCloseDialog
    });

    return (
        <DialogContext.Provider value={contextValue.current}>
            {children}
            {dialog &&
                <Dialog open={dialog.open}
                        onClose={closeDialogWithConfirm}
                        TransitionComponent={Grow}
                        scroll="body"
                        maxWidth={dialog.maxWidth}
                        fullWidth={dialog.fullWidth}
                        sx={{
                            '& .MuiDialog-scrollBody': {
                                overflowY: 'scroll',
                            }
                        }}>
                    {dialog.children}
                </Dialog>}
        </DialogContext.Provider>
    );
}