import {useCallback, useMemo, useRef, useState} from "react";
import {getQuestionsMatchTypes, getRequiredConstraintTypes, QUESTION} from "../../utils/formUtils";
import useIdGenerator from "../../hooks/useIdGenerator";
import {t} from "i18next";

import {translateUserCategoryName} from "../../utils/userCategories";

export default function useFormEditorStateManager() {
    const [form, setFormState] = useState({});
    const {generateId: generateRef, addGeneratedId: addGeneratedRef} = useIdGenerator();

    const events = useRef({
        questionAdded: [],
        questionMoved: []
    });

    const addListener = useCallback((name, fn) => {
        events.current[name].push(fn);
    }, []);

    const removeListener = useCallback((name, fn) => {
        let index = events.current[name].indexOf(fn);
        if (index !== -1) {
            events.current[name].splice(index, 1);
        }
    }, []);

    const triggerEvent = useCallback((name, params) => {
        events.current[name].forEach(listener => listener.apply(null, params));
    }, []);

    /*
     * -- Private --
     */

    const _editor = useMemo(() => {
        function findQuestionPossibleExitRef(_questions, excludeIndexes, extraQuestion) {
            const converter = (q) => ({
                ref: q.ref,
                position: q.position,
                title: q.title
            });
            const questions = _questions
                .filter((q, k) => !excludeIndexes.includes(k))
                .map(q => converter(q));
            if (extraQuestion) {
                questions.push(converter(extraQuestion));
            }
            return questions;
        }

        function editQuestion(questionRef, applyEdit, options = {}) {
            setFormState(_form => {
                let questionIndex = _form.questions.findIndex(q => q.ref === questionRef);
                if (questionIndex < 0) {
                    return _form;
                }

                const question = {..._form.questions[questionIndex]};
                const applyResponse = applyEdit(question);
                if (applyResponse === false) {
                    return _form;
                }

                if (options.updatePossibleExitRefs) {
                    return {
                        ..._form,
                        questions: _form.questions.map((q, i) => {
                            if (i === questionIndex) {
                                return question;
                            }

                            let questionModified = false;
                            const possibleExitRefs = q.possibleExitRefs.map(exitRef => {
                                if (exitRef.ref === question.ref) {
                                    exitRef = {...exitRef, title: question.title};
                                    questionModified = true;
                                }
                                return exitRef;
                            });
                            if (questionModified) {
                                q = {...q, possibleExitRefs: possibleExitRefs};
                            }
                            return q;
                        }),
                        dirty: true
                    };
                }

                const questions = [..._form.questions];
                questions[questionIndex] = question;
                return {..._form, questions: questions, dirty: true};
            });
        }

        function editExitCondition(questionRef, ecRef, applyEdit) {
            _editor.editQuestion(questionRef, question => {
                question.exitConditions = question.exitConditions.map(ec => {
                    if (ec.ref === ecRef) {
                        ec = {...ec};
                        applyEdit(ec, question);
                    }
                    return ec;
                });
            });
        }

        function editMatchCondition(questionRef, ecRef, mcRef, applyEdit) {
            editExitCondition(questionRef, ecRef, ec => {
                ec.matchConditions = ec.matchConditions.map(mc => {
                    if (mc.ref === mcRef) {
                        mc = {...mc};
                        applyEdit(mc);
                    }
                    return mc;
                });
            });
        }

        function editConstraint(questionRef, constraintRef, applyEdit) {
            _editor.editQuestion(questionRef, question => {
                question.constraints = question.constraints.map(ct => {
                    if (ct.ref === constraintRef) {
                        ct = {...ct, default: false};
                        applyEdit(ct, question);
                    }
                    return ct;
                });
            });
        }

        function editConditions(question, conditionFilter, applyEdit) {
            let exitConditionsModified = false;
            const exitConditions = question.exitConditions.map(ec => {
                let matchModified = false;
                let _matchConditionsCopy = ec.matchConditions.map(mc => {
                    if (conditionFilter(mc)) {
                        mc = {...mc};
                        applyEdit(mc);
                        matchModified = true;
                        exitConditionsModified = true;
                    }
                    return mc;
                });
                return matchModified ? {...ec, matchConditions: _matchConditionsCopy} : ec;
            });
            if (exitConditionsModified) {
                question.exitConditions = exitConditions;
            }

            let constraintsModified = false;
            const constraints = question.constraints.map(constraint => {
                if (conditionFilter(constraint.condition)) {
                    constraint = {...constraint, condition: {...constraint.condition}};
                    applyEdit(constraint.condition);
                    constraintsModified = true;
                }
                return constraint;
            });
            if (constraintsModified) {
                question.constraints = constraints;
            }
        }

        function deleteConditions(question, deleteFilter) {
            let customConditionsCounter = 0;

            // Exit condtions
            let exitConditionsModified = false;
            const exitConditions = question.exitConditions.map(ec => {
                let matchConditionsCopy = ec.matchConditions.filter(ec => !deleteFilter(ec));
                if (matchConditionsCopy.length < ec.matchConditions.length) {
                    customConditionsCounter += (ec.matchConditions.length - matchConditionsCopy.length);
                    exitConditionsModified = true;
                    if (matchConditionsCopy.length === 0) {
                        return null;
                    }
                    return {...ec, matchConditions: matchConditionsCopy};
                }
                return ec;
            });
            if (exitConditionsModified) {
                question.exitConditions = exitConditions.filter(ec => ec !== null);
            }

            // Constraints conditions
            const constraintsCopy = question.constraints.filter(constraint => {
                if (!deleteFilter(constraint.condition)) {
                    return true;
                }
                if (!constraint.default) {
                    customConditionsCounter++;
                }
                return false;
            });
            if (question.constraints.length !== constraintsCopy.length) {
                question.constraints = constraintsCopy;
            }

            return customConditionsCounter;
        }

        function editChoice(questionRef, choiceRef, applyEdit) {
            _editor.editQuestion(questionRef, question => {
                question.choices = question.choices.map(choice => {
                    if (choice.ref === choiceRef) {
                        choice = {...choice};
                        applyEdit(choice, question);
                    }
                    return choice;
                });
            });
        }

        function convertBackendRefToState(ref) {
            return ref == null ? QUESTION.REF.END : (ref === "" ? QUESTION.REF.NEXT : ref);
        }

        function editTopic(topicRef, applyEdit) {
            setFormState(form => ({
                ...form,
                topics: form.topics.map(topic => {
                    if (topic.ref === topicRef) {
                        topic = {...topic};
                        applyEdit(topic);
                    }
                    return topic;
                }),
                dirty: true
            }));
        }

        function editSettingsByIndex(settingsIndex, applyEdit) {
            setFormState(form => ({
                ...form,
                quizResultSettings: form.quizResultSettings.map((_settings, _settingsIndex) => {
                    if (_settingsIndex === settingsIndex) {
                        return applyEdit(_settings);
                    }
                    return _settings;
                }),
                dirty: true
            }));
        }

        return {
            editQuestion, editExitCondition, editMatchCondition, editConstraint, convertBackendRefToState,
            deleteConditions, editConditions, editChoice, findQuestionPossibleExitRef, editTopic, editSettingsByIndex
        };
    }, []);

    /*
     * -- Public export --
     */

    const formManager = useMemo(() => ({
        loadData: (formData) => {
            const _form = {
                dirty: false,
                id: formData.id,
                legacy: formData.legacy,
                title: formData.title,
                description: formData.description,
                open: formData.open,
                repeatResponse: formData.repeatResponse,
                responseScheduling: {
                    everyTime: formData.responseScheduling.everyTime,
                    everyNTimes: formData.responseScheduling.everyNTimes,
                    weekDays: formData.responseScheduling.weekDays,
                    startDate: formData.responseScheduling.startDate
                        ? new Date(formData.responseScheduling.startDate) : null,
                    endType: formData.responseScheduling.endType,
                    endDate: formData.responseScheduling.endDate
                        ? new Date(formData.responseScheduling.endDate) : null,
                    endNTimes: formData.responseScheduling.endNTimes
                },
                anonymous: formData.anonymous,
                theme: formData.theme,
                topics: formData.topics.map(topicData => ({
                    id: topicData.id,
                    ref: addGeneratedRef(topicData.ref),
                    title: topicData.title,
                    position: {
                        x: topicData.positionX,
                        y: topicData.positionY
                    },
                    childLinks: topicData.childLinks.map(link => ({
                        targetRef: link.targetRef,
                        weight: link.weight
                    }))
                })),
                recipients: formData.recipients.map(recipient => ({
                    id: recipient.id,
                    name: translateUserCategoryName(recipient.name),
                    type: recipient.type
                })),
                permissions: formData.permissions.map(permissionData => ({
                    user: {
                        id: permissionData.user.id,
                        email: permissionData.user.email,
                        name: permissionData.user.name
                    },
                    readOnly: permissionData.readOnly,
                    canEditForm: permissionData.canEditForm,
                    canViewResponses: permissionData.canViewResponses,
                    canDeleteResponses: permissionData.canDeleteResponses,
                    canEditResponses: permissionData.canEditResponses,
                })),
                quizResultSettings: formData.quizResultSettings.map(settings => ({
                    ref: generateRef(),
                    title: settings.title,
                    scores: settings.scores,
                    columns: settings.columns.map(title => ({
                        ref: generateRef(),
                        title: title
                    })),
                    classes: settings.classes.map(cls => ({
                        ref: generateRef(),
                        minScore: cls.minScore,
                        maxScore: cls.maxScore,
                        columns: cls.columns.map(title => ({
                            ref: generateRef(),
                            title: title
                        })),
                    }))
                })),
                questions: formData.questions.map(questionData => {
                    const question = {
                        ref: addGeneratedRef(questionData.ref),
                        id: questionData.id,
                        title: questionData.title,
                        description: questionData.description,
                        type: questionData.type,
                        position: questionData.position,
                        topicRef: questionData.topicId
                            ? formData.topics.find(topicData => topicData.id === questionData.topicId).ref
                            : "",
                        constraints: questionData.constraints.map(ct => ({
                            ref: addGeneratedRef(ct.ref),
                            condition: {
                                type: ct.condition.type,
                                pattern: ct.condition.pattern
                            },
                            default: false
                        })),
                        defaultExitRef: _editor.convertBackendRefToState(questionData.defaultExitRef),
                        exitConditions: questionData.exitConditions.map(ec => ({
                            ref: addGeneratedRef(ec.ref),
                            matchConditions: ec.matchConditions.map(mc => ({
                                ref: generateRef(),
                                type: mc.type,
                                pattern: mc.pattern
                            })),
                            exitRef: _editor.convertBackendRefToState(ec.exitRef)
                        })),
                        possibleExitRefs: [],
                        quizAllCorrectRequired: questionData.quizAllCorrectRequired
                    };
                    if (question.type === QUESTION.TYPES.SINGLE_CHOICE
                        || question.type === QUESTION.TYPES.MULTIPLE_CHOICE
                        || question.type === QUESTION.TYPES.MEASURE) {
                        question.choices = questionData.choices.map(c => {
                            let quizResponse = questionData.quizResponses.find(qr => qr.value === c.title);
                            return {
                                ref: addGeneratedRef(c.ref),
                                title: c.title,
                                score: quizResponse ? quizResponse.score : 0
                            };
                        });
                    }
                    return question;
                })
            };
            _form.questions.forEach((question, questionIndex) => {
                question.possibleExitRefs = _editor.findQuestionPossibleExitRef(_form.questions, [questionIndex]);
            });
            setFormState(_form);
        },
        getSaveData: (form) => ({
            title: form.title,
            description: form.description,
            theme: form.theme,
            topics: form.topics.map(t => ({
                id: t.id,
                ref: t.ref,
                title: t.title,
                positionX: t.position.x,
                positionY: t.position.y,
                childLinks: t.childLinks.map(l => ({
                    targetRef: l.targetRef,
                    weight: l.weight
                }))
            })),
            repeatResponse: form.repeatResponse,
            responseScheduling: {
                everyTime: form.responseScheduling.everyTime,
                everyNTimes: form.responseScheduling.everyNTimes,
                weekDays: form.responseScheduling.weekDays,
                startDate: form.responseScheduling.startDate,
                endType: form.responseScheduling.endType,
                endDate: form.responseScheduling.endDate,
                endNTimes: form.responseScheduling.endNTimes
            },
            anonymous: form.anonymous,
            permissions: form.permissions.filter(p => !p.readOnly).map(p => ({
                userId: p.user.id,
                canEditForm: p.canEditForm,
                canViewResponses: p.canViewResponses,
                canDeleteResponses: p.canDeleteResponses,
                canEditResponses: p.canEditResponses,
            })),
            recipients: form.recipients.map(r => ({
                type: r.type,
                id: r.id
            })),
            questions: form.questions.map(q => ({
                ref: q.ref,
                id: q.id,
                title: q.title,
                description: q.description,
                type: q.type,
                position: q.position,
                constraints: q.constraints.map(ct => ({
                    ref: ct.ref,
                    type: ct.condition.type,
                    pattern: ct.condition.pattern
                })),
                defaultExitRef: q.defaultExitRef,
                exitConditions: q.exitConditions.map(ec => ({
                    ref: ec.ref,
                    matchConditions: ec.matchConditions.map(mc => ({
                        ref: mc.ref,
                        type: mc.type,
                        pattern: mc.pattern
                    })),
                    exitRef: ec.exitRef
                })),
                topicRef: q.topicRef,
                choices: 'choices' in q ? q.choices.map(c => ({
                    ref: c.ref,
                    title: c.title
                })) : [],
                quizResponses: 'choices' in q ? q.choices.map(c => ({
                    value: c.title,
                    score: c.score
                })) : [],
                quizAllCorrectRequired: q.quizAllCorrectRequired
            })),
            quizResultSettings: form.quizResultSettings.map(s => ({
                title: s.title,
                scores: s.scores,
                columns: s.columns.map(col => col.title),
                classes: s.classes.map(cls => ({
                    minScore: cls.minScore,
                    maxScore: cls.maxScore,
                    columns: cls.columns.map(col => col.title),
                }))
            }))
        }),
        updateData: (formData) => {
            formManager.loadData(formData);
        },
        addQuestion: () => {
            setFormState(_form => {
                const newQuestion = {
                    ref: generateRef(),
                    id: 0,
                    title: "",
                    type: QUESTION.TYPES.SINGLE_CHOICE,
                    choices: [
                        {
                            ref: generateRef(),
                            title: '',
                            score: 0
                        },
                        {
                            ref: generateRef(),
                            title: '',
                            score: 0
                        }
                    ],
                    defaultExitRef: QUESTION.REF.NEXT,
                    position: _form.questions.length + 1,
                    constraints: getRequiredConstraintTypes(QUESTION.TYPES.SINGLE_CHOICE).map(type => ({
                        ref: generateRef(),
                        id: 0,
                        condition: {
                            type: type,
                            pattern: ""
                        },
                        default: true
                    })),
                    exitConditions: [],
                    possibleExitRefs: _editor.findQuestionPossibleExitRef(_form.questions, []),
                    quizAllCorrectRequired: true
                };

                const questions = _form.questions.map((question, questionIndex) => ({
                    ...question,
                    possibleExitRefs: _editor.findQuestionPossibleExitRef(_form.questions, [questionIndex], newQuestion)
                }));

                triggerEvent('questionAdded', [newQuestion]);
                return {..._form, questions: [...questions, newQuestion], dirty: true};
            });
        },
        editTitle: (title) => {
            setFormState(form => ({...form, title: title, dirty: true}));
        },
        editDescription: (description) => {
            setFormState(form => ({...form, description: description, dirty: true}));
        },
        editRecipients: (recipients) => {
            setFormState(form => ({...form, recipients: recipients, dirty: true}));
        },
        editTheme: (theme) => {
            setFormState(form => ({...form, theme: theme, dirty: true}));
        },
        editOpen: (open, keepDirty) => {
            setFormState(form => ({...form, open: open, dirty: keepDirty ? form.dirty : true}));
        },
        editAnonymous: (anonymous) => {
            setFormState(form => ({...form, anonymous: anonymous, dirty: true}));
        },
        editRepeatResponse: (repeatResponse) => {
            setFormState(form => ({...form, repeatResponse: repeatResponse, dirty: true}));
        },
        editResponseScheduling: (responseScheduling) => {
            setFormState(form => ({
                ...form,
                responseScheduling: {...form.responseScheduling, ...responseScheduling},
                dirty: true
            }));
        },
        resetDirty: () => {
            setFormState(form => form.dirty ? {...form, dirty: false} : form);
        }
    }), [_editor, addGeneratedRef, generateRef, triggerEvent]);

    const permissionsManager = useMemo(() => ({
        add: (user) => {
            setFormState(form => ({
                ...form,
                permissions: (() => {
                    const list = form.permissions.filter(p => p.user.id !== user.id);
                    list.push({
                        user: {
                            id: user.id,
                            email: user.email,
                            name: user.name
                        },
                        readOnly: false,
                        canEditForm: false,
                        canViewResponses: false,
                        canDeleteResponses: false,
                        canEditResponses: false
                    });
                    return list;
                })(),
                dirty: true
            }));
        },
        delete: (userId) => {
            setFormState(form => ({
                ...form,
                permissions: form.permissions.filter(p => p.user.id !== userId),
                dirty: true
            }));
        },
        editPermissionType: (userId, permissionType, enabled) => {
            setFormState(form => ({
                ...form,
                permissions: form.permissions.map(p => {
                    if (!p.readOnly && p.user.id === userId) {
                        p = {...p};
                        if (!enabled &&
                            permissionType === "canViewResponses") {
                            p.canDeleteResponses = false;
                            p.canEditResponses = false;
                        } else if (enabled &&
                            (permissionType === "canDeleteResponses"
                                || permissionType === "canEditResponses")) {
                            p.canViewResponses = true;
                        }
                        p[permissionType] = enabled;
                    }
                    return p;
                }),
                dirty: true
            }));
        }
    }), []);

    const topicsManager = useMemo(() => ({
        add: (topic) => {
            setFormState(form => ({
                ...form,
                topics: [
                    ...form.topics, topic
                ],
                dirty: true
            }));
        },
        createNew: (topicData) => ({
            ref: generateRef(),
            title: topicData.title,
            position: topicData.position,
            childLinks: [],
            totalQuestions: 0
        }),
        editPosition: (topicRef, position) => {
            _editor.editTopic(topicRef, t => t.position = position);
        },
        editTitle: (topicRef, title) => {
            _editor.editTopic(topicRef, t => t.title = title);
        },
        getTopicQuestionsTotal: (questions, topicRef) => {
            return questions.filter(q => q.topicRef === topicRef).length;
        },
        delete: (topicRef) => {
            setFormState(form => ({
                ...form,
                topics: form.topics.filter(t => t.ref !== topicRef).map(t => ({
                    ...t,
                    childLinks: t.childLinks.filter(l => l.targetRef !== topicRef)
                })),
                questions: form.questions.map(q => {
                    if (q.topicRef === topicRef) {
                        q = {...q, topicRef: ""};
                    }
                    return q;
                }),
                dirty: true
            }));
        },
        addLink: (topicRef, linkData) => {
            _editor.editTopic(topicRef, t => {
                t.childLinks = [
                    ...t.childLinks, {
                        targetRef: linkData.targetRef,
                        weight: linkData.weight
                    }
                ];
            });
        },
        editLinkWeight: (sourceTopicRef, targetTopicRef, weight) => {
            _editor.editTopic(sourceTopicRef, t => {
                t.childLinks = t.childLinks.map(l => {
                    if (l.targetRef === targetTopicRef) {
                        l = {...l, weight: Math.max(Math.min(weight, 100), 0)};
                    }
                    return l;
                });
            });
        },
        deleteLink: (sourceTopicRef, targetTopicRef) => {
            _editor.editTopic(sourceTopicRef, t => {
                t.childLinks = t.childLinks.filter(l => l.targetRef !== targetTopicRef);
            });
        }
    }), [_editor, generateRef]);

    const questionsManager = useMemo(() => ({
        editTitle: (questionRef, title) => {
            _editor.editQuestion(questionRef, q => {
                q.title = title;
            }, {
                updatePossibleExitRefs: true
            });
        },
        editDescription: (questionRef, description) => {
            _editor.editQuestion(questionRef, q => {
                q.description = description;
            });
        },
        editDefaultExitRef: (questionRef, ref) => {
            _editor.editQuestion(questionRef, q => {
                q.defaultExitRef = ref;
            });
        },
        editTopicRef: (questionRef, ref) => {
            _editor.editQuestion(questionRef, q => {
                q.topicRef = ref;
            });
        },
        editquizAllCorrectRequired: (questionRef, allCorrectRequired) => {
            _editor.editQuestion(questionRef, q => {
                q.quizAllCorrectRequired = allCorrectRequired;
            });
        },
        editType: (questionRef, type) => {
            _editor.editQuestion(questionRef, question => {
                let resettedItems = 0;

                question.type = type;

                if (question.type === QUESTION.TYPES.SINGLE_CHOICE
                    || question.type === QUESTION.TYPES.MULTIPLE_CHOICE
                    || question.type === QUESTION.TYPES.MEASURE) {
                    if (!('choices' in question)) {
                        question.choices = [
                            {
                                ref: generateRef(),
                                title: '',
                                score: 0
                            },
                            {
                                ref: generateRef(),
                                title: '',
                                score: 0
                            }
                        ];
                    }
                } else if ('choices' in question) {
                    const nonEmptyChoicesTotal = question.choices.filter(c => c.title !== "").length;
                    if (nonEmptyChoicesTotal > 0) {
                        resettedItems++;
                    }
                    delete question.choices;
                }

                const allowedTypes = getQuestionsMatchTypes(question.type);
                const allowedTypesValues = allowedTypes.reduce((p, c) => (p.push(c.value) && p), []);
                let deleteConditionsTotal = _editor.deleteConditions(question,
                    condition => !allowedTypesValues.includes(condition.type));
                if (deleteConditionsTotal > 0) {
                    resettedItems++;
                }

                if (!question.constraints.length) {
                    question.constraints = getRequiredConstraintTypes(question.type).map(type => ({
                        ref: generateRef(),
                        id: 0,
                        condition: {
                            type: type,
                            pattern: ""
                        },
                        default: true
                    }));
                }

                if (resettedItems && !window.confirm((t("form_editor.question_incompatibility_confirm")))) {
                    return false;
                }
            });
        },
        move: (questionRef, direction) => {
            setFormState(_form => {
                const questionIndex = _form.questions.findIndex(q => q.ref === questionRef);
                if (questionIndex < 0) {
                    return _form;
                }

                let currentIndex, finalIndex;
                if (direction === 'up') {
                    if (questionIndex > 0) {
                        currentIndex = questionIndex;
                        finalIndex = questionIndex - 1;
                    } else {
                        return _form;
                    }
                } else {
                    if (questionIndex < _form.questions.length - 1) {
                        currentIndex = questionIndex;
                        finalIndex = questionIndex + 1;
                    } else {
                        return _form;
                    }
                }

                let questions = [..._form.questions];

                const movedQuestion = {...questions[currentIndex]};
                const replacedQuestion = {...questions[finalIndex]};

                const tempPosition = movedQuestion.position;
                movedQuestion.position = replacedQuestion.position;
                replacedQuestion.position = tempPosition;

                questions[finalIndex] = movedQuestion;
                questions[currentIndex] = replacedQuestion;
                questions.forEach((q, i) => {
                    if (i !== finalIndex && i !== currentIndex) {
                        q = {...q};
                    }
                    q.possibleExitRefs = _editor.findQuestionPossibleExitRef(questions, [i]);
                    questions[i] = q;
                });

                triggerEvent('questionMoved', [movedQuestion]);
                return {..._form, questions: questions, dirty: true};
            });
        },
        delete: (questionRef) => {
            if (window.confirm(t('form_editor.are_you_sure_you_want_to_delete'))) {
                setFormState(_form => {
                    const deletedQuestion = _form.questions.find(q => q.ref === questionRef);
                    if (!deletedQuestion) {
                        return _form;
                    }

                    let questions = _form.questions.filter(q => q !== deletedQuestion);
                    let positionCounter = 0;

                    questions = questions.map((question, questionIndex) => {
                        question = {...question};
                        question.position = ++positionCounter;
                        if (question.defaultExitRef === deletedQuestion.ref) {
                            question.defaultExitRef = deletedQuestion.defaultExitRef === question.ref
                                ? null
                                : deletedQuestion.defaultExitRef;
                        }
                        question.exitConditions.forEach(c => {
                            if (c.exitRef === deletedQuestion.ref) {
                                c.exitRef = deletedQuestion.defaultExitRef === question.ref
                                    ? null
                                    : deletedQuestion.defaultExitRef;
                            }
                        });
                        question.possibleExitRefs = _editor.findQuestionPossibleExitRef(
                            questions, [questionIndex]);
                        return question;
                    });

                    return {..._form, questions: questions, dirty: true};
                });
            }
        }
    }), [_editor, generateRef, triggerEvent]);

    const exitConditionsManager = useMemo(() => ({
        add: (questionRef) => {
            _editor.editQuestion(questionRef, question => {
                const possibleMatchTypes = getQuestionsMatchTypes(question.type);
                question.exitConditions = [...question.exitConditions, {
                    ref: generateRef(),
                    matchConditions: [
                        {
                            ref: generateRef(),
                            type: possibleMatchTypes.length > 0 ? possibleMatchTypes[0].value : "",
                            pattern: ""
                        }
                    ],
                    exitRef: ""
                }];
            });
        },
        delete: (questionRef, ecRef) => {
            _editor.editQuestion(questionRef, q => {
                q.exitConditions = q.exitConditions.filter(ec => ec.ref !== ecRef);
            });
        },
        editGoto: (questionRef, ecRef, exitRef) => {
            _editor.editExitCondition(questionRef, ecRef, ec => ec.exitRef = exitRef);
        },
        editType: (questionRef, ecRef, mcRef, type) => {
            _editor.editMatchCondition(questionRef, ecRef, mcRef, mc => mc.type = type);
        },
        editPattern: (questionRef, ecRef, mcRef, pattern) => {
            _editor.editMatchCondition(questionRef, ecRef, mcRef, mc => mc.pattern = pattern);
        },
        addMatch: (questionRef, exLey) => {
            _editor.editExitCondition(questionRef, exLey, (ec, question) => {
                const possibleMatchTypes = getQuestionsMatchTypes(question.type);
                ec.matchConditions = [...ec.matchConditions, {
                    ref: generateRef(),
                    type: possibleMatchTypes.length > 0 ? possibleMatchTypes[0].value : "",
                    pattern: ""
                }];
            });
        },
        deleteMatch: (questionRef, ecRef, mcRef) => {
            _editor.editExitCondition(questionRef, ecRef, ec => {
                ec.matchConditions = ec.matchConditions.filter(mc => mc.ref !== mcRef);
            });
        }
    }), [_editor, generateRef]);

    const constraintsManager = useMemo(() => ({
        add: (questionRef) => {
            _editor.editQuestion(questionRef, question => {
                question.constraints = [...question.constraints, {
                    ref: generateRef(),
                    id: 0,
                    condition: {
                        type: "",
                        pattern: ""
                    },
                    default: false
                }];
            });
        },
        delete: (questionRef, constraintRef) => {
            _editor.editQuestion(questionRef, question => {
                question.constraints = question.constraints.filter(c => c.ref !== constraintRef);
            });
        },
        editType: (questionRef, constraintRef, type) => {
            _editor.editConstraint(questionRef, constraintRef, ct => ct.condition = {...ct.condition, type: type});
        },
        editPattern: (questionRef, constraintRef, pattern) => {
            _editor.editConstraint(questionRef, constraintRef, ct => ct.condition = {
                ...ct.condition,
                pattern: pattern
            });
        }
    }), [_editor, generateRef]);

    const choicesManager = useMemo(() => ({
        add: (questionRef) => {
            _editor.editQuestion(questionRef, question => {
                question.choices = [...question.choices, {
                    ref: generateRef(),
                    title: '',
                    score: 0
                }];
            });
        },
        delete: (questionRef, choiceRef) => {
            _editor.editQuestion(questionRef, question => {
                const removedChoice = question.choices.find(choice => choice.ref === choiceRef);

                if (removedChoice) {
                    question.choices = question.choices
                        .filter(choice => choice.ref !== choiceRef);

                    if (removedChoice.title !== "") {
                        _editor.deleteConditions(question, condition =>
                            condition.pattern === removedChoice.title
                            && (condition.type === QUESTION.MATCH_CONDITION.TYPES.CHOICE_IS_SELECTED
                                || condition.type === QUESTION.MATCH_CONDITION.TYPES.CHOICE_IS_NOT_SELECTED));
                    }
                }
            });
        },
        editTitle: (questionRef, choiceRef, newTitle) => {
            _editor.editChoice(questionRef, choiceRef, (choice, question) => {
                if (choice.title !== "") {
                    _editor.editConditions(question, condition =>
                            condition.pattern === choice.title
                            && (condition.type === QUESTION.MATCH_CONDITION.TYPES.CHOICE_IS_SELECTED
                                || condition.type === QUESTION.MATCH_CONDITION.TYPES.CHOICE_IS_NOT_SELECTED),
                        condition => condition.pattern = newTitle);
                }
                choice.title = newTitle;
            });
        },
        editScore: (questionRef, choiceRef, newScore) => {
            _editor.editChoice(questionRef, choiceRef, (choice) => {
                choice.score = Math.max(Math.min(newScore, 999), -999);
            });
        }
    }), [_editor, generateRef]);

    const quizResultsManager = useMemo(() => ({
        addSettings: () => {
            setFormState(form => ({
                ...form,
                quizResultSettings: [
                    ...form.quizResultSettings,
                    {
                        ref: generateRef(),
                        title: "",
                        scores: [],
                        columns: [
                            {ref: generateRef(), title: ""},
                            {ref: generateRef(), title: ""}
                        ],
                        classes: [
                            {
                                ref: generateRef(),
                                minScore: "",
                                maxScore: "",
                                columns: [
                                    {ref: generateRef(), title: ""},
                                    {ref: generateRef(), title: ""}
                                ],
                            }
                        ]
                    }
                ],
                dirty: true
            }));
        },
        deleteSettings: (settingsIndex) => {
            setFormState(form => ({
                ...form,
                quizResultSettings: form.quizResultSettings
                    .filter((_settings, _settingsIndex) => _settingsIndex !== settingsIndex),
                dirty: true
            }));
        },
        editSettingsTitle: (settingsIndex, value) => {
            _editor.editSettingsByIndex(settingsIndex, (_settings) => ({
                ..._settings,
                title: value
            }));
        },
        editSettingsColumnTitle: (settingsIndex, colIndex, newValue) => {
            _editor.editSettingsByIndex(settingsIndex, (_settings) => ({
                ..._settings,
                columns: _settings.columns.map((_col, _colIndex) => (colIndex === _colIndex ? {
                    ..._col,
                    title: newValue
                } : _col))
            }));
        },
        addSettingsClass: (settingsIndex) => {
            _editor.editSettingsByIndex(settingsIndex, (_settings) => ({
                ..._settings,
                classes: [
                    ..._settings.classes, {
                        ref: generateRef(),
                        minScore: "",
                        maxScore: "",
                        columns: _settings.columns.map(() => ({ref: generateRef(), title: ""})),
                    }
                ]
            }));
        },
        addSettingsColumn: (settingsIndex) => {
            _editor.editSettingsByIndex(settingsIndex, (_settings) => ({
                ..._settings,
                columns: [..._settings.columns, {ref: generateRef(), title: ""}],
                classes: _settings.classes.map(_class => ({
                    ..._class, columns: [
                        ..._class.columns,
                        {ref: generateRef(), title: ""}
                    ]
                }))
            }));
        },
        editSettingsClassColumn: (settingsIndex, classIndex, colIndex, newValue) => {
            _editor.editSettingsByIndex(settingsIndex, (_settings) => ({
                ..._settings,
                classes: _settings.classes.map((_class, _classIndex) => {
                    if (_classIndex === classIndex) {
                        return {
                            ..._class,
                            columns: _class.columns.map((_col, _colIndex) => (colIndex === _colIndex ? {
                                ..._col,
                                title: newValue
                            } : _col))
                        };
                    }
                    return _class;
                })
            }));
        },
        editSettingsClassInterval: (settingsIndex, classIndex, intervalId, newValue) => {
            let sanitizedValue = parseInt(newValue);
            if (isNaN(sanitizedValue)) {
                sanitizedValue = 0;
            }
            _editor.editSettingsByIndex(settingsIndex, (_settings) => ({
                ..._settings,
                classes: _settings.classes.map((_class, _classIndex) => {
                    if (_classIndex === classIndex) {
                        return {
                            ..._class,
                            [intervalId === 'min' ? 'minScore' : 'maxScore']: sanitizedValue
                        };
                    }
                    return _class;
                })
            }));
        },
        deleteSettingsColumn: (settingsIndex, colIndex) => {
            _editor.editSettingsByIndex(settingsIndex, (_settings) => {
                return {
                    ..._settings,
                    columns: _settings.columns.filter((_col, _colIndex) => _colIndex !== colIndex),
                    classes: _settings.classes.map(_class => ({
                        ..._class,
                        columns: _class.columns.filter((_col, _colIndex) => _colIndex !== colIndex)
                    }))
                };
            });
        },
        deleteSettingsClass: (settingsIndex, settingsClassIndex) => {
            _editor.editSettingsByIndex(settingsIndex, (_settings) => ({
                ..._settings,
                classes: _settings.classes.filter((_class, _classIndex) => _classIndex !== settingsClassIndex)
            }));
        }
    }), [_editor, generateRef]);

    const eventsRef = useRef({
        addListener: addListener,
        removeListener: removeListener
    });

    const managersRef = useRef({
        form: formManager,
        questions: questionsManager,
        permissions: permissionsManager,
        choices: choicesManager,
        constraints: constraintsManager,
        exitConditions: exitConditionsManager,
        quizResultsManager: quizResultsManager,
        topics: topicsManager
    });

    return {
        form,
        events: eventsRef.current,
        managers: managersRef.current
    };
}