
export function scrollToElementById(id, options = {}) {
    const el = window.document.getElementById(id);
    if (el) {
        scrollToElement(el, options);
    }
}

export function scrollToElementByRef(ref, options = {}) {
    if (ref.current) {
        scrollToElement(ref.current, options);
    }
}

export function scrollToElement(el, options = {}) {
    let height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    let scrollContainer;
    let dialogScrollBody = document.getElementsByClassName('MuiDialog-scrollBody');
    if (dialogScrollBody.length === 1) {
        scrollContainer = dialogScrollBody[0];
    } else {
        scrollContainer = window;
    }

    scrollContainer.scroll({
        top: el.offsetTop - (options.centered ? Math.max((height - el.clientHeight) / 2, 0) : 20),
        behavior: 'smooth'
    });
}