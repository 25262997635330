import React from "react";
import './BubblesBackground.scss'
import {
    CalendarTodayOutlined,
    CheckBoxOutlined, GradeOutlined, Link as LinkIcon, MailOutline,
    RadioButtonCheckedOutlined,
    RadioOutlined, TextsmsOutlined, TopicOutlined,
    WrapTextOutlined
} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Background from "../assets/background.svg";

export function BubblesBackground() {
    return (
        <div className="bg-bubbles-wrapper">
            <div style={{backgroundImage: 'url(' + Background + ')'}} className="bg-bubbles-overlay"></div>
            <ul className="bg-bubbles">
                <li><RadioOutlined /></li>
                <li><WrapTextOutlined /></li>
                <li><RadioButtonCheckedOutlined /></li>
                <li><CheckBoxOutlined /></li>
                <li><CalendarTodayOutlined /></li>
                <li><GradeOutlined /></li>
                <li><LinkIcon /></li>
                <li><MailOutline /></li>
                <li><TextsmsOutlined /></li>
                <li><TopicOutlined /></li>
            </ul>
        </div>
    );
}

export function BubblesLayout({children}) {
    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <Grid item xs={false} sm={4} md={7}>
                <BubblesBackground />
            </Grid>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                {children}
            </Grid>
        </Grid>
    );
}