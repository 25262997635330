import useBeforeUnload from '../hooks/useBeforeUnload';
import {Prompt} from "react-router";
import React from "react";

const LeaveConfirm = ({when, message}) => {
    // Window beforeunload leave confirm
    useBeforeUnload(() => when ? message : null);

    // Route leave confirm
    return <Prompt when={when} message={message} />;
};

export default LeaveConfirm;