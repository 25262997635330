import React, {useCallback, useEffect, useState} from "react";
import {useDialog} from "../../providers/DialogProvider";
import AppConsts from "../../app/AppConsts";
import {
    Alert,
    Avatar,
    Box, Chip,
    CircularProgress,
    DialogContent,
    DialogTitle,
    Divider, Grid,
    IconButton, Paper, Rating,
    Stack, Typography
} from "@mui/material";
import {
    AnnouncementOutlined,
    ArrowBackOutlined, CheckBoxOutlineBlankOutlined,
    CheckBoxOutlined,
    CheckCircleOutlineOutlined,
    DeleteOutlined, MarkChatReadOutlined,
    MarkChatUnreadOutlined,
    RadioButtonCheckedOutlined, RadioButtonUncheckedOutlined, SaveAltOutlined, TopicOutlined
} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {useSnackbar} from "../../providers/SnackbarProvider";
import {ICON_STYLES, Theme} from "../../app/Theme";
import {logError} from "../../utils/logging";
import {QUESTION} from "../../utils/formUtils";
import {green, lightGreen, lime, orange, purple, red} from "@mui/material/colors";
import {FormResponsesExportMenu} from "./FormResponsesExportMenu";
import {httpClient} from "../../utils/httpClient";
import {t} from "i18next";

export function getFormResponseDialogOptions(form, responseId, triggerDeleted = undefined, triggerReadChanged = undefined) {
    return {
        children: <FormResponseDialog
            form={form}
            responseId={responseId}
            triggerDeleted={triggerDeleted}
            triggerReadChanged={triggerReadChanged}/>,
        maxWidth: 'md',
        fullWidth: true
    };
}

function FormResponseDialog(props) {
    const form = props.form;
    const responseId = props.responseId;
    const triggerDeleted = props.triggerDeleted;
    const triggerReadChanged = props.triggerReadChanged;

    const {closeDialog} = useDialog();
    const {createSnackbar} = useSnackbar();
    const [viewState, setViewState] = useState({code: "loading"});
    const [response, setResponse] = useState({});

    useEffect(() => {
        const cancelTokenSource = httpClient.cancelToken();
        let isMounted = true;

        httpClient.get(`${AppConsts.API_PATH}/forms/${form.id}/responses/${responseId}`, {
            cancelToken: cancelTokenSource.token
        }).then(response => {
            if (!isMounted) {
                return;
            }
            if (response.data.errors) {
                setViewState({
                    code: "loadingError",
                    text: response.data.errors[0].message
                });
            } else {
                const responseData = response.data.response;
                setResponse({
                    id: responseData.id,
                    creatorName: responseData.creatorName,
                    createdDateTime: responseData.createdDateTime,
                    preview: responseData.preview,
                    read: responseData.read,
                    totalQuestions: responseData.totalQuestions,
                    topics: (() => {
                        let topics = {};
                        for (let id in responseData.topics) {
                            let topicData = responseData.topics[id];
                            topics[id] = {
                                id: topicData.id,
                                title: topicData.title,
                                averageKnowledgeScore: topicData.averageKnowledgeScore
                            };
                        }
                        return topics;
                    })(),
                    quizResult: responseData.quizResult === null ? null : {
                        maxScore: responseData.quizResult.maxScore,
                        totalScore: responseData.quizResult.totalScore,
                        topicResults: responseData.quizResult.topicResults.map(tr => ({
                            topicId: tr.topicId,
                            knowledgeScore: tr.knowledgeScore
                        })),
                        classesResults: responseData.quizResult.classesResults,
                        resultSettings: responseData.quizResultSettings.map(settings => ({
                            title: settings.title,
                            columns: settings.columns,
                            classes: settings.classes,
                        })),
                        questionResults: responseData.quizResult.questionResults.map(qr => ({
                            questionId: qr.questionId,
                            obtainedScore: qr.obtainedScore,
                            maxScore: qr.maxScore,
                            valuesResult: qr.valuesResult.map(vr => ({
                                correct: vr.correct,
                                value: vr.value,
                                given: vr.given,
                                obtainedScore: vr.obtainedScore
                            }))
                        }))
                    },
                    responses: responseData.responses.map(r => {
                        const question = responseData.questions.find(q => q.id === r.questionId);
                        return {
                            number: r.number,
                            question: {
                                id: question.id,
                                title: question.title,
                                description: question.description,
                                type: question.type,
                                position: question.position,
                                topic: question.topicId ? {
                                    title: responseData.topics[question.topicId].title
                                } : null
                            },
                            readableValues: r.readableValues,
                            groupableValues: r.groupableValues
                        };
                    })
                });
                setViewState({code: "loaded"});
            }
        }).catch((e) => {
            logError(e);
            if (!isMounted) {
                return;
            }
            setViewState({
                code: "loadingError",
                text: t('common.sorry_something_went_wrong')
            });
        });

        return () => {
            isMounted = false;
            cancelTokenSource.cancel();
        }
    }, [form.id, responseId]);

    const handleDelete = useCallback(() => {
        if (!window.confirm(t('form_individual.are_you_sure_you_want_to_delete'))) {
            return;
        }
        httpClient.delete(`${AppConsts.API_PATH}/forms/${form.id}/responses/${responseId}`).then(response => {
            if (response.data.errors) {
                createSnackbar({
                    message: response.data.errors[0].message,
                    autoHideDuration: 2000
                });
            } else {
                createSnackbar({
                    message: t('form_individual.response_deleted'),
                    autoHideDuration: 2000
                });
                closeDialog();
                if (triggerDeleted) {
                    triggerDeleted(responseId);
                }
            }
        }).catch((e) => {
            logError(e);
            createSnackbar({
                message: t('common.sorry_something_went_wrong'),
                autoHideDuration: 2000
            });
        });
    }, [form.id, responseId, closeDialog, createSnackbar, triggerDeleted]);

    const handleSetReadChange = useCallback(() => {
        httpClient.put(`${AppConsts.API_PATH}/forms/${form.id}/responses/${responseId}`, {read: !response.read}).then(r => {
            if (r.data.errors) {
                createSnackbar({
                    message: r.data.errors[0].message,
                    autoHideDuration: 2000
                });
            } else {
                const formResponse = r.data.response;
                if (triggerReadChanged) {
                    triggerReadChanged(formResponse.id, formResponse.read);
                }
                setResponse((response => ({...response, read: formResponse.read})));
                createSnackbar({
                    message: formResponse.read
                        ? t('form_individual.response_marked_as_read')
                        : t('form_individual.response_marked_as_unread'),
                    autoHideDuration: 2000
                });
            }
        }).catch((e) => {
            logError(e);
            createSnackbar({
                message: t('common.sorry_something_went_wrong'),
                autoHideDuration: 2000
            });
        });
    }, [createSnackbar, response.read, form.id, responseId, triggerReadChanged]);

    const [exportMenuAnchor, setExportMenuAnchor] = useState(null);
    const closeExportMenu = useCallback(() => {
        setExportMenuAnchor(null);
    }, [setExportMenuAnchor]);

    if (viewState.code === "loading") {
        return (
            <DialogContent>
                <Box sx={{justifyContent: 'center', display: 'flex', py: 4}}><CircularProgress/></Box>
            </DialogContent>
        );
    }

    if (viewState.code === "loadingError") {
        return (
            <DialogContent>
                <Alert severity="error">{viewState.text}</Alert>
            </DialogContent>
        );
    }

    return (
        <>
            <DialogTitle>
                <Grid container alignItems="center" sx={{width: '100%'}} spacing={2}>
                    <Grid item xs={12} sm={10}>
                        <Stack direction="row" alignItems="center">
                            <IconButton onClick={closeDialog} sx={{mr: 1}}>
                                <ArrowBackOutlined/>
                            </IconButton>
                            <Box style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                {response.preview}
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Box display="flex" justifyContent={{xs: 'left', sm: 'right'}}>
                            <Chip
                                icon={<AnnouncementOutlined/>}
                                variant='outlined'
                                color={response.read ? 'default' : 'warning'}
                                label={response.read ? t('form_individual.read') : t('form_individual.unread')}
                                size="small"/>
                        </Box>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} direction="row" sx={{mt: 2}} justifyContent="space-between">
                    <Stack direction="row" spacing={2}>
                        {response.read ?
                            <Button variant="text"
                                    color="neutral"
                                    startIcon={<MarkChatUnreadOutlined/>}
                                    onClick={() => handleSetReadChange()}
                                    disabled={!form.canEditResponses}>
                                {t('form_individual.mark_as_unread')}
                            </Button>
                            :
                            <Button variant="text"
                                    color="warning"
                                    startIcon={<MarkChatReadOutlined/>}
                                    onClick={() => handleSetReadChange()}
                                    disabled={!form.canEditResponses}>
                                {t('form_individual.mark_as_read')}
                            </Button>
                        }
                        <Button onClick={(e) => setExportMenuAnchor(e.currentTarget)}>
                            <SaveAltOutlined sx={{fontSize: 14, mr: 1}}/>
                            {t('form_individual.export_as')}
                        </Button>
                    </Stack>
                    <Button
                        variant="text"
                        color="error"
                        startIcon={<DeleteOutlined/>}
                        onClick={handleDelete}
                        disabled={!form.canDeleteResponses}>
                        {t('common.delete')}
                    </Button>
                </Stack>
                <FormResponsesExportMenu
                    form={form}
                    responseId={response.id}
                    openerAnchor={exportMenuAnchor}
                    closeMenu={closeExportMenu}/>
                <Divider sx={{my: 3}}/>
                {response.responses.map(qr => {
                    if (response.quizResult) {
                        const quizResult = response.quizResult.questionResults.find(qz => qz.questionId === qr.question.id);
                        if (quizResult) {
                            return <QuestionQuizResult
                                key={qr.number}
                                formResponse={response}
                                questionResponse={qr}
                                quizResult={quizResult}/>;
                        }
                    }
                    return <QuestionResponse
                        key={qr.number}
                        formResponse={response}
                        questionResponse={qr}/>;
                })}
                <Stack direction="row" spacing={3} alignItems="center">
                    <Avatar sx={{bgcolor: response.read ? Theme.palette.neutral.main : Theme.palette.warning.main}}>
                        <CheckCircleOutlineOutlined/>
                    </Avatar>
                    <Box>
                        <Typography variant="h3" gutterBottom>
                            {t('form_individual.completed')}
                        </Typography>
                        <Typography color="text.secondary">
                            {response.creatorName
                                ? <Typography variant="span" color="text.primary">{response.creatorName}</Typography>
                                : <em>{t('common.anonymous')}</em>}
                            <span> - </span>
                            {response.createdDateTime}
                        </Typography>
                    </Box>
                </Stack>
                {response.quizResult && <>
                    <Divider orientation="horizontal" sx={{mt: 3, mb: 3}}/>
                    <QuizFinalResult
                        result={response.quizResult}
                        topics={response.topics}/>
                </>}
            </DialogContent>
        </>
    );
}

function QuestionResponse({formResponse, questionResponse}) {
    return (
        <>
            <Stack direction={{xs: "column", sm: "row"}} spacing={3}>
                <Avatar sx={{bgcolor: formResponse.read ? Theme.palette.neutral.main : Theme.palette.warning.main}}>
                    {questionResponse.number}
                </Avatar>
                <Box flexGrow={1} sx={{mt: 3}}>
                    <Typography variant="h3" gutterBottom>
                        {questionResponse.question.title}
                    </Typography>
                    {questionResponse.readableValues.map((readableValues, i) =>
                        <Box key={i}>
                            <QuestionResponseValue
                                value={questionResponse.groupableValues[i]}
                                readableValues={readableValues}
                                question={questionResponse.question}
                                given={true}/>
                        </Box>)}
                </Box>
            </Stack>
            <Divider orientation="vertical" sx={{height: '30px', width: '1px', ml: '20px', mb: '10px'}}/>
        </>
    );
}

function QuestionQuizResult({formResponse, questionResponse, quizResult}) {
    return (
        <>
            <Stack direction={{xs: "column", sm: "row"}} spacing={3}>
                <Avatar sx={{bgcolor: formResponse.read ? Theme.palette.neutral.main : Theme.palette.warning.main}}>
                    {questionResponse.number}
                </Avatar>
                <Box flexGrow={1} sx={{mt: 3}}>
                    <Grid container sx={{width: '100%'}} spacing={1} columns={12}>
                        <Grid item xs={12} sm={10}>
                            <Typography variant="h2" gutterBottom>
                                {questionResponse.question.title}
                            </Typography>
                            {questionResponse.question.topic &&
                                <Stack direction="row" spacing={1} color="text.secondary" alignItems="center"
                                       sx={{mb: 2}}>
                                    <TopicOutlined sx={ICON_STYLES.icon21}/>
                                    <Typography>
                                        {questionResponse.question.topic.title}
                                    </Typography>
                                </Stack>}
                            {quizResult.valuesResult.map((vr, i) =>
                                <Box key={i}>
                                    <QuestionResponseValue
                                        value={vr.value}
                                        readableValues={vr.value}
                                        question={questionResponse.question}
                                        given={vr.given}
                                        quiz={{
                                            obtainedScore: vr.obtainedScore,
                                            correct: vr.correct
                                        }}/>
                                </Box>)}
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Box display="flex" justifyContent="right">
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Chip variant="outlined"
                                          color="primary"
                                          label={quizResult.obtainedScore} sx={{fontWeight: 700}}/>
                                    <Typography variant="body" color="text.secondary">/</Typography>
                                    <Typography variant="body" color="text.primary">{quizResult.maxScore}</Typography>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
            <Divider orientation="vertical" sx={{height: '30px', width: '1px', ml: '20px', mb: '10px'}}/>
        </>);
}

function QuizFinalResult({result, topics}) {
    return (
        <>
            <Grid container sx={{width: '100%'}} alignItems="top" spacing={2}>
                <Grid item sm={12} md={8}>
                    {result.topicResults.length > 0 && <>
                        <Stack direction="row" spacing={1} alignItems="center" sx={{mb: 2}}>
                            <TopicOutlined sx={ICON_STYLES.icon21}/>
                            <Typography variant="h3" sx={{mb: 2}}>
                                Topics knowledge:
                            </Typography>
                        </Stack>
                        {result.topicResults.map(tr =>
                            <Grid container sx={{width: '100%', mt: {xs: 3, sm: 1}}} key={tr.topicId} columns={20}>
                                <Grid item xs={20} sm={12}
                                      style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                    {topics[tr.topicId].title}
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    <Box display="flex" justifyContent={{sm: "center"}}>
                                        <QuizTopicPercentageChip percentage={tr.knowledgeScore}/>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography color="text.secondary">
                                        Avg {topics[tr.topicId].averageKnowledgeScore + "%"}
                                    </Typography>
                                </Grid>
                            </Grid>)}
                    </>}
                </Grid>
                {!result.resultSettings.length &&
                    <Grid item xs>
                        <Box display="flex" justifyContent={{sm: "left", md: "right"}}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="h3" sx={{mr: 1}}>{t('form_individual.final_score')}:</Typography>
                                <Chip variant="outlined"
                                      color="primary"
                                      label={result.totalScore}
                                      sx={{fontWeight: 500, fontSize: 22}}/>
                                <Typography variant="body" color="text.secondary">/</Typography>
                                <Typography variant="body" color="text.primary">{result.maxScore}</Typography>
                            </Stack>
                        </Box>
                    </Grid>}
            </Grid>
            {result.resultSettings.map((settings, resultKey) => (
                <Box key={resultKey}>
                    <Stack direction="row" spacing={1} alignItems="center" sx={{mb: 3}}>
                        <Typography variant="h3" sx={{mr: 1}}>{settings.title}:</Typography>
                        <Chip variant="outlined"
                              color="primary"
                              label={result.totalScore}
                              sx={{fontWeight: 500, fontSize: 22}}/>
                        <Typography variant="body" color="text.secondary">/</Typography>
                        <Typography variant="body" color="text.primary">{result.maxScore}</Typography>
                    </Stack>
                    <Paper variant="outlined" sx={{py: 1}}>
                        <Grid container sx={{width: 1}} spacing={3} alignItems="center">
                            <Grid item xs={3}>
                                <Box sx={{py: 1, px: 3, fontWeight: 700}}>
                                    {t('form_individual.scores_interval')}
                                </Box>
                            </Grid>
                            {settings.columns.map((col, colIndex) =>
                                <Grid item key={colIndex} xs>
                                    <Box sx={{py: 1, px: 3, fontWeight: 700}}>
                                        {col}
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                        {settings.classes.map(settingsClass => {
                            const highlighed = result.classesResults.length === result.resultSettings.length
                                && result.classesResults[resultKey] === settingsClass.id;
                            return <Grid container
                                         alignItems="center"
                                         key={settingsClass.id}
                                         sx={{
                                             my: 1,
                                             bgcolor: highlighed ? Theme.palette.neutral.main : '',
                                             color: highlighed ? Theme.palette.neutral.contrastText : ''
                                         }}>
                                <Grid item xs={3}>
                                    <Box sx={{py: 1, px: 3}}>
                                        {settingsClass.minScore}-{settingsClass.maxScore}
                                    </Box>
                                </Grid>
                                {settingsClass.columns.map((col, colIndex) => (
                                    <Grid item key={colIndex} xs>
                                        <Box sx={{py: 1, px: 3}}>
                                            {col}
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>;
                        })}
                    </Paper>
                </Box>
            ))}
        </>
    );
}

function QuizTopicPercentageChip({percentage}) {
    let color;
    if (percentage >= 100) {
        color = purple[700];
    } else if (percentage >= 90) {
        color = green[700];
    } else if (percentage >= 80) {
        color = green[500];
    } else if (percentage >= 70) {
        color = lightGreen[500];
    } else if (percentage >= 60) {
        color = lime[500];
    } else if (percentage >= 40) {
        color = orange[500];
    } else {
        color = red[500];
    }

    return (
        <Chip
            variant="outlined"
            label={percentage + '%'}
            sx={{
                fontWeight: 500,
                color: color,
                borderColor: color
            }}/>
    );
}

function QuestionResponseValue({value, readableValues, question, given, quiz}) {
    if (readableValues === "") {
        return (
            <Typography color="text.secondary">
                - nd -
            </Typography>
        );
    }

    const color = quiz
        ? given ? Theme.palette.primary.main : Theme.palette.text.primary
        : Theme.palette.text.primary;

    if (question.type === QUESTION.TYPES.SINGLE_CHOICE) {
        return (
            <Stack direction="row" alignItems="center" color={color} spacing={1}>
                {given
                    ? <RadioButtonCheckedOutlined sx={{fontSize: 16}}/>
                    : <RadioButtonUncheckedOutlined sx={{fontSize: 16}}/>}
                <Typography>{readableValues}</Typography>
                {given && quiz && <QuizResponseValueScore quiz={quiz}/>}
            </Stack>
        );
    }

    if (question.type === QUESTION.TYPES.MULTIPLE_CHOICE) {
        return (
            <Stack direction="row" alignItems="center" color={color} spacing={1}>
                {given
                    ? <CheckBoxOutlined sx={{fontSize: 16}}/>
                    : <CheckBoxOutlineBlankOutlined sx={{fontSize: 16}}/>}
                <Typography>{readableValues}</Typography>
                {given && quiz && <QuizResponseValueScore quiz={quiz}/>}
            </Stack>
        );
    }

    if (question.type === QUESTION.TYPES.RATING) {
        const numberValue = Number(value);

        return (
            <Stack direction="row" alignItems="center" spacing={2}>
                <Rating
                    precision={0.5}
                    value={numberValue > 0 ? numberValue : null}
                    readOnly
                />
                <Box>
                    {numberValue} / 5
                </Box>
            </Stack>
        );
    }

    return <Typography color={color}>{readableValues}</Typography>
}

function QuizResponseValueScore({quiz}) {
    return (
        <Typography color="primary">
            {quiz.obtainedScore > 0 ? `(+${quiz.obtainedScore})` : `(${quiz.obtainedScore})`}
        </Typography>
    );
}