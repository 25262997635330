import {Grid} from "@mui/material";
import Button from "@mui/material/Button";
import {ContentCopyOutlined, Facebook, LinkedIn, Pinterest, Reddit, Telegram, Twitter, WhatsApp} from "@mui/icons-material";
import React from "react";
import {useSnackbar} from "../providers/SnackbarProvider";
import {copyToClipboard} from "../utils/copyToClipboard";

function openShareWindow(url) {
    window.open(url, "shareWindow", "menubar=1,resizable=1,width=600,height=350");
}

function copyShareUrlToClipboard(url, createSnackbar) {
    copyToClipboard(url).then(function () {
        createSnackbar({
            message: "Form URL copied to clipboard!",
            autoHideDuration: 2000
        });
    });
}

export function ShareButtons({url}) {
    const {createSnackbar} = useSnackbar();

    return <>
        <Grid container sx={{width: '100%'}} spacing={1}>
            <Grid item xs={12} sm={6}>
                <Button startIcon={<Facebook />} fullWidth onClick={() => openShareWindow("https://www.facebook.com/sharer/sharer.php?u=" + url)}>
                    Facebook
                </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Button startIcon={<Twitter />} fullWidth onClick={() => openShareWindow("https://twitter.com/share?url=" + url)}>
                    Twitter
                </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Button startIcon={<LinkedIn />} fullWidth onClick={() => openShareWindow("https://www.linkedin.com/sharing/share-offsite/?url=" + url)}>
                    LinkedIn
                </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Button startIcon={<WhatsApp />} fullWidth onClick={() => openShareWindow("https://api.whatsapp.com/send?text=" + url)}>
                    WhatsApp
                </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Button startIcon={<Telegram />} fullWidth onClick={() => openShareWindow("https://telegram.me/share/url?url=" + url)}>
                    Telegram
                </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Button startIcon={<Reddit />} fullWidth onClick={() => openShareWindow("https://www.reddit.com/submit?url=" + url)}>
                    Reddit
                </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Button startIcon={<Pinterest />} fullWidth onClick={() => openShareWindow("https://pinterest.com/pin/create/button/?url=" + url)}>
                    Pinterest
                </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Button startIcon={<ContentCopyOutlined />} fullWidth onClick={() => copyShareUrlToClipboard(url, createSnackbar)}>
                    Copy URL
                </Button>
            </Grid>
        </Grid>
    </>
}