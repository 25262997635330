import React, {useState} from "react";
import {useHistory} from "react-router";
import AppConsts from "../app/AppConsts";
import {PathsMap} from "../app/PageRouting";
import {logError} from "../utils/logging";
import {Alert, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {DialogWrapper} from "../components/DialogWrapper";
import {ArrowBackOutlined} from "@mui/icons-material";
import {httpClient} from "../utils/httpClient";
import {t} from "i18next";
import {Trans} from "react-i18next";

export function useFormDeleteDialog(form, onDelete) {
    return DialogWrapper({
        component: FormDeleteDialog,
        componentProps: {
            form: {
                id: form.id,
                title: form.title
            },
            onDelete: onDelete
        },
        maxWidth: 'sm',
        fullWidth: true
    });
}

function FormDeleteDialog(props) {
    const history = useHistory();

    const form = props.form;
    const closeDialog = props.onClose;
    const onDelete = props.onDelete;
    const [confirmationTextField, setConfirmationTextField] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const confirmationFormId = form.title.replace(/[^0-9a-z]/gi, '').substr(0, 16).toLowerCase();
    const confirmationCode = confirmationFormId || 'pleasedeleteit';
    const deleteEnabled = confirmationTextField.toLowerCase() === confirmationCode;

    const handleDelete = function () {
        if (deleteEnabled) {
            setErrors([]);
            setLoading(true);
            httpClient.delete(`${AppConsts.API_PATH}/forms/${form.id}`).then(response => {
                setLoading(false);
                if (response.data.deleted) {
                    onDelete();
                    closeDialog();
                    history.push(PathsMap.HOME);
                } else {
                    setErrors(response.data.errors);
                }
            }).catch((e) => {
                logError(e);
                setLoading(false);
            });
        }
    };

    return <>
        <DialogTitle>
            <Stack direction="row" alignItems="center">
                <IconButton onClick={props.onClose} sx={{mr: 1}}>
                    <ArrowBackOutlined/>
                </IconButton>
                {t('form.delete_form')}
            </Stack>
        </DialogTitle>
        <DialogContent>
            <Alert severity="error">
                <Trans
                    i18nKey="form_editor.are_you_sure_you_want_to_permanently"
                    values={{ code: confirmationCode }}
                    components={{ b: <strong /> }}
                />
            </Alert>
            <TextField
                autoFocus
                margin="dense"
                onChange={(e) => setConfirmationTextField(e.target.value)}
                id="confirmation_code"
                label={t('common.confirmation_code')}
                type="text"
                fullWidth
                variant="standard"
            />
            {errors.map((error, errorKey) =>
                <Alert severity="error" key={errorKey} sx={{mt: 2}}>{error.message}</Alert>)}
        </DialogContent>
        <DialogActions>
            <LoadingButton onClick={handleDelete}
                           color="error"
                           disabled={!deleteEnabled}
                           loading={loading}
                           loadingIndicator="Loading...">
                {t('form_editor.delete_this_form')}
            </LoadingButton>
            <LoadingButton onClick={closeDialog} color="secondary">
                {t('common.cancel')}
            </LoadingButton>
        </DialogActions>
    </>;
}