import {BrowserRouter} from "react-router-dom";

import {ThemePaletteProvider} from "./Theme";
import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import {PageSwitch} from "./PageRouting";
import SnackbarProvider from "../providers/SnackbarProvider";
import './App.scss';
import DialogProvider from "../providers/DialogProvider";
import {LocalizationProvider} from "@mui/x-date-pickers";
import '../utils/i18n';
import {withTranslation} from "react-i18next";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import UserProvider from "../user-auth/UserProvider";
import {useLocalizationProvider} from "../hooks/useLocalizationProvider";
import {AppGlobal} from "./AppGlobals";

function App() {
    let {adapterLocale, localeText} = useLocalizationProvider();
    return (
        <BrowserRouter basename="/">
            <ThemePaletteProvider>
                <CssBaseline/>
                <UserProvider>
                    <SnackbarProvider>
                        <DialogProvider>
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={adapterLocale}
                                localeText={localeText}>
                                <AppGlobal>
                                    <PageSwitch />
                                </AppGlobal>
                            </LocalizationProvider>
                        </DialogProvider>
                    </SnackbarProvider>
                </UserProvider>
            </ThemePaletteProvider>
        </BrowserRouter>
    );
}

export default withTranslation()(App);
