import {useUser} from "../user-auth/UserProvider";
import {UserRequiredRedirect} from "../user-auth/UserRequiredRedirect";
import * as React from "react";
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {
    Breadcrumbs, Card, CardActionArea, CardActions, CardContent,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {NavLink, Route, Switch, useLocation} from "react-router-dom";
import {AccountCircleOutlined, MailLockOutlined, PasswordOutlined} from "@mui/icons-material";
import {UserSettingsEmail} from "./UserSettingsEmail";
import {UserSettingsPassword} from "./UserSettingsPassword";
import {UserSettingsProfile} from "./UserSettingsProfile";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppConsts from "../app/AppConsts";
import {PathsMap} from "../app/PageRouting";
import {Page} from "../app/Page";
import {t} from "i18next";
import {httpClient} from "../utils/httpClient";
import {logError} from "../utils/logging";
import Button from "@mui/material/Button";
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import {CardMediaBordered} from "../components/CardMediaBordered";
import {AppGlobalContext} from "../app/AppGlobals";

export function useProfileData() {
    const [profileData, setProfileData] = useState(null);
    const loadProfileData = useCallback(() => {
        httpClient.get(`${AppConsts.API_PATH}/users/profile/me`).then(response => {
            if (response.data) {
                setProfileData(response.data);
            }
        }).catch((e) => {
            logError(e);
        });
    }, []);
    useEffect(() => {
        loadProfileData();
    }, [loadProfileData]);
    return {profileData, loadProfileData};
}

export function UserSettings() {
    const location = useLocation();
    const user = useUser();

    const sections = useMemo(() => ({
        profile: {
            title: t('user.profile'),
            description: t('user_settings.edit_your_profile_details'),
            path: PathsMap.USER_SETTINGS_PROFILE,
            component: UserSettingsProfile,
            icon: AccountCircleOutlined
        },
        email: {
            title: t('user.email_address'),
            description: t('user_settings.change_your_account_email'),
            path: PathsMap.USER_SETTINGS_EMAIL,
            component: UserSettingsEmail,
            icon: MailLockOutlined
        },
        password: {
            title: t('user.password'),
            description: t('user_settings.change_your_account_passw'),
            path: PathsMap.USER_SETTINGS_PASSWORD,
            component: UserSettingsPassword,
            icon: PasswordOutlined
        }
    }), []);

    const visibleSections = useMemo(() => (
        user?.data?.canChangePassword ? ['profile', 'email', 'password'] : ['profile', 'email']
    ), [user]);

    const [currentSection, setCurrentSection] = useState('');

    useEffect(() => {
        setCurrentSection(visibleSections
            .find(sectionId => location.pathname === sections[sectionId].path) ?? '');
    }, [visibleSections, sections, location]);

    if (!user.logged) {
        return <UserRequiredRedirect/>
    }
    return (
        <Page title={(currentSection && sections[currentSection].title + " - ") + t('user_settings.settings_title')}>
            <Breadcrumbs aria-label="breadcrumb" sx={{mb: 6}}>
                <Link underline="hover" color="inherit" to={PathsMap.HOME} component={NavLink}>
                    {AppConsts.NAME}
                </Link>
                <Link underline="hover" color={currentSection ? "inherit" : "text.primary"} to={PathsMap.USER_SETTINGS} component={NavLink}>
                    {t('user_settings.settings_title')}
                </Link>
                {currentSection &&
                    <Typography color="text.primary">
                        {sections[currentSection].title}
                    </Typography>}
            </Breadcrumbs>
            <Switch>
                {visibleSections.map(sectionId => {
                    const SectionComponent = sections[sectionId].component;
                    return (
                        <Route key={sectionId} exact path={sections[sectionId].path}>
                            <UserSettingsCols
                                visibleSections={visibleSections}
                                currentSection={currentSection}
                                sections={sections}
                                sectionComponent={SectionComponent} />
                        </Route>
                    );
                })}
                <Route path="*">
                    <UserSettingsHome visibleSections={visibleSections} sections={sections} />
                </Route>
            </Switch>
        </Page>
    );
}

export function UserSettingsCols({visibleSections, sections, currentSection, sectionComponent}) {
    const SectionComponent = sectionComponent;

    return (
        <Grid container sx={{width: 1}} rowSpacing={{xs: 4, md: 0}} columnSpacing={{md: 4}}>
            <Grid item xs={12} md="auto">
                <Box sx={{minWidth: {md: 240}}}>
                    <List>
                        {visibleSections.map(sectionId => {
                            const section = sections[sectionId];
                            const SectionIcon = section.icon;
                            const selected = sectionId === currentSection;

                            return (
                                <ListItemButton
                                          key={sectionId}
                                          to={section.path}
                                          selected={selected}
                                          component={NavLink}>
                                    <ListItemIcon>
                                        <SectionIcon color={selected ? "primary" : "default"} />
                                    </ListItemIcon>
                                    <ListItemText primary={section.title} />
                                </ListItemButton>
                            );
                        })}
                    </List>
                </Box>
            </Grid>
            <Grid item xs sx={{borderLeft: {md: '1px solid rgba(0, 0, 0, 0.1)'}}}>
                <Divider orientation="horizontal" sx={{mb: 4, display: {md: 'none'}}} />
                <SectionComponent />
            </Grid>
        </Grid>
    );
}

function UserSettingsHome({visibleSections, sections}) {
    const user = useUser();
    const {categories} = useContext(AppGlobalContext);

    return (
        <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={12}>
                <Card variant="outlined" sx={{pb: 0}}>
                    <CardContent sx={{marginBottom: '8px'}}>
                        <Box sx={{mb: 2}}>
                        </Box>
                        <Typography variant="h1" component="div" sx={{mb: 2}}>
                            {user.data.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {user.data.roles.length > 0 && `\n${t('user.roles')}: `
                                + user.data.roles.map(r => t('user.role_names.' + r)).join(", ")}
                            {user.data.categoryIds.length > 0 && `\n${t('user.categories')}: `
                                + user.data.categoryIds.map(id => categories.find(c => c.id === id)?.name || '?').join(", ")}
                        </Typography>
                    </CardContent>
                    <CardMediaBordered theme="red" />
                </Card>
            </Grid>
            {visibleSections.map(sectionId => {
                const section = sections[sectionId];
                const SectionIcon = section.icon;

                return (
                    <Grid item xs={12} lg={4} key={sectionId}>
                        <Card variant="outlined" sx={{minHeight: '100%'}}>
                            <CardActionArea to={section.path} component={NavLink}>
                                <CardContent>
                                    <Box sx={{mb: 2}}>
                                        <SectionIcon sx={{fontSize: 32}} />
                                    </Box>
                                    <Typography variant="h3" component="div" sx={{mb: 2}}>
                                        {section.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {section.description}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button to={section.path} component={NavLink} endIcon={<KeyboardArrowRightOutlinedIcon />}>
                                    {section.title}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
}