
export const FIELDS = {
    patient: {
        name: {
            isValid: (data) => data.patient.name?.length >= 1 && data.patient.name?.length <= 100
        },
        genre: {
            isValid: (data) => data.patient.genre !== null
        },
        age: {
            isValid: (data) => data.patient.age >= 1 && data.patient.age <= 130
        },
        fiscalCode: {
            isValid: (data) => data.patient.fiscalCode?.length === 16
        }
    },
    contact: {
        name: {
            isValid: (data) => data.contact.name?.length >= 1 && data.contact.name?.length <= 100
        },
        type: {
            isValid: (data) => data.contact.type !== null
        },
        phoneNumber: {
            isValid: (data) => data.contact.phoneNumber?.length >= 1 && data.contact.phoneNumber?.length <= 20
        }
    },
    anamnesis: {
        firstSymptomsAtKnown: {
            isValid: (data) => data.anamnesis.firstSymptomsAtKnown !== null
        },
        firstSymptomsAtType: {
            isValid: (data) => data.anamnesis.firstSymptomsAtKnown === true || data.anamnesis.firstSymptomsAtType !== null
        },
        firstSymptomsAt: {
            isValid: (data) => data.anamnesis.firstSymptomsAt !== null
        },
        patientFoundAt: {
            isValid: (data) => data.anamnesis.patientFoundAt !== null
        },

        isPsychiatric: {
            isValid: (data) => data.anamnesis.isPsychiatric !== null
        },
        isEpileptic: {
            isValid: (data) => data.anamnesis.isEpileptic !== null
        },
        isHeartPatient: {
            isValid: (data) => data.anamnesis.isHeartPatient !== null
        }
    },
    parameters: {
        minBloodPressure: {
            isValid: (data) => data.parameters.minBloodPressure >= 1 && data.parameters.minBloodPressure <= 500
        },
        maxBloodPressure: {
            isValid: (data) => data.parameters.maxBloodPressure >= 1 && data.parameters.maxBloodPressure <= 500
        },
        oxygenSaturation: {
            isValid: (data) => data.parameters.oxygenSaturation >= 1 && data.parameters.oxygenSaturation <= 100
        },
        glycemia: {
            isValid: (data) => data.parameters.glycemia >= 1 && data.parameters.glycemia <= 255
        }
    },
};

export const STEP_STATES = {
    COMPLETED: 'COMPLETED',
    INCOMPLETE: 'INCOMPLETE'
};

export const STEPS = {
    PATIENT: {
        fields: [FIELDS.patient.name, FIELDS.patient.genre, FIELDS.patient.age, FIELDS.patient.fiscalCode],
    },
    CONTACT: {
        fields: [FIELDS.contact.name, FIELDS.contact.type, FIELDS.contact.phoneNumber],
    },
    SYMPTOMS: {
        fields: [FIELDS.anamnesis.firstSymptomsAtKnown, FIELDS.anamnesis.firstSymptomsAtType, FIELDS.anamnesis.firstSymptomsAt, FIELDS.anamnesis.patientFoundAt],
    },
    PATHOLOGIES_DRUGS: {
        fields: [FIELDS.anamnesis.isPsychiatric, FIELDS.anamnesis.isEpileptic, FIELDS.anamnesis.isHeartPatient]
    },
    BLOOD_PRESSURE: {
        fields: [FIELDS.parameters.minBloodPressure, FIELDS.parameters.maxBloodPressure]
    },
    OXYGEN_SATURATION: {
        fields: [FIELDS.parameters.oxygenSaturation]
    },
    GLYCEMIA: {
        fields: [FIELDS.parameters.glycemia]
    },
    SCALES: {
    },
};