import {useUser} from "../../user-auth/UserProvider";
import React, {useCallback} from "react";
import AppConsts from "../../app/AppConsts";
import {ListItemIcon, Menu, MenuItem} from "@mui/material";
import {AccountTreeOutlined, GridOnOutlined, ListAltOutlined} from "@mui/icons-material";

export function FormResponsesExportMenu({form, responseId, openerAnchor, closeMenu}) {
    const menuOpen = Boolean(openerAnchor);
    const user = useUser();

    const downloadFormat = useCallback((format) => {
        window.open(AppConsts.API_PATH + '/forms/' + form.id + '/responses/export' +
            (typeof responseId !== 'undefined' ? '/' + responseId : '') +
            '?format=' + format +
            '&accessToken=' + user.getAccessToken());
        closeMenu();
    }, [form.id, responseId, user, closeMenu]);

    return (
        <Menu
            anchorEl={openerAnchor}
            open={menuOpen}
            onClose={closeMenu}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            disableScrollLock={true}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            PaperProps={{
                style: {
                    minWidth: '150px'
                }
            }}>
            <MenuItem onClick={() => downloadFormat('json')}>
                <ListItemIcon>
                    <AccountTreeOutlined fontSize="small"/>
                </ListItemIcon>
                JSON
            </MenuItem>
            <MenuItem onClick={() => downloadFormat('csv')}>
                <ListItemIcon>
                    <ListAltOutlined fontSize="small"/>
                </ListItemIcon>
                CSV
            </MenuItem>
            <MenuItem onClick={() => downloadFormat('xlsx')}>
                <ListItemIcon>
                    <GridOnOutlined fontSize="small"/>
                </ListItemIcon>
                XLSX
            </MenuItem>
        </Menu>
    );
}