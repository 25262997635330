import React, {useEffect, useRef, useState} from 'react';
import {EmbeddedMarkdown} from "./EmbeddedMarkdown";
import {DialogWrapper} from "./DialogWrapper";
import {DialogContent, DialogTitle, Grid, IconButton, Paper, Stack} from "@mui/material";
import EasyMDE from "easymde";
import "easymde/dist/easymde.min.css";
import {debounce} from "../utils/debounce";
import {ArrowBackOutlined} from "@mui/icons-material";
import {httpClient} from "../utils/httpClient";
import AppConsts from "../app/AppConsts";
import {logError} from "../utils/logging";
import {t} from "i18next";

export function MdEditorDialog({title, content, onChange, onChangeDebounced, onClose}) {
    const initialContent = useRef(content() ?? '');
    const [value, setValue] = useState(initialContent.current);

    const textareaRef = useRef(null);
    const easyMDE = useRef(null);
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        if (textareaRef.current && !easyMDE.current) {
            easyMDE.current = new EasyMDE({
                element: textareaRef.current,
                toolbar: TOOLBAR_BUTTONS,
                initialValue: initialContent.current,
                spellChecker: false,
                autoDownloadFontAwesome: true,
                uploadImage: true,
                imagePathAbsolute: true,
                imageUploadFunction: (file, onSuccess , onError) => {
                    const uploadData = new FormData();
                    uploadData.append('file', file, file.name);
                    httpClient.post(`${AppConsts.API_PATH}/images`, uploadData).then(response => {
                        if (response.data.filename) {
                            onSuccess(`${AppConsts.API_PATH}/images/${response.data.filename}`);
                        } else {
                            onError(t('common.invalid_file'));
                        }
                    }).catch((e) => {
                        logError(e);
                        onError(t('common.invalid_file'));
                    });
                }
            });
            easyMDE.current.codemirror.on('change', () => {
                if (!isMounted.current) {
                    return;
                }
                let value = easyMDE.current.value();
                onChange(value);
                debounce({target: textareaRef.current}, () => {
                    if (isMounted.current) {
                        setValue(value);
                    }
                    onChangeDebounced(value);
                });
            });
        }
    }, [onChange, onChangeDebounced, textareaRef]);

    // noinspection CheckTagEmptyBody
    return (<>
        <DialogTitle>
            <Stack direction="row" alignItems="center">
                <IconButton onClick={onClose} sx={{mr: 1}}>
                    <ArrowBackOutlined/>
                </IconButton>
                {title}
            </Stack>
        </DialogTitle>
        <DialogContent>
            <Grid container sx={{width: '100%'}} spacing={4}>
                <Grid item xs={12} xl={7}>
                    <textarea ref={textareaRef}></textarea>
                </Grid>
                <Grid item xs={12} xl={5} sx={{minWidth: 0}}>
                    <Paper variant="outlined" sx={{p: 3}}>
                        <EmbeddedMarkdown content={value} />
                    </Paper>
                </Grid>
            </Grid>
        </DialogContent>
    </>);
}

export function useMdEditorDialog(props) {
    return DialogWrapper({
        component: MdEditorDialog,
        componentProps: props,
        maxWidth: 'xl',
        fullWidth: true
    });
}

const TOOLBAR_BUTTONS = [
    'bold',
    'italic',
    'strikethrough',
    'heading-smaller',
    'heading-bigger',
    '|',
    'code',
    'quote',
    {
        name: "katex-replace",
        action: (editor) => {
            let cm = editor.codemirror;
            let selectedText = cm.getSelection();
            let text = selectedText || 'KaTeX-expression-here';
            let output = '$$\n' + text + '\n$$';
            cm.replaceSelection(output);
        },
        className: "fa fa-plus",
        title: "KaTeX"
    },
    'unordered-list',
    'ordered-list',
    'clean-block',
    '|',
    'link',
    'image',
    {
        name: 'upload-image',
        action: (editor) => EasyMDE.drawUploadedImage(editor),
        className: "fa fa-upload",
        title: 'Upload an image',
    },
    'table',
    'horizontal-rule',
    '|',
    'fullscreen',
    'guide',
    {
        name: "katex-guide",
        action: () =>  window.open('https://katex.org/docs/supported'),
        className: "fa fa-plus-circle",
        title: "KaTeX guide"
    },
    '|',
    'undo',
    'redo'
];