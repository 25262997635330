import {t} from "i18next";
import {httpClient} from "./httpClient";
import AppConsts from "../app/AppConsts";
import {logError} from "./logging";
import {useEffect, useState} from "react";

export const USER_CATEGORY_PATIENT_ID = "663b4469eca85741faa4bc88";

export function translateUserCategoryName(name) {
    return name.substring(0, 2) === "{{"
        ? t("user.category_names." + name.substring(2, name.length - 2))
        : name;
}

export function useUserCategories() {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        httpClient.get(`${AppConsts.API_PATH}/users/categories`).then(response => {
            setCategories(response.data.categories.map(c => ({
                id: c.id,
                name: translateUserCategoryName(c.name)
            })));
        }).catch((e) => {
            logError(e);
        });
    }, []);

    return {categories: categories};
}