import {Alert, Box, IconButton, Input, InputAdornment, MenuItem, Select} from "@mui/material";
import {LEGACY_QUESTION, QUESTION} from "../../utils/formUtils";
import {
    AddOutlined,
    CheckBoxOutlineBlank,
    DeleteOutlined,
    RadioButtonUncheckedOutlined
} from "@mui/icons-material";
import {ICON_STYLES} from "../../app/Theme";
import {debounce} from "../../utils/debounce";
import Button from "@mui/material/Button";
import React, {useMemo} from "react";
import {t} from "i18next";

export function FormEditorType({question, legacy, errorsManager, managers, errorsPrefix, errors}) {
    const questionsManager = managers.questions;
    const choicesManager = managers.choices;
    const typesDetails = useMemo(() => legacy
        ? QUESTION.TYPES_DETAILS.filter(td => LEGACY_QUESTION.TYPES.includes(td.id))
        : QUESTION.TYPES_DETAILS, [legacy]);

    return (<>
        <Select
            inputProps={{MenuProps: {disableScrollLock: true}}}
            onChange={(e) => questionsManager.editType(question.ref, e.target.value)}
            value={question.type}
            size="small">
            {typesDetails.map(td => {
                const TypeIcon = td.icon;
                return (
                    <MenuItem value={td.id} key={td.id}>
                        <TypeIcon sx={ICON_STYLES.icon16Left}/>
                        {t(`question.type_names.${td.id}`)}
                    </MenuItem>
                );
            })}
        </Select>
        {question.choices &&
            <Box sx={{mt: 1}}>
                {question.choices.map(choice => (
                    <FormEditorChoice
                        key={choice.ref}
                        question={question}
                        choice={choice}
                        choicesManager={choicesManager}
                        choiceErrorsPrefix={`${errorsPrefix}.choices[${choice.ref}]`}
                        errors={errors}
                        errorsManager={errorsManager} />

                ))}
                <Button variant="outlined"
                        startIcon={<AddOutlined/>}
                        sx={{mt: 3}}
                        size="small"
                        onClick={() => choicesManager.add(question.ref)}>
                    {t('form_editor.add_choice')}
                </Button>
            </Box>}
    </>);
}

function FormEditorChoice({question, choice, choicesManager, choiceErrorsPrefix, errors, errorsManager}) {
    return (<>
        <Input
            sx={{mt: 2}}
            startAdornment={
                <InputAdornment position="start">
                    {question.type === QUESTION.TYPES.MULTIPLE_CHOICE
                        ? <CheckBoxOutlineBlank sx={ICON_STYLES.icon21}/>
                        : <RadioButtonUncheckedOutlined sx={ICON_STYLES.icon21}/>}
                </InputAdornment>
            } endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="Delete choice"
                        onClick={() => {
                            choicesManager.delete(question.ref, choice.ref);
                        }}
                        onMouseDown={(e) => {
                            e.preventDefault()
                        }}
                    >
                        <DeleteOutlined sx={ICON_STYLES.icon21}/>
                    </IconButton>
                </InputAdornment>
            }
            onChange={(e) => {
                errorsManager.resetErrors(choiceErrorsPrefix);
                debounce(e, () => choicesManager.editTitle(question.ref, choice.ref, e.target.value));
            }}
            error={!!errors?.[`${choiceErrorsPrefix}.title`]}
            placeholder={t('form_editor.choice_title')}
            defaultValue={choice.title}
            fullWidth/>
        {(errors?.[choiceErrorsPrefix] || []).map((error, errorKey) =>
            <Alert severity="error" key={errorKey} sx={{mb: 2}}>{error}</Alert>)}
    </>);
}