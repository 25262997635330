import {createContext} from "react";
import {useUserCategories} from "../utils/userCategories";
import {useInitialLocations} from "../components/LocationsAutocomplete";

export const AppGlobalContext = createContext(null);

export const AppGlobal = (props) => {
    const {categories} = useUserCategories();
    const {locations} = useInitialLocations();

    return (
        <AppGlobalContext.Provider value={{categories, locations}}>
            {props.children}
        </AppGlobalContext.Provider>
    );
};