
export function debounce(event, fn) {
    if (!event.target) {
        fn();
        return;
    }
    const target = event.target;

    let delay = 100;
    if (target._debounceTimeout) {
        clearTimeout(target._debounceTimeout);
        target._debounceTimeout = null;
        delay = 500;
    }
    target._debounceTimeout = setTimeout(() => {
        target._debounceTimeout = null;
        fn();
    }, delay);
}