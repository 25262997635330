import Box from "@mui/material/Box";
import {Alert, Grow, Paper, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {LoadingButton} from "@mui/lab";
import {useState} from "react";
import {getDefaultErrorsMapper, useErrors} from "../hooks/useErrors";
import AppConsts from "../app/AppConsts";
import {logError} from "../utils/logging";
import Grid from "@mui/material/Grid";
import {TransitionGroup} from "react-transition-group";
import {SaveOutlined} from "@mui/icons-material";
import {PathsMap} from "../app/PageRouting";
import {useHistory} from "react-router";
import {httpClient} from "../utils/httpClient";
import {t} from "i18next";

export function UserSettingsPassword() {
    const [formState, setFormState] = useState('none');
    const {errors, setErrors, resetErrors} = useErrors({
        mapper: getDefaultErrorsMapper(['password'])
    });
    const history = useHistory();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const password = data.get('password');
        const passwordConfirmation = data.get('password_confirmation');

        if (passwordConfirmation !== password) {
            setErrors([
                {
                    details: {field: 'passwordConfirmation'},
                    message: "Password confirmation must be equal to password"
                }
            ]);
            return;
        }

        setFormState('loading');

        httpClient.post(`${AppConsts.API_PATH}/users/password/edit`, {
            password: password
        }).then(response => {
            if (response.data.errors) {
                setErrors(response.data.errors);
                setFormState('unsaved');
            } else {
                setErrors([]);
                setFormState('saved');
                setTimeout(() => {
                    history.push(PathsMap.SIGN_IN);
                }, 2000);
            }
        }).catch((e) => {
            logError(e);
            setFormState('unsaved');
        });
    };

    if (formState === 'saved') {
        return (
            <Alert severity="success" sx={{mt: 2}}>
                {t('user_settings.your_password_has_been_updated')}
            </Alert>
        );
    }

    return (
        <Paper variant="outlined" sx={{p: 3}}>
            <Typography variant="h5" sx={{mb: 4}}>
                {t('user_settings.edit_account_password')}
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{width: 1, maxWidth: 720}}>
                <Grid container sx={{width: 1, mb: 4}} rowSpacing={{xs: 2, md: 0}} columnSpacing={{md: 4}} alignItems="center">
                    <Grid item xs={12} md={5}>
                        <Typography variant="h6" color={!!errors?.password ? 'error' : ''} sx={{textAlign: {md: 'right'}}}>
                            {t('user.password')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            fullWidth
                            name="password"
                            type="password"
                            autoComplete="new-password"
                            error={!!errors?.password}
                            helperText={errors?.password}
                            onChange={() => resetErrors('password')}
                        />
                    </Grid>
                </Grid>
                <Grid container sx={{width: 1, mb: 4}} rowSpacing={{xs: 2, md: 0}} columnSpacing={{md: 4}} alignItems="center">
                    <Grid item xs={12} md={5}>
                        <Typography variant="h6" color={!!errors?.passwordConfirmation ? 'error' : ''} sx={{textAlign: {md: 'right'}}}>
                            {t('user.password_confirmation')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            fullWidth
                            name="password_confirmation"
                            type="password"
                            autoComplete="new-password"
                            error={!!errors?.passwordConfirmation}
                            helperText={errors?.passwordConfirmation}
                            onChange={() => resetErrors('passwordConfirmation')}
                        />
                    </Grid>
                </Grid>
                <TransitionGroup>
                    {errors?.main && errors.main.map((error, errorKey) =>
                        <Grow in={true} key={errorKey}>
                            <Alert severity="error" sx={{mt: 2}}>
                                {error}
                            </Alert>
                        </Grow>)}
                </TransitionGroup>
                <LoadingButton
                    size="large"
                    startIcon={<SaveOutlined />}
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3 }}
                    loading={formState === 'loading'}
                >
                    {t('common.save')}
                </LoadingButton>
            </Box>
        </Paper>
    );
}