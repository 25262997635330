// noinspection JSUnresolvedVariable

import {NavLink, useParams} from "react-router-dom";
import {
    Alert,
    Box,
    Breadcrumbs,
    CircularProgress,
    IconButton,
    Link,
    Stack,
    Tabs,
    Tooltip,
    Typography
} from "@mui/material";
import {Page} from "../app/Page";
import React, {useCallback, useEffect, useState} from "react";
import AppConsts from "../app/AppConsts";
import {PathGenerator, PathsMap} from "../app/PageRouting";
import Tab from "@mui/material/Tab";
import {FormResponsesSummary} from "./summary/FormResponsesSummary";
import {FormResponsesIndividual} from "./individual/FormResponsesIndividual";
import {FormEditor} from "./editor/FormEditor";
import {logError} from "../utils/logging";
import {Route, Switch, useHistory} from "react-router";
import {Link as RouterLink} from "react-router-dom";
import {
    BarChartOutlined,
    EditOutlined,
    QuestionAnswerOutlined,
    ShareOutlined, VisibilityOutlined
} from "@mui/icons-material";
import {ICON_STYLES} from "../app/Theme";
import {useFormShareDialog} from "../form-dialogs/FormShareDialog";
import {UserRequiredRedirect} from "../user-auth/UserRequiredRedirect";
import {useUser} from "../user-auth/UserProvider";
import {httpClient} from "../utils/httpClient";
import {t} from "i18next";

export function FormDashboardPageMiddleware() {
    const user = useUser();
    if (!user.logged) {
        return <UserRequiredRedirect />;
    }
    return <FormDashboardPage />;
}

function FormDashboardPage() {
    const {formId} = useParams();
    const history = useHistory();
    const [form, setForm] = useState(null);
    const [viewState, setViewState] = useState({code: "loading"});
    const [refreshCounter, setRefreshCounter] = useState(0);

    useEffect(() => {
        httpClient.get(`${AppConsts.API_PATH}/forms/${formId}/dashboard`).then(response => {
            if (response.data.errors) {
                setViewState({
                    code: "loadingError",
                    text: response.data.errors[0].message
                });
            } else {
                let formData = response.data.form;
                setForm({
                    id: formData.id,
                    title: formData.title,
                    unreadResponsesCount: formData.unreadResponsesCount,
                    open: formData.open,
                    canEdit: formData.canEdit,
                    canViewResponses: formData.canViewResponses,
                    canEditResponses: formData.canEditResponses,
                    canDeleteResponses: formData.canDeleteResponses
                });
                setViewState({code: "loaded"});
            }
        }).catch((e) => {
            logError(e);
            setViewState({
                code: "loadingError",
                text: t('common.sorry_something_went_wrong')
            });
        });
    }, [formId, refreshCounter]);

    const onRefreshForm = useCallback(() => {
        setRefreshCounter(c => ++c);
    }, []);

    const onEditForm = useCallback((editedProperties) => {
        setForm(form => ({
            ...form,
            ...editedProperties
        }));
    }, []);

    if (viewState.code === "loading") {
        return (
            <Page>
                <DefaultBreadcrumb />
                <CircularProgress/>
            </Page>
        );
    }

    if (viewState.code === "loadingError") {
        return (
            <Page>
                <DefaultBreadcrumb />
                <Alert severity="error">{viewState.text}</Alert>
            </Page>
        );
    }

    const URL_SUMMARY = PathGenerator.formSummary(form.id);
    const URL_INVIDIDUAL = PathGenerator.formIndividual(form.id);
    const URL_EDITOR = PathGenerator.formEditor(form.id);

    const currentTab = window.location.pathname;
    const avaibleTabs = [];
    if (form.canEdit) {
        avaibleTabs.push(URL_EDITOR);
    }
    if (form.canViewResponses) {
        avaibleTabs.push(URL_SUMMARY, URL_INVIDIDUAL);
    }
    if (!avaibleTabs.includes(currentTab)) {
        if (avaibleTabs.length) {
            history.push(avaibleTabs[0]);
            return <></>
        } else {
            return (
                <Page>
                    <DefaultBreadcrumb />
                    <Alert severity="error">{t('common.permissions_missing')}</Alert>
                </Page>
            );
        }
    }

    return (
        <Page title={form.title}>
            <Breadcrumbs aria-label="breadcrumb" sx={{mb: 3}}>
                <Link component={NavLink} to={PathsMap.HOME} underline="hover" color="inherit">
                    {AppConsts.NAME}
                </Link>
                <Typography color="text.primary">{form.title}</Typography>
            </Breadcrumbs>
            <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 3}}>
                <Tabs value={currentTab} centered>
                    {avaibleTabs.includes(URL_EDITOR) &&
                        <Tab
                            label={
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <EditOutlined fontSize="small"/>
                                    <Typography>{t('form_editor.editor_title')}</Typography>
                                </Stack>}
                            value={URL_EDITOR}
                            component={RouterLink}
                            to={URL_EDITOR} />}
                    {avaibleTabs.includes(URL_SUMMARY) &&
                        <Tab
                            label={
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <BarChartOutlined fontSize="small"/>
                                    <Typography>{t('form_summary.summary_title')}</Typography>
                                </Stack>}
                            value={URL_SUMMARY}
                            component={RouterLink}
                            to={URL_SUMMARY} />}
                    {avaibleTabs.includes(URL_INVIDIDUAL) &&
                        <Tab
                            label={
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <QuestionAnswerOutlined fontSize="small"/>
                                    <Typography>{form.unreadResponsesCount > 0
                                        ? t('form_individual.individual_n_unread', {n: form.unreadResponsesCount})
                                        : t('form_individual.individual_title')}</Typography>
                                </Stack>}
                            value={URL_INVIDIDUAL}
                            component={RouterLink}
                            to={URL_INVIDIDUAL} />}
                </Tabs>
            </Box>
            <Switch>
                <Route path={URL_SUMMARY}>
                    <FormDashboardHeading form={form}/>
                    <FormResponsesSummary
                        form={form} />
                </Route>
                <Route path={URL_INVIDIDUAL}>
                    <FormDashboardHeading form={form}/>
                    <FormResponsesIndividual
                        form={form}
                        refreshForm={onRefreshForm}
                        editForm={onEditForm} />
                </Route>
                <Route path={URL_EDITOR}>
                    <FormEditor formId={form.id} />
                </Route>
            </Switch>
        </Page>
    );
}

function FormDashboardHeading({form}) {
    const [openShareDialog] = useFormShareDialog(form);

    return (
        <Stack direction="row" spacing={2} alignItems="center" sx={{mb: 3}}>
            <Typography gutterBottom variant="h1" component="h1">
                {form.title}
            </Typography>
            <Tooltip title={t('form.share_form')}>
                <IconButton onClick={openShareDialog} size="small">
                    <ShareOutlined sx={ICON_STYLES.icon21} />
                </IconButton>
            </Tooltip>
            <Tooltip title={t('form.view_form')}>
                <IconButton component={NavLink} to={PathGenerator.createFormResponse(form.id)} size="small">
                    <VisibilityOutlined sx={ICON_STYLES.icon21} />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

function DefaultBreadcrumb() {
    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{mb: 2}}>
            <Link component={NavLink} to={PathsMap.HOME} underline="hover" color="inherit">
                {AppConsts.NAME}
            </Link>
            <Typography color="text.primary">Dashboard</Typography>
        </Breadcrumbs>
    );
}