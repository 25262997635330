import React, {useCallback, useEffect, useRef, useState} from "react";
import {CircularProgress} from "@mui/material";
import Box from "@mui/material/Box";
import AppConsts from "../app/AppConsts";
import {httpClient} from "../utils/httpClient";

const STOR_ACCESS_TOKEN_KEY = 'auth_token';
const STOR_ACCESS_TOKEN_EXP_KEY = 'auth_token_exp';
const STOR_REFRESH_TOKEN_KEY = 'auth_refresh_token';
const STOR_REFRESH_INTERVAL_SECONDS = 60 * 2; // 2 minutes

export const UserContext = React.createContext(null);

export const useUser = () => {
    return React.useContext(UserContext);
};

export default function UserProvider({children}) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [logged, setLogged] = useState(false);
    const refreshTokenTimeout = useRef(null);

    const getAccessTokenFromStorage = useCallback(() => {
        return localStorage.getItem(STOR_ACCESS_TOKEN_KEY);
    }, []);

    const deleteStorage = useCallback(() => {
        localStorage.removeItem(STOR_ACCESS_TOKEN_KEY);
        localStorage.removeItem(STOR_ACCESS_TOKEN_EXP_KEY);
        localStorage.removeItem(STOR_REFRESH_TOKEN_KEY);
    }, []);

    const refreshToken = useCallback(() => {
        return new Promise((success, fail) => {
            const refreshTokenStorage = localStorage.getItem(STOR_REFRESH_TOKEN_KEY);
            if (typeof refreshTokenStorage !== 'string' || !refreshTokenStorage.length) {
                fail();
                return;
            }

            httpClient.withoutInterceptors.post(`${AppConsts.API_PATH}/users/auth/refresh`, {
                refreshToken: refreshTokenStorage
            }).then(response => {
                if (response.data.accessToken) {
                    success({
                        accessToken: response.data.accessToken,
                        refreshToken: response.data.refreshToken,
                        expiresAt: response.data.expiresAt
                    });
                } else {
                    fail();
                }
            }).catch(() => {
                fail();
            });
        });
    }, []);

    const getUserByStorageToken = useCallback(() => {
        return new Promise((success, fail) => {
            const accessTokenStorage = localStorage.getItem(STOR_ACCESS_TOKEN_KEY);
            if (typeof accessTokenStorage !== 'string' || !accessTokenStorage.length) {
                fail('NO_ACCESS_TOKEN_IN_STORAGE');
                return;
            }

            const accessTokenExpStorage = localStorage.getItem(STOR_ACCESS_TOKEN_EXP_KEY);
            if (typeof accessTokenExpStorage !== 'string') {
                fail('NO_ACCESS_TOKEN_EXP_IN_STORAGE');
                return;
            }

            if (new Date().getTime() / 1000 > parseInt(accessTokenExpStorage) - STOR_REFRESH_INTERVAL_SECONDS * 2) {
                fail('REFRESH_REQUIRED');
                return;
            }

            httpClient.get(`${AppConsts.API_PATH}/users/auth`).then(response => {
                if (response.data.user) {
                    success(response.data.user);
                } else {
                    fail('USER_GET_FAILED');
                }
            }).catch(() => {
                fail('USER_GET_FAILED');
            });
        });
    }, []);

    const unsetUser = useCallback(() => {
        clearTimeout(refreshTokenTimeout.current);
        setLogged(false);
        setData(null);
        deleteStorage();
    }, [deleteStorage]);

    const setUserData = useCallback((newUserData) => {
        setData((userData) => {
            if (userData !== null && userData.id !== newUserData.id) {
                return userData; // user change must not be allowed
            }
            return newUserData;
        });
        setLogged(true);
    }, []);

    const setUser = useCallback((newUser) => {
        return new Promise((success, fail) => {
            if ('tokens' in newUser) {
                localStorage.setItem(STOR_ACCESS_TOKEN_KEY, newUser.tokens.accessToken);
                localStorage.setItem(STOR_ACCESS_TOKEN_EXP_KEY, newUser.tokens.expiresAt);
                localStorage.setItem(STOR_REFRESH_TOKEN_KEY, newUser.tokens.refreshToken);
            }

            clearTimeout(refreshTokenTimeout.current);
            refreshTokenTimeout.current = setTimeout(() => {
                refreshToken().then(tokens => setUser({tokens: tokens})).catch();
            }, STOR_REFRESH_INTERVAL_SECONDS * 1000);

            if ('data' in newUser) {
                setUserData(newUser.data);
                success();
            } else {
                httpClient.get(`${AppConsts.API_PATH}/users/auth`).then(response => {
                    if (response.data.user) {
                        setUserData(response.data.user);
                        success();
                    }
                }).catch(() => {
                    fail();
                });
            }
        });
    }, [refreshToken, setUserData]);

    const updateUser = useCallback((newUserData) => {
        setData((userData) => {
            if (userData === null || ('id' in newUserData && userData.id !== newUserData.id)) {
                return; // user change not allowed (probably by another tab), stop
            }
            return Object.assign({}, userData, newUserData);
        });
    }, []);

    useEffect(() => {
        httpClient.interceptors.request.use(function (config) {
            const accessToken = localStorage.getItem(STOR_ACCESS_TOKEN_KEY);
            if (accessToken) {
                config.headers.Authorization = 'Bearer ' + accessToken;
            }
            return config;
        });

        httpClient.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response && error.response.status === 401) {
                deleteStorage();
            } else {
                return Promise.reject(error);
            }
        });

        getUserByStorageToken().then(userData => {
            setUser({data: userData})
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
        }).catch(error => {
            if (error === 'REFRESH_REQUIRED') {
                refreshToken()
                    .then(tokens => {
                        setUser({tokens: tokens})
                            .then(() => setLoading(false))
                            .catch(() => setLoading(false));
                    })
                    .catch(() => setLoading(false));
            } else {
                setLoading(false);
            }
        });
    }, [getUserByStorageToken, deleteStorage, refreshToken, setUser]);

    return (
        <UserContext.Provider value={{
            set: setUser,
            unset: unsetUser,
            update: updateUser,
            getAccessToken: getAccessTokenFromStorage,
            data,
            logged
        }}>
            {loading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh"
                >
                    <CircularProgress/>
                </Box>
            ) : children}
        </UserContext.Provider>
    );
}