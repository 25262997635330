import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import AppConsts from "../../app/AppConsts";
import {
    Alert,
    Box,
    CardActionArea,
    Chip,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    ListItemIcon,
    Menu,
    MenuItem,
    Pagination,
    Paper,
    Stack,
    Switch,
    Tooltip,
    Typography
} from "@mui/material";
import {scrollToElementByRef} from "../../utils/scroll";
import {
    AnnouncementOutlined,
    DeleteOutlined,
    MarkChatReadOutlined,
    MarkChatUnreadOutlined,
    MoreHorizOutlined,
    QuestionAnswerOutlined,
    SaveAltOutlined,
    WarningAmberOutlined
} from "@mui/icons-material";
import {ICON_STYLES} from "../../app/Theme";
import {grey, red} from "@mui/material/colors";
import {useDialog} from "../../providers/DialogProvider";
import {getFormResponseDialogOptions} from "./FormResponseDialog";
import Button from "@mui/material/Button";
import {logError} from "../../utils/logging";
import {useSnackbar} from "../../providers/SnackbarProvider";
import {useEditOpenState} from "../editor/editOpenState";
import {FormResponsesExportMenu} from "./FormResponsesExportMenu";
import {httpClient} from "../../utils/httpClient";
import {t} from "i18next";

export function FormResponsesIndividual(props) {
    const form = props.form;
    const refreshForm = props.refreshForm;
    const editForm = props.editForm;

    const {openDialog} = useDialog();
    const [viewState, setViewState] = useState({code: "loading"});
    const [pageNumber, setPageNumber] = useState(1);
    const [individual, setIndividual] = useState(null);
    const topScrollRef = useRef(null);
    const [refreshIndividualCounter, setRefreshIndividualCounter] = useState(0);
    const editOpenState = useEditOpenState(form.id, (open) => editForm({open: open}));

    const [exportMenuAnchor, setExportMenuAnchor] = useState(null);
    const closeExportMenu = useCallback(() => {
        setExportMenuAnchor(null);
    }, [setExportMenuAnchor]);

    const [moreMenuAnchor, setMoreMenuAnchor] = useState(null);
    const closeMoreMenu = useCallback(() => {
        setMoreMenuAnchor(null);
    }, [setMoreMenuAnchor]);

    const onResponseDeleted = useCallback(() => {
        setRefreshIndividualCounter(c => ++c);
        refreshForm();
    }, [refreshForm]);

    const onResponseReadChanged = useCallback((responseId, readState) => {
        setIndividual((individual) => ({
            ...individual,
            list: individual.list.map(i => {
                if (i.id === responseId || responseId === "all") {
                    i = {...i, read: readState};
                }
                return i;
            })
        }));
        refreshForm();
    }, [refreshForm]);

    const openFormResponseDialog = useCallback((response) => {
        openDialog(getFormResponseDialogOptions(form, response.id, onResponseDeleted, onResponseReadChanged));
    }, [form, onResponseDeleted, onResponseReadChanged, openDialog]);

    useEffect(() => {
        const cancelTokenSource = httpClient.cancelToken();
        let isMounted = true;

        httpClient.get(`${AppConsts.API_PATH}/forms/${form.id}/responses?pageNumber=${pageNumber}&pageSize=25`, {
            cancelToken: cancelTokenSource.token
        }).then(response => {
            if (!isMounted) {
                return;
            }
            if (response.data.errors) {
                setViewState({
                    code: "loadingError",
                    text: response.data.errors[0].message
                });
            } else {
                const data = response.data;

                setIndividual(() => {
                    const list = data.page.elements.map(r => {
                        return {
                            id: r.id,
                            createdDateTime: r.createdDateTime,
                            preview: r.preview,
                            read: r.read,
                            totalQuestions: r.totalQuestions
                        };
                    });

                    return {
                        pageNumber: data.page.pageNumber,
                        totalPages: data.pagination.totalPages,
                        totalResponses: data.pagination.totalElements,
                        list: list,
                    };
                });
                setViewState({code: "loaded"});
            }
        }).catch((e) => {
            logError(e);
            if (!isMounted) {
                return;
            }
            setViewState({
                code: "loadingError",
                text: t('common.sorry_something_went_wrong')
            });
        });

        return () => {
            isMounted = false;
            cancelTokenSource.cancel();
        }
    }, [form.id, pageNumber, refreshIndividualCounter]);

    if (viewState.code === "loading") {
        return <Box sx={{justifyContent: 'center', display: 'flex', py: 4}}><CircularProgress/></Box>;
    }

    if (viewState.code === "loadingError") {
        return <Alert severity="error">{viewState.text}</Alert>;
    }

    return (
        <Paper variant="outlined" sx={{py: 5, mb: 3}} ref={topScrollRef}>
            <Grid container alignItems="center" sx={{px: 4}} spacing={2}>
                <Grid item xs={12} md={7}>
                    <Stack direction={{xs: "column", md: "row"}} justifyContent="space-between" spacing={{xs: 3, md: 2}}>
                        <Typography variant="p" component="p">
                            {individual.totalResponses === 1
                                ? t('form_individual.1_response_receveid')
                                : t('form_individual.n_responses_received', {n: individual.totalResponses})}
                        </Typography>
                        <Tooltip title={form.open
                            ? t('form_individual.new_responses_are_currently_accepted')
                            : t('form_individual.new_responses_are_currently_rejected')}>
                            <Stack direction="row" alignItems="center">
                                <FormControlLabel
                                    control={
                                        <Switch checked={form.open}
                                                disabled={!form.canEdit}
                                                onChange={(e) => editOpenState(e.target.checked)}
                                                size="small"
                                        />}
                                    label={form.open ? t('form.accepting_responses') : t('form.rejecting_responses')} />
                                    <WarningAmberOutlined sx={{fontSize: 16, color: red[500], opacity: form.open ? 0 : 1, transition: 'opacity .3s'}}/>
                            </Stack>
                        </Tooltip>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Stack direction="row" justifyContent={{xs: "space-between", md: "right"}} spacing={2}>
                        <Button onClick={(e) => setExportMenuAnchor(e.currentTarget)}>
                            <SaveAltOutlined sx={{fontSize: 14, mr: 1}}/>
                            {t('form_individual.export_as')}
                        </Button>
                        <Button onClick={(e) => setMoreMenuAnchor(e.currentTarget)}>
                            <MoreHorizOutlined/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
            <FormResponsesExportMenu
                form={form}
                openerAnchor={exportMenuAnchor}
                closeMenu={closeExportMenu} />
            <FormResponsesMoreMenu
                form={form}
                totalResponses={individual.totalResponses}
                triggerResponseReadChanged={onResponseReadChanged}
                triggerDeleted={onResponseDeleted}
                openerAnchor={moreMenuAnchor}
                closeMenu={closeMoreMenu} />
            {individual.list.length > 0 &&
                <Divider sx={{mt: 2, mb: 1}} />}
            {individual.list.map(response => (
                <CardActionArea onClick={() => openFormResponseDialog(response)} key={response.id}>
                    <Box sx={{borderBottom: {xs: '1px solid ' + grey[300], md: 0}, py: 2, px: 4}}>
                        <Grid container
                                sx={{width: '100%'}}
                                spacing={2}>
                            <Grid item xs={12} md={8}>
                                <Stack direction={{xs: "column", md: "row"}} alignItems={{xs: "left", md: "center"}}>
                                    {!response.read &&
                                    <Box sx={{mb: {xs: 2, md: 0}, mr: {md: 2}}}>
                                        <Chip
                                            icon={<AnnouncementOutlined />}
                                            label={t('form_individual.unread')}
                                            color="warning"
                                            size="small" />
                                    </Box>}
                                    {response.preview.length < 1 ?
                                    <Typography
                                        color={response.read ? 'text.secondary' : 'text.primary'}>
                                        - nd -
                                    </Typography>
                                    :
                                    <>
                                        <Typography
                                            color={response.read ? 'text.secondary' : 'text.primary'}
                                            sx={{display: {sm: 'none'}}}>
                                            {response.preview}
                                        </Typography>
                                        <Typography
                                            color={response.read ? 'text.secondary' : 'text.primary'}
                                            sx={{display: {xs: 'none', sm: 'block'}}}
                                            noWrap>
                                            {response.preview}
                                        </Typography>
                                    </>}
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Box display="flex" justifyContent={{xs: 'left', md: 'center'}}>
                                    <Typography variant="body2" color="text.secondary">
                                        {response.createdDateTime}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Stack direction="row"
                                       justifyContent={{xs: "flex-start", md: "flex-end"}}
                                       alignItems="center"
                                       spacing={2}>
                                    <QuestionAnswerOutlined sx={ICON_STYLES.icon16} color={response.read ? "disabled" : "primary"} />
                                    <Typography variant="body2" color="text.secondary" noWrap>
                                        {response.totalQuestions === 1
                                            ? t('form_individual.1_question')
                                            : t('form_individual.n_questions', {n: response.totalQuestions})}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </CardActionArea>
            ))}
            {individual.totalPages > 1 && <>
                <Pagination
                    sx={{mt: 3, px: 4}}
                    onChange={(e, page) => {
                        setPageNumber(page);
                        scrollToElementByRef(topScrollRef);
                    }}
                    count={individual.totalPages} /></>}
        </Paper>
    );
}

function FormResponsesMoreMenu({form, totalResponses, openerAnchor, closeMenu, triggerDeleted, triggerResponseReadChanged}) {
    const menuOpen = Boolean(openerAnchor);
    const {createSnackbar} = useSnackbar();
    const currentTimestamp = useMemo(() => Math.ceil( Date.now() / 1000), []);
    
    const handleDeleteAll = useCallback(() => {
        closeMenu();

        if (!window.confirm(t('form_individual.delete_all_confirm', {n: totalResponses}))) {
            return;
        }

        httpClient.delete(`${AppConsts.API_PATH}/forms/${form.id}/responses?maxCreatedTime=${currentTimestamp}`).then(response => {
            if (response.data.errors) {
                createSnackbar({
                    message: response.data.errors[0].message,
                    autoHideDuration: 2000
                });
            } else {
                createSnackbar({
                    message: response.data.deletedCount === 1
                        ? t('form_individual.1_response_deleted')
                        : t('form_individual.n_responses_deleted', {n: response.data.deletedCount}),
                    autoHideDuration: 2000
                });
                triggerDeleted();
            }
        }).catch((e) => {
            logError(e);
            createSnackbar({
                message: t('common.sorry_something_went_wrong'),
                autoHideDuration: 2000
            });
        });
    }, [closeMenu, createSnackbar, form.id, currentTimestamp, totalResponses, triggerDeleted]);

    const markAllAsRead = useCallback((read) => {
        closeMenu();

        const msg = read
            ? t('form_individual.mark_all_as_read_confirm', {n: totalResponses})
            : t('form_individual.mark_all_as_unread_confirm', {n: totalResponses});

        if (!window.confirm(msg)) {
            return;
        }

        httpClient.put(`${AppConsts.API_PATH}/forms/${form.id}/responses?maxCreatedTime=${currentTimestamp}`, {read: read}).then(response => {
            if (response.data.errors) {
                createSnackbar({
                    message: response.data.errors[0].message,
                    autoHideDuration: 2000
                });
            } else {
                let editedCount = response.data.editedCount;
                createSnackbar({
                    message: editedCount === 1
                        ? (read
                            ? t('form_individual.1_response_marked_as_read')
                            : t('form_individual.1_response_marked_as_unread'))
                        : (read
                            ? t('form_individual.n_responses_marked_as_read', {n: editedCount})
                            : t('form_individual.n_responses_marked_as_unread', {n: editedCount})),
                    autoHideDuration: 2000
                });
                triggerResponseReadChanged('all', read);
            }
        }).catch((e) => {
            logError(e);
            createSnackbar({
                message: t('common.sorry_something_went_wrong'),
                autoHideDuration: 2000
            });
        });
    }, [closeMenu, createSnackbar, form.id, currentTimestamp, totalResponses, triggerResponseReadChanged]);

    return (<>
        <Menu
            anchorEl={openerAnchor}
            open={menuOpen}
            onClose={closeMenu}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            disableScrollLock={true}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
            <MenuItem onClick={() => markAllAsRead(true)} disabled={totalResponses < 1 || !form.canEditResponses}>
                <ListItemIcon>
                    <MarkChatReadOutlined fontSize="small"/>
                </ListItemIcon>
                {t('form_individual.mark_all_as_read')}
            </MenuItem>
            <MenuItem onClick={() => markAllAsRead(false)} disabled={totalResponses < 1 || !form.canEditResponses}>
                <ListItemIcon>
                    <MarkChatUnreadOutlined fontSize="small"/>
                </ListItemIcon>
                {t('form_individual.mark_all_as_unread')}
            </MenuItem>
            <MenuItem onClick={handleDeleteAll} disabled={totalResponses < 1 || !form.canDeleteResponses}>
                <ListItemIcon>
                    <DeleteOutlined fontSize="small"/>
                </ListItemIcon>
                {t('form_individual.delete_all')}
            </MenuItem>
        </Menu>
    </>);
}