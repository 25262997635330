import {TransitionGroup} from "react-transition-group";
import {Alert, Box, Divider, Fade, Grid, IconButton} from "@mui/material";
import {FormEditorConditionPatternInput, FormEditorConditionTypeSelect} from "./FormEditorConditions";
import {AddOutlined, DeleteOutlined} from "@mui/icons-material";
import {ICON_STYLES} from "../../app/Theme";
import Button from "@mui/material/Button";
import React from "react";
import {t} from "i18next";

export function FormEditorValidation({formManager, question, managers, legacy, errorsPrefix, errors}) {
    const constraintsManager = managers.constraints;

    return (<>
        <TransitionGroup>
            {question.constraints.map((ct) => {
                const ctErrorsPrefix = `${errorsPrefix}.constraints[${ct.ref}]`;
                return (
                    <Fade key={ct.ref} timeout={{appear: 0, enter: 300, exit: 0}}><Box sx={{mb: 2}}>
                        <Grid container sx={{width: '100%'}} spacing={2} alignItems="center">
                            <Grid item xs={12} md={2}>
                                <Divider orientation="horizontal">
                                    {t('form_editor.response')}
                                </Divider>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormEditorConditionTypeSelect
                                    question={question}
                                    context={'validation'}
                                    legacy={legacy}
                                    condition={ct.condition}
                                    onChange={(newValue) => {
                                        constraintsManager.editType(question.ref, ct.ref, newValue);
                                        formManager.resetErrors(ctErrorsPrefix);
                                    }}/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormEditorConditionPatternInput
                                    question={question}
                                    condition={ct.condition}
                                    onChange={(newValue) => {
                                        constraintsManager.editPattern(question.ref, ct.ref, newValue);
                                        formManager.resetErrors(ctErrorsPrefix);
                                    }}/>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Box display="flex" justifyContent="right">
                                    <IconButton
                                        aria-label="Delete condition"
                                        onClick={() => {
                                            constraintsManager.delete(question.ref, ct.ref);
                                            formManager.resetErrors(ctErrorsPrefix);
                                        }}
                                        onMouseDown={(e) => {
                                            e.preventDefault()
                                        }}
                                    >
                                        <DeleteOutlined sx={ICON_STYLES.icon21}/>
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        {(errors?.[ctErrorsPrefix] || []).map((error, errorKey) =>
                            <Alert severity="error" key={errorKey} sx={{mb: 1}}>{error}</Alert>)}
                    </Box></Fade>)
            })}
        </TransitionGroup>
        <Button variant="outlined"
                startIcon={<AddOutlined/>}
                size="small"
                onClick={() => constraintsManager.add(question.ref)}>
            {t('question.add_constraint')}
        </Button>
    </>);
}