import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import {NavLink} from "react-router-dom";
import AppConsts from "../app/AppConsts";
import {useState} from "react";
import {Alert, Grow} from "@mui/material";
import {TransitionGroup} from "react-transition-group";
import {LoadingButton} from "@mui/lab";
import {logError} from "../utils/logging";
import {Page} from "../app/Page";
import {PathsMap} from "../app/PageRouting";
import {getDefaultErrorsMapper, useErrors} from "../hooks/useErrors";
import {httpClient} from "../utils/httpClient";
import {t} from "i18next";

export function ResetPassword() {
    const [loading, setLoading] = useState(false);
    const {errors, setErrors, resetErrors} = useErrors({
        mapper: getDefaultErrorsMapper(['email', 'password', 'firstName', 'lastName', 'terms'])
    });
    const [emailSent, setEmailSent] = useState(false);

    const handleSubmit = (event) => {
        setLoading(true);

        event.preventDefault();
        const data = new FormData(event.currentTarget);

        httpClient.post(`${AppConsts.API_PATH}/users/auth/password-reset`, {
            email: data.get('email')
        }).then(response => {
            setLoading(false);
            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                setErrors([]);
                setEmailSent(response.data.emailSent);
            }
        }).catch((e) => {
            setLoading(false);
            logError(e);
        });
    };

    return (
        <Page title={t('reset_password.forgot_password_title')} maxWidth="sm" centered>
            <Paper sx={{p: 4}} variant="outlined">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h3">
                        {t('reset_password.forgot_password_title')}
                    </Typography>
                    <Typography component="p" variant="p" sx={{mt: 3}}>
                        {t('reset_password.type_the_email_address_linked')}
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 2, width: 1}}>
                        {emailSent ?
                            <Alert severity="success">
                                {t('reset_password.done_please_check_your_email')}
                            </Alert>
                            : <>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={t('user.email_address')}
                                name="email"
                                autoComplete="email"
                                autoFocus
                                error={!!errors?.email}
                                helperText={errors?.email}
                                onChange={() => resetErrors('email')}
                            />
                            <TransitionGroup>
                                {errors?.main && errors.main.map((error, errorKey) =>
                                    <Grow in={true} key={errorKey}>
                                        <Alert severity="error" sx={{mt: 2}}>
                                            {error}
                                        </Alert>
                                    </Grow>)}
                            </TransitionGroup>
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                loading={loading}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {t('reset_password.send_instructions')}
                            </LoadingButton>
                            <Grid container>
                                <Grid item xs>
                                    <Link to={PathsMap.SIGN_IN} component={NavLink} variant="body2">
                                        {t('sign_in.sign_in_button')}
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link to={PathsMap.SIGN_UP} component={NavLink} variant="body2">
                                        {t('sign_in.don_t_have_an_account_question')}
                                    </Link>
                                </Grid>
                            </Grid>
                        </>}
                    </Box>
                </Box>
            </Paper>
        </Page>
    );
}
