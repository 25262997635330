import {ContactPageOutlined, EventOutlined} from "@mui/icons-material";
import {FORM, getRequiredConstraintTypes} from "../utils/formUtils";

const {QUESTION} = require("../utils/formUtils");

export const FORM_TEMPLATES = [
    {
        icon: EventOutlined,
        data: {
            title: "Event registration",
            description: "**Event Timing:** January 4th-6th, 2016  \n" +
                "**Event Address:** 123 Your Street Your City, ST 12345  \n" +
                "**Contact us at** (123) 456-7890 or no_reply@example.com",
            theme: FORM.THEMES_ID.PURPLE,
            questions: [
                buildRequiredQuestion({
                    title: "Name",
                    type: QUESTION.TYPES.TEXT,
                    defaultExitRef: QUESTION.REF.NEXT
                }),
                buildRequiredQuestion({
                    title: "Email",
                    type: QUESTION.TYPES.EMAIL,
                    defaultExitRef: QUESTION.REF.NEXT
                }),
                buildRequiredQuestion({
                    title: "Organization",
                    type: QUESTION.TYPES.TEXT,
                    defaultExitRef: QUESTION.REF.NEXT
                }),
                buildRequiredQuestion({
                    title: "What days will you attend?",
                    type: QUESTION.TYPES.MULTIPLE_CHOICE,
                    defaultExitRef: QUESTION.REF.NEXT,
                    choices: [
                        {
                            title: "Day 1"
                        }, {
                            title: "Day 2"
                        }, {
                            title: "Day 3"
                        }, {
                            title: "Day 4"
                        },
                    ]
                }),
                buildRequiredQuestion({
                    title: "Dietary restrictions",
                    type: QUESTION.TYPES.MULTIPLE_CHOICE,
                    defaultExitRef: "payqst",
                    choices: [
                        {
                            title: "None"
                        }, {
                            title: "Vegetarian"
                        }, {
                            title: "Vegan"
                        }, {
                            title: "Kosher"
                        }, {
                            title: "Gluten free"
                        }, {
                            title: "Other"
                        },
                    ],
                    exitConditions: [
                        {
                            matchConditions: [
                                {
                                    type: QUESTION.MATCH_CONDITION.TYPES.CHOICE_IS_SELECTED,
                                    pattern: "Other"
                                }
                            ],
                            exitRef: "dietqs"
                        }
                    ]
                }),
                buildRequiredQuestion({
                    ref: "dietqs",
                    title: "Custom dietary restriction",
                    type: QUESTION.TYPES.TEXT,
                    defaultExitRef: QUESTION.REF.NEXT
                }),
                buildRequiredQuestion({
                    ref: "payqst",
                    title: "I understand that I will have to pay $$ upon arrival",
                    type: QUESTION.TYPES.MULTIPLE_CHOICE,
                    defaultExitRef: QUESTION.REF.NEXT,
                    choices: [
                        {
                            title: "Yes"
                        }
                    ]
                })
            ]
        }
    },
    {
        icon: ContactPageOutlined,
        data: {
            title: "Contact information",
            description: "",
            theme: FORM.THEMES_ID.GREEN,
            questions: [
                buildRequiredQuestion({
                    title: "Name",
                    type: QUESTION.TYPES.TEXT,
                    defaultExitRef: QUESTION.REF.NEXT
                }),
                buildRequiredQuestion({
                    title: "Email",
                    type: QUESTION.TYPES.EMAIL,
                    defaultExitRef: QUESTION.REF.NEXT
                }),
                buildRequiredQuestion({
                    title: "Address",
                    type: QUESTION.TYPES.TEXT,
                    defaultExitRef: QUESTION.REF.NEXT
                }),
                buildRequiredQuestion({
                    title: "Phone number",
                    type: QUESTION.TYPES.TEXT,
                    defaultExitRef: QUESTION.REF.NEXT
                }),
                buildRequiredQuestion({
                    title: "Comment",
                    type: QUESTION.TYPES.MULTILINE_TEXT,
                    defaultExitRef: QUESTION.REF.NEXT
                })
            ]
        }
    }
];

function buildRequiredQuestion(question) {
    if (!('constraints' in question)) {
        question.constraints = [];
    }
    getRequiredConstraintTypes(question.type).forEach(type => {
        question.constraints.push({
            type: type,
            pattern: ""
        });
    });
    return question;
}