import {generatePath, Route, Switch} from "react-router";
import {HomePageMiddleware} from "../home/HomePage";
import {FormCreateResponseMiddleware} from "../form-create-response/FormCreateResponse";
import {FormDashboardPageMiddleware} from "../form-dashboard/FormDashboardPage"
import React from "react";
import {SignUp} from "../user-auth/SignUp";
import {SignIn} from "../user-auth/SignIn";
import {SignOut} from "../user-auth/SignOut";
import {ResetPassword} from "../user-auth/ResetPassword";
import {ResetPasswordConfirm} from "../user-auth/ResetPasswordConfirm";
import {EmailConfirm} from "../user-auth/EmailConfirm";
import {UserSettings} from "../user-settings/UserSettings";
import {OAuthCallback} from "../user-auth/OAuthCallback";
import {AmbulanceModulePageMiddleware} from "../ambulance-module/AmbulanceModulePage";

const PUBLIC_URL = process.env.PUBLIC_URL;

const PathsMap = {
    HOME: PUBLIC_URL + "/",

    // Forms
    _FORM_DASHBOARD: {
        ROOT: PUBLIC_URL + "/forms/:formId/dashboard/",
        EDITOR: PUBLIC_URL + "/forms/:formId/dashboard/",
        SUMMARY: PUBLIC_URL + "/forms/:formId/dashboard/summary/",
        INDIVIDUAL: PUBLIC_URL + "/forms/:formId/dashboard/individual/",
    },
    _FORM_CREATE_RESPONSE: PUBLIC_URL + "/forms/:formId/",

    // User auth
    SIGN_UP: PUBLIC_URL + "/user/signup",
    SIGN_IN: PUBLIC_URL + "/user/signin",
    SIGN_OUT: PUBLIC_URL + "/user/signout",
    RESET_PWD: PUBLIC_URL + "/user/forgot-password",
    RESET_PSWD_CONFIRM: PUBLIC_URL + "/user/forgot-password/reset",
    EMAIL_CONFIRM: PUBLIC_URL + "/user/verify-email",
    OAUTH_CALLBACK: PUBLIC_URL + "/user/oauth-callback",

    // User settings
    USER_SETTINGS: PUBLIC_URL + "/user/settings",
    USER_SETTINGS_PROFILE: PUBLIC_URL + "/user/settings/profile",
    USER_SETTINGS_EMAIL: PUBLIC_URL + "/user/settings/email",
    USER_SETTINGS_PASSWORD: PUBLIC_URL + "/user/settings/password",

    // Modules
    _AMBULANCE_MODULE_PAGE: PUBLIC_URL + "/ambulance-modules/:moduleId",
};

const PathGenerator = {
    formEditor: (formId) => {
        return generatePath(PathsMap._FORM_DASHBOARD.EDITOR, {formId: formId});
    },
    ambulanceModule: (moduleId) => {
        return generatePath(PathsMap._AMBULANCE_MODULE_PAGE, {moduleId: moduleId});
    },
    formSummary: (formId) => {
        return generatePath(PathsMap._FORM_DASHBOARD.SUMMARY, {formId: formId});
    },
    formIndividual: (formId) => {
        return generatePath(PathsMap._FORM_DASHBOARD.INDIVIDUAL, {formId: formId});
    },
    createFormResponse: (formId) => {
        return generatePath(PathsMap._FORM_CREATE_RESPONSE, {formId: formId});
    },
    oauthCallback: () => {
        return generatePath(PathsMap.OAUTH_CALLBACK, {});
    },
};

const UrlGenerator = (() => {
    const fn = {};
    for (let k in PathGenerator) {
        fn[k] = (...args) => {
            return window.location.protocol + "//" + window.location.host + PathGenerator[k](...args);
        };
    }
    return fn;
})();

function PageSwitch() {
    return (
        <Switch>
            <Route exact path={PathsMap.SIGN_UP}>
                <SignUp/>
            </Route>
            <Route exact path={PathsMap.SIGN_IN}>
                <SignIn/>
            </Route>
            <Route exact path={PathsMap.SIGN_OUT}>
                <SignOut/>
            </Route>
            <Route exact path={PathsMap.RESET_PWD}>
                <ResetPassword/>
            </Route>
            <Route exact path={PathsMap.RESET_PSWD_CONFIRM}>
                <ResetPasswordConfirm/>
            </Route>
            <Route path={PathsMap.USER_SETTINGS + "/*"}>
                <UserSettings/>
            </Route>
            <Route path={PathsMap.USER_SETTINGS}>
                <UserSettings/>
            </Route>
            <Route exact path={PathsMap.EMAIL_CONFIRM}>
                <EmailConfirm/>
            </Route>
            <Route path={PathsMap._FORM_DASHBOARD.ROOT}>
                <FormDashboardPageMiddleware/>
            </Route>
            <Route path={PathsMap._FORM_CREATE_RESPONSE}>
                <FormCreateResponseMiddleware/>
            </Route>
            <Route path={PathsMap._AMBULANCE_MODULE_PAGE}>
                <AmbulanceModulePageMiddleware/>
            </Route>
            <Route path={PathsMap.OAUTH_CALLBACK}>
                <OAuthCallback/>
            </Route>
            <Route path={PathsMap.HOME}>
                <HomePageMiddleware/>
            </Route>
        </Switch>
    );
}

export {PageSwitch, UrlGenerator, PathGenerator, PathsMap};