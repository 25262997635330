import {Divider, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import {getQuestionsMatchTypes, LEGACY_QUESTION, QUESTION} from "../../utils/formUtils";
import React, {useMemo} from "react";
import {MobileDatePicker, MobileDateTimePicker, MobileTimePicker} from "@mui/x-date-pickers";
import {t} from "i18next";

export function FormEditorConditionTypeSelect({question, condition, onChange, legacy, context}) {
    let questionsMatchTypes = useMemo(() => legacy
        ? getQuestionsMatchTypes(question.type).filter(t =>
                (context === 'validation'
                    ? LEGACY_QUESTION.REQUIRED_CONSTRAINT_TYPES
                    : LEGACY_QUESTION.EQUAL_CONSTRAINT_TYPES).includes(t.value))
        : getQuestionsMatchTypes(question.type),
        [context, legacy, question.type]);

    return (
        <Select
            inputProps={{MenuProps: {disableScrollLock: true}}}
            onChange={(e) => onChange(e.target.value)}
            value={condition.type}
            label="Condition type"
            size="small"
            displayEmpty
            fullWidth>
            <MenuItem key={0} value="">
                <em>- {t('form_editor.select_a_condition')} -</em>
            </MenuItem>
            {questionsMatchTypes.map(mt =>
                <MenuItem key={mt.value} value={mt.value}>
                    {t(context === "validation" ? mt.text.required : mt.text.optional)}
                </MenuItem>)}
        </Select>
    );
}

export function FormEditorConditionPatternInput({question, condition, onChange}) {
    if ((condition.type === QUESTION.MATCH_CONDITION.TYPES.DATETIME_IS_BEFORE
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.DATETIME_IS_EQUAL
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.DATETIME_IS_NOT_EQUAL
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.DATETIME_IS_AFTER)) {
        // noinspection RequiredAttributes
        return (
            <MobileDateTimePicker
                onChange={onChange}
                value={new Date(condition.pattern)}
                slotProps={{textField: {
                        variant: "outlined",
                        size: "small",
                        fullWidth: true
                }}}
            />
        );
    }

    if (condition.type === QUESTION.MATCH_CONDITION.TYPES.DATE_IS_BEFORE
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.DATE_IS_EQUAL
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.DATE_IS_NOT_EQUAL
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.DATE_IS_AFTER) {
        // noinspection RequiredAttributes
        return (
            <MobileDatePicker
                onChange={onChange}
                value={new Date(condition.pattern)}
                slotProps={{textField: {
                        variant: "outlined",
                        size: "small",
                        fullWidth: true
                    }}}
            />
        );
    }

    if (condition.type === QUESTION.MATCH_CONDITION.TYPES.TIME_IS_BEFORE
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.TIME_IS_EQUAL
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.TIME_IS_NOT_EQUAL
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.TIME_IS_AFTER) {
        // noinspection RequiredAttributes
        return (
            <MobileTimePicker
                onChange={onChange}
                size="small"
                value={new Date(condition.pattern)}
                slotProps={{textField: {
                        variant: "outlined",
                        size: "small",
                        fullWidth: true
                    }}}
            />
        );
    }

    if (condition.type === QUESTION.MATCH_CONDITION.TYPES.CHOICE_IS_SELECTED
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.CHOICE_IS_NOT_SELECTED) {
        return (
            <Select
                inputProps={{MenuProps: {disableScrollLock: true}}}
                onChange={(e) => onChange(e.target.value)}
                value={condition.pattern}
                size="small"
                fullWidth
                displayEmpty>
                <MenuItem key={0} value="">
                    <em>- {t('form_editor.select_a_choice')} -</em>
                </MenuItem>
                {(question.choices).filter(choice => choice.title.length > 0).map(choice =>
                    <MenuItem key={choice.ref} value={choice.title}>
                        {choice.title}
                    </MenuItem>
                )}
            </Select>
        );
    }

    if (condition.type === QUESTION.MATCH_CONDITION.TYPES.TEXT_IS_EQUAL
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.TEXT_IS_NOT_EQUAL
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.TEXT_CONTAINS
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.TEXT_DOES_NOT_CONTAIN
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.TEXT_BEGINS_WITH
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.TEXT_ENDS_WITH) {
        return (
            <TextField
                onChange={(e) => onChange(e.target.value)}
                variant="outlined"
                size="small"
                value={condition.pattern}
                fullWidth/>
        );
    }

    if (condition.type === QUESTION.MATCH_CONDITION.TYPES.RATING_IS_EQUAL_OR_HIGHER_THAN
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.RATING_IS_EQUAL_OR_LOWER_THAN) {
        return (
            <Stack direction="row" spacing={1} alignItems="center">
                <TextField
                    onChange={(e) => onChange(e.target.value)}
                    variant="outlined"
                    size="small"
                    value={condition.pattern}
                    sx={{maxWidth: '120px'}}
                    label={`0-5`}
                    fullWidth/>
                <Typography>
                    {t('question.type_names.RATING')}
                </Typography>
            </Stack>
        );
    }

    if (condition.type === QUESTION.MATCH_CONDITION.TYPES.TEXT_HAS_MIN_N_CHARS
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.TEXT_HAS_MAX_N_CHARS
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.TEXT_HAS_N_CHARS) {
        return (
            <Stack direction="row" spacing={1} alignItems="center">
                <TextField
                    onChange={(e) => onChange(e.target.value)}
                    variant="outlined"
                    size="small"
                    value={condition.pattern}
                    sx={{maxWidth: '120px'}}
                    label={`0-${QUESTION.RESPONSE_MAX_LENGTH}`}
                    fullWidth/>
                <Typography>
                    chars
                </Typography>
            </Stack>
        );
    }

    if (condition.type === QUESTION.MATCH_CONDITION.TYPES.MAX_N_CHOICES_SELECTED
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.N_CHOICES_SELECTED
        || condition.type === QUESTION.MATCH_CONDITION.TYPES.MIN_N_CHOICES_SELECTED) {
        return (
            <Stack direction="row" spacing={1} alignItems="center">
                <TextField
                    onChange={(e) => onChange(e.target.value)}
                    variant="outlined"
                    size="small"
                    value={condition.pattern}
                    sx={{maxWidth: '120px'}}
                    label="number"
                    fullWidth/>
                <Typography>
                    choices
                </Typography>
            </Stack>
        );
    }

    // noinspection CheckTagEmptyBody
    return <Divider orientation="horizontal" sx={{display: {xs: 'none', md: 'block'}}}></Divider>;
}