import React, {useMemo} from "react";
import {getQuestionsMatchTypes, QUESTION} from "../../utils/formUtils";
import {TransitionGroup} from "react-transition-group";
import {Alert, Box, Divider, Fade, Grid, IconButton, MenuItem, Select, Tooltip} from "@mui/material";
import {FormEditorConditionPatternInput, FormEditorConditionTypeSelect} from "./FormEditorConditions";
import {AddOutlined, DeleteOutlined} from "@mui/icons-material";
import {ICON_STYLES} from "../../app/Theme";
import Button from "@mui/material/Button";
import {t} from "i18next";

export function FormEditorExitConditions({formManager, question, managers, legacy, errorsPrefix, errors}) {
    const canAddExitConditions = useMemo(() => {
        return getQuestionsMatchTypes(question.type).length > 0;
    }, [question.type]);

    const questionsManager = managers.questions;
    const exitConditionsManager = managers.exitConditions;

    return (<>
        <TransitionGroup>
            {canAddExitConditions && question.exitConditions.map(ec => {
                const ecErrorsPrefix = `${errorsPrefix}.exitConditions[${ec.ref}]`;
                return (
                    <Fade key={ec.ref} timeout={{appear: 0, enter: 300, exit: 0}}><Box sx={{mb: 3}}>
                        <Grid container spacing={2} sx={{width: '100%'}} columns={{xs: 2, md: 20}} alignItems="center">
                            <Grid item xs={2} md={3}>
                                <Divider orientation="horizontal" sx={{fontWeight: {xs: 700, md: 400}}}>
                                    {t('form_editor.if_response')}
                                </Divider>
                            </Grid>
                            <Grid item xs={2} md={9}>
                                {ec.matchConditions.map((mc, mcIndex) => (
                                    <Grid container
                                          sx={{width: '100%', pt: {xs: 0, md: mcIndex > 0 ? 1 : 0}}}
                                          alignItems="center"
                                          key={mc.ref}
                                          spacing={1}>
                                        <Grid item xs={2} md={8}>
                                            <FormEditorConditionTypeSelect
                                                question={question}
                                                legacy={legacy}
                                                context={'exitCondition'}
                                                onChange={(newValue) => {
                                                    exitConditionsManager.editType(question.ref, ec.ref, mc.ref, newValue);
                                                    formManager.resetErrors(ecErrorsPrefix);
                                                }}
                                                condition={mc}/>
                                        </Grid>
                                        <Grid item xs={2} md={10}>
                                            <FormEditorConditionPatternInput
                                                question={question}
                                                condition={mc}
                                                onChange={(newValue) => {
                                                    exitConditionsManager.editPattern(question.ref, ec.ref, mc.ref, newValue);
                                                    formManager.resetErrors(ecErrorsPrefix);
                                                }}/>
                                        </Grid>
                                        <Grid item xs={2} md={2}>
                                            {ec.matchConditions.length > 1 &&
                                            <Box sx={{
                                                display: "flex",
                                                justifyContent: {xs: "right", md: "center"},
                                                pb: {xs: 1, md: 0}
                                            }}>
                                                <IconButton
                                                    size="small"
                                                    aria-label="Delete condition"
                                                    onClick={() => {
                                                        exitConditionsManager.deleteMatch(question.ref, ec.ref, mc.ref);
                                                    }}
                                                    onMouseDown={(e) => {
                                                        e.preventDefault()
                                                    }}>
                                                    <DeleteOutlined sx={ICON_STYLES.icon21}/>
                                                </IconButton>
                                            </Box>}
                                        </Grid>
                                    </Grid>))}
                            </Grid>
                            <Grid item xs={2} md={1}>
                                <Box display="flex" justifyContent="center">
                                    <Tooltip title={t('form_editor.add_and_match_condition')}>
                                        <Button
                                            size="small"
                                            disabled={legacy}
                                            onClick={() => exitConditionsManager.addMatch(question.ref, ec.ref)}>
                                            + AND
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <Divider orientation="horizontal">
                                    {t('form_editor.go_to')}
                                </Divider>
                            </Grid>
                            <Grid item xs={2} md={4}>
                                <Select
                                    inputProps={{MenuProps: {disableScrollLock: true}}}
                                    onChange={(e) => {
                                        exitConditionsManager.editGoto(question.ref, ec.ref, e.target.value);
                                        formManager.resetErrors(ecErrorsPrefix);
                                    }}
                                    value={ec.exitRef === null ? "" : ec.exitRef}
                                    size="small"
                                    displayEmpty
                                    fullWidth>
                                    <MenuItem key="" value="">
                                        <em>- {t('form_editor.select_a_choice')} -</em>
                                    </MenuItem>
                                    {question.possibleExitRefs.map(q =>
                                        <MenuItem key={q.ref} value={q.ref}>
                                            {q.position}) {q.title.length > 0
                                            ? q.title
                                            : <em>- {t('form_editor.no_title')} -</em>}
                                        </MenuItem>
                                    )}
                                    <MenuItem key={"next"} value={QUESTION.REF.NEXT}>
                                        <em>{t('form_editor.next_question')} ({question.possibleExitRefs.length >= question.position ? question.position+1 : 'End'})</em>
                                    </MenuItem>
                                    <MenuItem key={"end"} value={QUESTION.REF.END}>
                                        <em>{t('form_editor.end')}</em>
                                    </MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={2} md={1}>
                                <Box display="flex" justifyContent="right">
                                    <IconButton
                                        aria-label="Delete condition"
                                        onClick={() => {
                                            exitConditionsManager.delete(question.ref, ec.ref);
                                            formManager.resetErrors(ecErrorsPrefix);
                                        }}
                                        onMouseDown={(e) => {
                                            e.preventDefault()
                                        }}
                                    >
                                        <DeleteOutlined sx={ICON_STYLES.icon21}/>
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        {(errors?.[ecErrorsPrefix] || []).map((error, errorKey) =>
                            <Alert severity="error" key={errorKey} sx={{mb: 1}}>{error}</Alert>)}
                    </Box></Fade>)
            })}
        </TransitionGroup>
        <Grid container
              spacing={2}
              sx={{width: '100%'}}
              columns={{xs: 2, md: 20}}
              alignItems="center">
            <Grid item xs={2} md={10}>
                {canAddExitConditions &&
                <Button variant="outlined"
                        startIcon={<AddOutlined/>}
                        size="small"
                        onClick={() => exitConditionsManager.add(question.ref)}>
                    {t('question.add_condition')}
                </Button>}
            </Grid>
            <Grid item xs={2} md={5}>
                <Divider orientation="horizontal">
                    {question.exitConditions.length > 0
                        ? t('form_editor.otherwise_go_to')
                        : t('form_editor.go_always_to')}
                </Divider>
            </Grid>
            <Grid item xs={2} md={5}>
                <Select
                    inputProps={{MenuProps: {disableScrollLock: true}}}
                    onChange={(e) => questionsManager.editDefaultExitRef(question.ref, e.target.value)}
                    value={question.defaultExitRef}
                    displayEmpty
                    size="small"
                    fullWidth>
                    {question.possibleExitRefs.map(q =>
                        <MenuItem key={q.ref} value={q.ref}>
                            {q.position}) {q.title.length > 0
                            ? q.title
                            : <em>- no title -</em>}
                        </MenuItem>
                    )}
                    <MenuItem key={"next"} value={QUESTION.REF.NEXT}>
                        <em>{t('form_editor.next_question')} ({question.possibleExitRefs.length >= question.position
                            ? question.position + 1
                            : t('form_editor.end')})</em>
                    </MenuItem>
                    <MenuItem key={"end"} value={QUESTION.REF.END}>
                        <em>{t('form_editor.end')}</em>
                    </MenuItem>
                </Select>
            </Grid>
        </Grid>
    </>);
}