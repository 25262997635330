import React, {useCallback, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router";
import {PathGenerator} from "../app/PageRouting";
import {
    Alert, Box,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia, CircularProgress, Divider,
    Grid,
    Grow,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem, Pagination,
    Stack, Tooltip,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import {NavLink} from "react-router-dom";
import {
    AddOutlined,
    BarChartOutlined, ContentCopyOutlined, DeleteOutlined,
    EditOutlined,
    MoreVertOutlined,
    QuestionAnswerOutlined,
    ShareOutlined,
    VisibilityOutlined
} from "@mui/icons-material";
import AppConsts from "../app/AppConsts";
import {scrollToElementByRef} from "../utils/scroll";
import {logError} from "../utils/logging";
import {useFormShareDialog} from "../form-dialogs/FormShareDialog";
import {useSnackbar} from "../providers/SnackbarProvider";
import {useFormDeleteDialog} from "../form-dialogs/FormDeleteDialog";
import {FORM, QUESTION} from "../utils/formUtils";
import {ICON_STYLES, THEME_PALETTES} from "../app/Theme";
import {httpClient} from "../utils/httpClient";
import {t} from "i18next";
import {useFormCreateDialog} from "../form-create/FormCreateDialog";

function FormsCardListEl(props) {
    const form = props.form;
    const refreshList = props.refreshList;

    const [menuAnchor, setMenuAnchor] = useState(null);
    const menuOpen = Boolean(menuAnchor);
    const handleCloseMenu = useCallback(() => {
        setMenuAnchor(null);
    }, []);
    const handleClickMore = useCallback((event) => {
        setMenuAnchor(event.currentTarget);
    }, [setMenuAnchor]);
    const history = useHistory();
    const [openShareDialog] = useFormShareDialog(form);
    
    const [deleteDialogComponent, openDeleteDialog] = useFormDeleteDialog(form, () => refreshList());
    const handleDeleteForm = useCallback(() => {
        handleCloseMenu();
        openDeleteDialog();
    }, [handleCloseMenu, openDeleteDialog]);

    const {createSnackbar} = useSnackbar();
    const handleDuplicateForm = useCallback(() => {
        handleCloseMenu();
        httpClient.post(`${AppConsts.API_PATH}/forms/${form.id}/duplicate`).then(response => {
            if (response.data.form) {
                refreshList();
            } else {
                createSnackbar({
                    message: response.data.errors[0].message,
                    autoHideDuration: 2000,
                    type: "error"
                });
            }
        }).catch((e) => {
            logError(e);
        });
    }, [createSnackbar, form.id, handleCloseMenu, refreshList]);

    const menuElements = [
        <MenuItem key="view" component={NavLink} to={PathGenerator.createFormResponse(form.id)}>
            <ListItemIcon>
                <VisibilityOutlined fontSize="small"/>
            </ListItemIcon>
            {t('common.view')}
        </MenuItem>,
        <MenuItem key="share" onClick={() => {
            openShareDialog();
            handleCloseMenu();
        }}>
            <ListItemIcon>
                <ShareOutlined fontSize="small"/>
            </ListItemIcon>
            {t('common.share')}
        </MenuItem>,
        <Divider key="firstDivider"/>
    ];

    if (form.canViewResponses) {
        menuElements.push(
            <MenuItem key="summary" component={NavLink} to={PathGenerator.formSummary(form.id)}>
                <ListItemIcon>
                    <BarChartOutlined fontSize="small"/>
                </ListItemIcon>
                {t('form_summary.summary_title')}
            </MenuItem>
        );
        menuElements.push(
            <MenuItem key="individual" component={NavLink} to={PathGenerator.formIndividual(form.id)}>
                <ListItemIcon>
                    <QuestionAnswerOutlined fontSize="small"/>
                </ListItemIcon>
                {t('form_individual.individual_title')}
            </MenuItem>
        );
    }

    if (form.canEdit) {
        menuElements.push(<Divider key="dividerCanEdit"/>);
        menuElements.push(
            <MenuItem component={NavLink} to={PathGenerator.formEditor(form.id)} key="edit">
                <ListItemIcon>
                    <EditOutlined fontSize="small"/>
                </ListItemIcon>
                {t('common.edit')}
            </MenuItem>
        );
        menuElements.push(
            <MenuItem onClick={handleDeleteForm} key="delete">
                <ListItemIcon>
                    <DeleteOutlined fontSize="small"/>
                </ListItemIcon>
                {t('common.delete')}
            </MenuItem>
        );
        menuElements.push(
            <MenuItem onClick={handleDuplicateForm} key="duplicate">
                <ListItemIcon>
                    <ContentCopyOutlined fontSize="small"/>
                </ListItemIcon>
                {t('common.duplicate')}
            </MenuItem>
        );
    }

    return (
    <Grow in={true}>
        <Card variant="outlined" sx={{width: 1}}>
            <Tooltip placement="bottom" title={
                    <div>
                        <div>{`${t('common.created_by')}: ${form.creator.name} (${form.creator.email})`}</div>
                        <div>{`${t('common.last_edit')}: ${form.lastEdit}`}</div>
                        <div>{`${t('form.total_questions')}: ${form.questions.length}`}</div>
                    </div>
            }>
                <CardActionArea onClick={() => history.push(PathGenerator.formEditor(form.id))}>
                    <FormCardMedia form={form} />
                    <CardContent>
                        <Typography gutterBottom variant="h2" noWrap>
                            {form.title}
                        </Typography>
                        {form.description.length > 0 ?
                            <Typography color="text.secondary" noWrap>
                                {form.description}
                            </Typography> :
                            <Typography color="text.disabled" noWrap>
                                Empty description
                            </Typography>
                        }
                    </CardContent>
                </CardActionArea>
            </Tooltip>
            <CardActions>
                <Stack direction="row" spacing={2} justifyContent="space-between" sx={{width: 1}}>
                    {form.canEdit ?
                        <Button component={NavLink} to={PathGenerator.formEditor(form.id)} size="small">
                            <EditOutlined sx={{fontSize: 14, mr: 1}}/>
                            {t('common.edit')}
                        </Button> :
                        (form.canViewResponses &&
                            <Button component={NavLink} to={PathGenerator.formIndividual(form.id)} size="small">
                                <QuestionAnswerOutlined sx={{fontSize: 14, mr: 1}}/>
                                View responses
                            </Button>)
                    }
                    <IconButton
                        aria-haspopup="true"
                        onClick={handleClickMore}>
                        <MoreVertOutlined/>
                    </IconButton>
                </Stack>
            </CardActions>
            <Menu
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={handleCloseMenu}
                PaperProps={{
                    style: {
                        minWidth: 220,
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                {menuElements}
            </Menu>
            {deleteDialogComponent}
        </Card>
    </Grow>);
}

function FormCardMedia({form}) {
    const palette = THEME_PALETTES[FORM.THEME_2_PALETTE[form.theme]];

    return (
        <CardMedia
            component="div"
            sx={{
                background: `rgba(0, 0, 0, 0) linear-gradient(to right bottom, ` +
                    `${palette.light}, ${palette.main} 120%) repeat scroll 0% 0%`,
                height: '130px',
                color: 'rgba(255, 255, 255, 0.5)',
                overflow: 'hidden',
                position: 'relative',
                opacity: 0.85
        }}>
            <Box sx={{p: 2}}>
                {form.questions.slice(0, 6).map(q => {
                    const typeDetails = QUESTION.TYPES_DETAILS.find(t => t.id === q.type);
                    const TypeIcon = typeDetails.icon;

                    return (
                        <Stack sx={{mb: '6px'}} direction="row" spacing={1} alignItems="center" justifyContent="space-between" key={q.id}>
                            <Stack direction="row" spacing={1} alignItems="center" sx={{minWidth: 0}}>
                                <Typography sx={{
                                    fontSize: '10px',
                                    color: 'rgba(255, 255, 255, 0.8)',
                                    minWidth: '14px',
                                    textAlign: 'center',
                                    borderRadius: '100%',
                                    border: '1px solid rgba(255, 255, 255, 0.5)',
                                    lineHeight: '12px',
                                    padding: '0 2px'
                                }}>
                                    {q.position}
                                </Typography>
                                <Typography sx={{fontSize: '13px', fontWeight: 300}} noWrap>
                                    {q.title}
                                </Typography>
                            </Stack>
                            <TypeIcon sx={ICON_STYLES.icon16}/>
                        </Stack>
                    );
                })}
            </Box>
            <Box sx={{
                background: `linear-gradient(to bottom, transparent, ${palette.light})`,
                height: '100px',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0
            }}>
            </Box>
        </CardMedia>
    );
}

function FormsManagedCardList() {
    const [viewState, setViewState] = useState({code: "loading"});
    const [pageNumber, setPageNumber] = useState(1);
    const [forms, setForms] = useState(null);
    const topRef = useRef(null);

    const [refreshCounter, setRefreshCounter] = useState(0);
    const refreshList = useCallback(() => {
        setRefreshCounter(counter => counter + 1);
    }, []);

    useEffect(() => {
        const cancelTokenSource = httpClient.cancelToken();
        let isMounted = true;

        httpClient.get(`${AppConsts.API_PATH}/forms/dashboard/me?pageNumber=${pageNumber}&pageSize=12`, {
            cancelToken: cancelTokenSource.token
        }).then(response => {
            if (!isMounted) {
                return;
            }
            if (response.data.errors) {
                setViewState({
                    code: "loadingError",
                    text: response.data.errors[0].message
                });
            } else {
                const data = response.data;
                setForms({
                    pageNumber: data.page.pageNumber,
                    totalPages: data.pagination.totalPages,
                    totalForms: data.pagination.totalElements,
                    list: data.page.elements.map(f => ({
                        id: f.id,
                        title: f.title,
                        description: f.description,
                        theme: f.theme,
                        canEdit: f.canEdit,
                        canDelete: f.canDelete,
                        canDuplicate: f.canDuplicate,
                        canViewResponses: f.canViewResponses,
                        lastEdit: f.lastEdit,
                        creator: {
                            name: f.creator.name,
                            email: f.creator.email
                        },
                        questions: f.questions.map(q => ({
                            id: q.id,
                            title: q.title,
                            position: q.position,
                            type: q.type
                        }))
                    }))
                });
                setViewState({code: "loaded"});
            }
        }).catch((e) => {
            logError(e);
            if (!isMounted) {
                return;
            }
            setViewState({
                code: "loadingError",
                text: t('common.sorry_something_went_wrong')
            });
        });

        return () => {
            isMounted = false;
            cancelTokenSource.cancel();
        }
    }, [pageNumber, refreshCounter]);

    if (viewState.code === "loading") {
        return <CircularProgress/>;
    }

    if (viewState.code === "loadingError") {
        return <Alert severity="error">{viewState.text}</Alert>;
    }

    if (!forms.list.length) {
        return <Alert severity="info" sx={{mb: 4}}>
            {t('forms_list.you_haven_t_created_any_form')}
        </Alert>
    }

    return (
        <Box ref={topRef}>
            <Grid container spacing={4}>
                {forms.list.map(form =>
                    <Grid item xs={12} sm={6} lg={4} key={form.id}>
                        <FormsCardListEl
                            form={form}
                            refreshList={refreshList} />
                    </Grid>)}
            </Grid>
            {forms.totalPages > 1 && <>
                <Divider sx={{mt: 5, mb: 2}} />
                <Pagination
                    onChange={(e, page) => {
                        setPageNumber(page);
                        scrollToElementByRef(topRef);
                    }}
                    count={forms.totalPages} /></>}
        </Box>
    );
}


export function FormsManagedTab() {
    const [openCreateFormDialog] = useFormCreateDialog();

    return (
        <>
            <Box sx={{mb: 4}}>
                <Button variant="contained" startIcon={<AddOutlined/>} onClick={openCreateFormDialog}>
                    {t('new_form.new_form_button')}
                </Button>
            </Box>
            <FormsManagedCardList />
        </>
    );
}