import {autocompleteStyles} from "../app/Theme";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {httpClient} from "../utils/httpClient";
import AppConsts from "../app/AppConsts";
import {logError} from "../utils/logging";
import {Autocomplete, Box, CircularProgress, TextField, Typography} from "@mui/material";
import {t} from "i18next";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import {USER_CATEGORY_PATIENT_ID} from "../utils/userCategories";

export function UserAutocomplete({onChange}) {
    const classes = autocompleteStyles();
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const xhrTimeout = useRef(null);
    const search = useCallback((e = null) => {
        clearTimeout(xhrTimeout.current);
        setLoading(true);
        xhrTimeout.current = setTimeout(() => {
            httpClient.get(`${AppConsts.API_PATH}/users/search`, {
                params: {
                    query: e ? e.target.value : "",
                    category: USER_CATEGORY_PATIENT_ID
                }
            }).then(response => {
                setLoading(false);
                if (response.data.users) {
                    setFilteredUsers(response.data.users.map(r => ({
                        type: r.type,
                        id: r.id,
                        name: r.name,
                        fiscalCode: r.fiscalCode
                    })));
                }
            }).catch((e) => {
                setLoading(false);
                logError(e);
            });
        }, 350);
    }, []);

    useEffect(() => {
        search();
    }, [search]);

    return <Autocomplete
        options={filteredUsers}
        onChange={(e, newValue) => onChange(newValue)}
        isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
        }}
        getOptionLabel={(option) => option.name}
        filterOptions={(x) => x}
        classes={{paper: classes.paper}}
        openOnFocus={true}
        noOptionsText={t('common.no_results')}
        renderOption={(props, option) => (
            <Box component="li" {...props}>
                <PersonOutlineOutlinedIcon sx={{mr: 2, flexShrink: 0}}/>
                {option.name}
                {option.fiscalCode
                    && <Typography component="span" color="text.secondary" sx={{ml: 1}}>({option.fiscalCode})</Typography>}
            </Box>
        )}
        clearOnBlur={false}
        renderInput={(params) => (
            <TextField
                {...params}
                variant="standard"
                placeholder={t('user_autocomplete.placeholder')}
                onChange={(e) => search(e)}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: loading
                        ? <CircularProgress color="inherit" size={20}/> : null
                }}
            />
        )}
    />;
}