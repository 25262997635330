import React, {useEffect, useMemo, useRef, useState} from "react";
import AppConsts from "../../app/AppConsts";
import {
    Alert,
    Box, Checkbox,
    CircularProgress, Divider,
    Grid,
    ListItemIcon,
    Menu,
    MenuItem,
    Paper, Stack,
    Typography
} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {Bar, Pie, PolarArea} from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {NavLink} from "react-router-dom";
import {PathGenerator} from "../../app/PageRouting";
import {BarChartOutlined, CheckOutlined, EditOutlined, PieChartOutline, QuizOutlined} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {logError} from "../../utils/logging";
import {createStyles, makeStyles} from "@mui/styles";
import {ICON_STYLES, Theme} from "../../app/Theme";
import {red} from "@mui/material/colors";
import {httpClient} from "../../utils/httpClient";
import {t} from "i18next";

export function FormResponsesSummary(props) {
    const form = props.form;
    const [summary, setSummary] = useState(null);
    const [viewState, setViewState] = useState({code: "loading"});
    const keyGenerator = useRef(0);

    useEffect(() => {
        httpClient.get(`${AppConsts.API_PATH}/forms/${form.id}/responses/summary`).then(response => {
            let data = response.data;
            if (data.errors) {
                setViewState({
                    code: "loadingError",
                    text: data.errors[0].message
                });
            } else {
                setSummary({
                    questions: data.questions.map(q => ({
                        _key: ++keyGenerator.current,
                        title: q.title,
                        type: q.type,
                        totalResponses: q.totalResponses,
                        aggregatedResponses: q.aggregatedResponses.map(response => ({
                            value: response.value,
                            occurrences: response.occurrences,
                        }))
                    })),
                    topics: data.topics.map(t => ({
                        _key: ++keyGenerator.current,
                        title: t.title,
                        averageKnowledgeScore: t.averageKnowledgeScore,
                        minimumKnowledgeScore: t.minimumKnowledgeScore,
                        maximumKnowledgeScore: t.maximumKnowledgeScore
                    })),
                    quiz: {
                        maxScore: data.quizMaxScore,
                        stats: data.quizStats ? {
                            minimumScore: data.quizStats.minimumScore,
                            averageScore: data.quizStats.averageScore,
                            maxiumumScore: data.quizStats.maxiumumScore,
                            totalScores: data.quizStats.totalScores,
                        } : null
                    }
                });
                setViewState({code: "loaded"});
            }
        }).catch((e) => {
            logError(e);
            setViewState({
                code: "loadingError",
                text: t('common.sorry_something_went_wrong')
            });
        });
    }, [form.id]);

    if (viewState.code === "loading") {
        return <Box sx={{justifyContent: 'center', display: 'flex', py: 4}}><CircularProgress/></Box>;
    }

    if (viewState.code === "loadingError") {
        return <Alert severity="error">{viewState.text}</Alert>;
    }

    if (summary.questions.length < 1) {
        return <Box>
            <Alert severity="info" sx={{mb: 3}}>
                {t('form_summary.this_form_hasn_t_got_any_question')}
            </Alert>
            <Button component={NavLink} to={PathGenerator.formEditor(form.id)} size="small" color="neutral">
                <EditOutlined sx={{fontSize: 14, mr: 1}}/>
                {t('common.edit')}
            </Button>
        </Box>;
    }

    return (
        <>
            {summary.quiz.stats && summary.quiz.stats.totalScores > 1 &&
                <FormQuizStats quiz={summary.quiz} />}
            {summary.topics.length > 0 &&
                <FormTopicsSummary topics={summary.topics} />}
            {summary.questions.map(question => (
                <FormSummaryQuestion question={question} key={question._key} />
            ))}
        </>
    );
}

function FormQuizStats({quiz}) {
    const stats = quiz.stats;

    return (
        <Paper variant="outlined" sx={{py: 3, mb: 3}}>
            <Grid container sx={{width: '100%'}}>
                <Grid item xs={12} md={3}>
                    <Typography variant="p" component="div" align="center" sx={{mb: 2, fontWeight: 500}}>
                        {t('form_summary.quiz_results')}
                    </Typography>
                    <Typography component="div" color="text.primary">
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                            <QuizOutlined sx={ICON_STYLES.icon21} />
                            <span>{stats.totalScores}</span>
                        </Stack>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Divider sx={{my: 2, display: {xs: 'block', 'md': 'none'}}} />
                    <Typography variant="p" component="div" align="center" sx={{mb: 2}}>
                        {t('form_summary.avg_score')}
                    </Typography>
                    <QuizScoreColored score={Math.round(stats.averageScore * 10) / 10} maxScore={quiz.maxScore} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Divider sx={{my: 2, display: {xs: 'block', 'md': 'none'}}} />
                    <Typography variant="p" component="div" align="center" sx={{mb: 2}}>
                        {t('form_summary.min_score')}
                    </Typography>
                    <QuizScoreColored score={stats.minimumScore} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Divider sx={{my: 2, display: {xs: 'block', 'md': 'none'}}} />
                    <Typography variant="p" component="div" align="center" sx={{mb: 2}}>
                        {t('form_summary.max_score')}
                    </Typography>
                    <QuizScoreColored score={stats.maxiumumScore} />
                </Grid>
            </Grid>
        </Paper>
    );
}

function QuizScoreColored({score, maxScore}) {
    if (typeof maxScore === 'number') {
        return (
            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                <Typography variant="h2" sx={{color: score < 0 ? red[500] : Theme.palette.text.primary}}>
                    {score}
                </Typography>
                <Typography variant="h3" color="text.secondary">/</Typography>
                <Typography variant="h3" color="text.secondary">
                    {maxScore}
                </Typography>
            </Stack>
        );
    }

    return (
        <Typography variant="h2" align="center" sx={{color: score < 0 ? red[500] : Theme.palette.text.primary}}>
            {score}
        </Typography>
    );
}

function FormTopicsSummary({topics}) {
    const chartData = {
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 1
            }
        ]
    };

    const datagridRows = [];

    let incrementalColorIndex = 0;
    topics.forEach(topic => {
        if (topic.minimumKnowledgeScore === 0 && topic.maximumKnowledgeScore === 0) {
            return;
        }

        if (incrementalColorIndex > DATAGRID_VALUES_COLORS.length - 1) {
            incrementalColorIndex = 0;
        }

        chartData.labels.push(topic.title);

        let dataset = chartData.datasets[0];
        dataset.data.push(topic.averageKnowledgeScore);
        dataset.backgroundColor.push(`rgba(${DATAGRID_VALUES_COLORS[incrementalColorIndex]}, 0.5)`);
        dataset.borderColor.push(`rgba(${DATAGRID_VALUES_COLORS[incrementalColorIndex]}, 0.6)`);

        datagridRows.push({
            id: datagridRows.length + 1,
            title: topic.title,
            averageKnowledgeScore: topic.averageKnowledgeScore,
            minimumKnowledgeScore: topic.minimumKnowledgeScore,
            maximumKnowledgeScore: topic.maximumKnowledgeScore,
            colorIndex: incrementalColorIndex
        });

        incrementalColorIndex++;
    });

    const coloredDataGridStyles = useColoredDataGridStyles();

    const LOCALE_TEXT_TOPICS = useRef({
        footerRowSelected: (count) =>
            count !== 1
                ? t('form_summary.n_topics_selected', {n: count.toLocaleString()})
                : t('form_summary.1_topic_selected')
    });

    // noinspection JSUnusedLocalSymbols
    return (
        <Paper variant="outlined" sx={{px: 4, py: 5, mb: 3}}>
            <Typography gutterBottom variant="h2" component="h2" sx={{mb: 2}}>
                Topics knowledge
            </Typography>
            <Grid container sx={{width: '100%'}}>
                <Grid item xs={12} md={6}>
                    <PolarArea
                        data={chartData}
                        plugins={[ChartDataLabels]}
                        height={450}
                        options={{
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    display: false
                                },
                                tooltip: {
                                    callbacks: {
                                        title: (item) => {
                                            if (item.length) {
                                                return item[0].label;
                                            }
                                        },
                                        label: (item) => {
                                            return item.raw.toFixed(1) + '% (average)';
                                        }
                                    }
                                },
                                datalabels: {
                                    anchor: 'end',
                                    backgroundColor: function(context) {
                                        return context.dataset.borderColor;
                                    },
                                    borderColor: 'white',
                                    borderRadius: 25,
                                    borderWidth: 2,
                                    padding: 6,
                                    color: '#fff',
                                    font: {
                                        family: Theme.typography.fontFamily,
                                        weight: 'bold',
                                        size: '15px'
                                    },
                                    formatter: function(value, context) {
                                        if (chartData.datasets[0].data.length > 15) {
                                            return null;
                                        }
                                        return value.toFixed(1) + '%';
                                    }
                                }
                            },
                            scales: {
                                r: {
                                    beginAtZero: true,
                                    max: 100,
                                    min: 0,
                                    stepSize: 1
                                }
                            }
                        }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <div style={{height: 400, width: '100%'}}>
                        <DataGrid
                            rows={datagridRows}
                            columns={TOPICS_DATAGRID_COLUMNS}
                            initialState={{ pagination: { paginationModel: { page: 0, pageSize: 4 } } }}
                            pageSizeOptions={[4]}
                            className={coloredDataGridStyles.root}
                            getRowClassName={(params) => `datagrid-colors--${params.row.colorIndex}`}
                            localeText={LOCALE_TEXT_TOPICS.current}
                        />
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
}

let TOPICS_DATAGRID_COLUMNS = [
    {
        field: 'title',
        headerName: 'Topic',
        flex: 1
    }, {
        field: 'averageKnowledgeScore',
        headerName: 'Avg',
        type: 'number',
        minWidth: 90,
        valueFormatter: (value) => value.toFixed(1) + '%',
        valueParser: (value) => Number(value) / 100
    }, {
        field: 'minimumKnowledgeScore',
        headerName: 'Min',
        type: 'number',
        minWidth: 90,
        valueFormatter: (value) => value.toFixed(1) + '%',
        valueParser: (value) => Number(value) / 100
    }, {
        field: 'maximumKnowledgeScore',
        headerName: 'Max',
        type: 'number',
        minWidth: 90,
        valueFormatter: (value) => value.toFixed(1) + '%',
        valueParser: (value) => Number(value) / 100
    }
];

const CheckboxOutlinedIcon = React.forwardRef((props, ref) => {
    return <Checkbox ref={ref} {...props} checkedIcon={<CheckOutlined/>} />;
});

function FormSummaryQuestion({question}) {
    const CHART_TYPES = useRef({
        bar: {
            id: "bar",
            name: t('form_summary.bar_chart'),
            icon: BarChartOutlined
        },
        pie: {
            id: "pie",
            name: t('form_summary.pie_chart'),
            icon: PieChartOutline
        }
    });

    const summaryData = useMemo(() => generateQuestionSummaryData(question, CHART_TYPES.current), [question]);
    const [filteredRowsIds, setFilteredRowsIds] = useState(summaryData.rows.filter((row, index) => index < 75).map(row => row.id));

    const [chartType, setChartType] = useState(summaryData.chart.defaultType);
    const ChartTypeIcon = chartType.icon;

    const [menuAnchor, setMenuAnchor] = useState(null);
    const menuOpen = Boolean(menuAnchor);
    const handleCloseMenu = () => {
        setMenuAnchor(null);
    };
    const handleMenuChartTypeChange = (ct) => {
        setChartType(ct);
        handleCloseMenu();
    };

    const coloredDataGridStyles = useColoredIfSelectedDataGridStyles();

    const LOCALE_TEXT_RESPONSES = useRef({
        footerRowSelected: (count) =>
            count !== 1
                ? t('form_summary.n_responses_selected', {n: count.toLocaleString()})
                : t('form_summary.1_response_selected')
    });

    const AGGREGATED_RESPONSES_DATAGRID_COLUMNS = useRef([
        {
            field: 'value',
            headerName: t('form_summary.response'),
            flex: 1
        }, {
            field: 'occurrences',
            headerName: t('form_summary.occurrences'),
            description: t('form_summary.how_many_times_this_response'),
            type: 'number',
            minWidth: 120
        },  {
            field: 'ratio',
            headerName: t('form_summary.percentage'),
            type: 'number',
            minWidth: 110,
            valueFormatter: (value) => (value * 100).toFixed(1) + '%',
            valueParser: (value) => Number(value) / 100
        }
    ]);

    return (
        <Paper variant="outlined" sx={{px: 4, py: 5, mb: 3}}>
            {summaryData.rows.length ?
                <>
                    <Grid container sx={{width: '100%'}}>
                        <Grid item xs={12} sm={9}>
                            <Typography gutterBottom variant="h2" component="h2">
                                {question.title}
                            </Typography>
                            <Typography color="text.secondary" sx={{mb: 2}}>
                                {question.totalResponses === 1
                                    ? t('form_summary.1_response')
                                    : t('form_summary.n_responses', {n: question.totalResponses})}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3} sx={{display: {xs: 'none', md: 'block'}}}>
                            <Box display="flex" justifyContent="right">
                                <Button onClick={(e) => setMenuAnchor(e.target)} startIcon={<ChartTypeIcon />}>
                                    {chartType.name}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container sx={{width: '100%'}}>
                        <Grid item xs={12} md={6}>
                            <div style={{height: 400, width: '100%'}}>
                                <DataGrid
                                    checkboxSelection
                                    rows={summaryData.rows}
                                    columns={AGGREGATED_RESPONSES_DATAGRID_COLUMNS.current}
                                    initialState={{ pagination: { paginationModel: { page: 0, pageSize: 5 } } }}
                                    pageSizeOptions={[5]}
                                    onRowSelectionModelChange={selectedIds => setFilteredRowsIds(selectedIds)}
                                    rowSelectionModel={filteredRowsIds}
                                    className={coloredDataGridStyles.root}
                                    localeText={LOCALE_TEXT_RESPONSES.current}
                                    getRowClassName={(params) => `datagrid-colors--${params.row.colorIndex}`}
                                    slots={{
                                        checkbox: CheckboxOutlinedIcon
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{display: {xs: 'flex', md: 'none'}, justifyContent: 'center'}}>
                                <Button onClick={(e) => setMenuAnchor(e.target)} startIcon={<ChartTypeIcon />}>
                                    {chartType.name}
                                </Button>
                            </Box>
                            <Box sx={{ml: {md: 3}, mt: {xs: 3, md: 0}}}>
                                <FormSummaryResponsesChart
                                    summaryData={summaryData}
                                    renderChartType={chartType}
                                    filteredRowsIds={filteredRowsIds} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Menu
                        anchorEl={menuAnchor}
                        open={menuOpen}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        {summaryData.chart.types.map(ct => {
                            const ChartTypeIcon = ct.icon;
                            return (
                                <MenuItem onClick={() => handleMenuChartTypeChange(ct)} key={ct.id} selected={chartType.id === ct.id}>
                                    <ListItemIcon>
                                        <ChartTypeIcon fontSize="small"/>
                                    </ListItemIcon>
                                    {ct.name}
                                </MenuItem>);
                        })}
                    </Menu>
                </>
                :
                <>
                    <Typography gutterBottom variant="h2" component="h2">
                        {question.title}
                    </Typography>
                    <Alert severity="info" sx={{mt: 2}}>
                        {t('form_summary.no_responses_avabile_for_aggregated')}
                    </Alert>
                </>}
        </Paper>
    );
}

function FormSummaryResponsesChart({summaryData, renderChartType, filteredRowsIds}) {
    const filteredSummaryRows = useMemo(() => {
        return summaryData.rows.filter(r => filteredRowsIds.includes(r.id));
    }, [filteredRowsIds, summaryData.rows]);

    const filteredData = useMemo(() => {
        const data = {
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: [],
                    borderColor: [],
                    borderWidth: 1
                }
            ]
        };
        filteredSummaryRows.forEach((response, index) => {
            data.labels[index] = response.value;
            data.datasets[0].data[index] = response.occurrences;
            data.datasets[0].backgroundColor[index] = `rgba(${DATAGRID_VALUES_COLORS[response.colorIndex]}, 0.5)`;
            data.datasets[0].borderColor[index] = `rgba(${DATAGRID_VALUES_COLORS[response.colorIndex]}, 0.6)`;
        });
        return data;
    }, [filteredSummaryRows]);

    if (renderChartType.id === 'pie') {
        return (
            <Pie
                data={filteredData}
                plugins={[ChartDataLabels]}
                height={400}
                options={{
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            callbacks: {
                                footer: (item) => {
                                    return item.length ? filteredSummaryRows[item[0].dataIndex].percentage : null;
                                }
                            }
                        },
                        datalabels: {
                            backgroundColor: function(context) {
                                return context.dataset.borderColor;
                            },
                            borderColor: 'white',
                            borderRadius: 25,
                            borderWidth: 2,
                            padding: 6,
                            formatter: function(value, context) {
                                if (filteredData.datasets[0].data.length > 25) {
                                    return null;
                                }
                                return filteredSummaryRows[context.dataIndex].percentage;
                            },
                            color: '#fff',
                            font: {
                                family: Theme.typography.fontFamily,
                                weight: 'bold',
                                size: filteredRowsIds.length <= 5 ? '17px' : '14px'
                            }
                        }
                    }
                }} />
        );
    }

    return (
        <Bar
            data={filteredData}
            plugins={[ChartDataLabels]}
            height={400}
            options={{
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            footer: (item) => {
                                return item.length ? filteredSummaryRows[item[0].dataIndex].percentage : null;
                            }
                        }
                    },
                    datalabels: {
                        borderColor: '#DDD',
                        borderRadius: 25,
                        borderWidth: 2,
                        padding: 6,

                        formatter: function(value, context) {
                            if (filteredData.datasets[0].data.length > 25) {
                                return null;
                            }
                            return filteredSummaryRows[context.dataIndex].percentage;
                        },
                        color: '#000',
                        font: {
                            family: Theme.typography.fontFamily,
                            weight: 'bold',
                            size: filteredSummaryRows.length <= 5 ? '17px' : '14px'
                        },
                        align: 'end',
                        anchor: 'end',
                    }
                },
                layout: {
                    padding: {
                        top: 42
                    }
                },
                scales: {
                    xAxis: {
                        ticks: {
                            display: filteredData.datasets[0].data.length <= 15
                        }
                    }
                }
            }} />
    );
}

function generateQuestionSummaryData(question, chartTypes) {
    let incrementalId = 0;
    let incrementalColorIndex = 0;
    const rows = question.aggregatedResponses.map(response => {
        if (incrementalColorIndex > DATAGRID_VALUES_COLORS.length - 1) {
            incrementalColorIndex = 0;
        }
        return {
            id: ++incrementalId,
            value: response.value,
            occurrences: response.occurrences,
            ratio: response.occurrences / question.totalResponses,
            percentage: parseFloat(response.occurrences / question.totalResponses * 100).toFixed(1) + '%',
            colorIndex: incrementalColorIndex++
        };
    });

    rows.sort((a, b) => b.ratio - a.ratio);

    return {
        rows: rows,
        chart: (() => {
            let types = [chartTypes.pie, chartTypes.bar];
            let defaultType = rows.length > 3 ? chartTypes.bar : chartTypes.pie;
            return {
                types: types,
                defaultType: defaultType,
            };
        })()
    };
}

const DATAGRID_VALUES_COLORS = [
    '255,99,132',
    '54,162,235',
    '220,220,35',
    '75,192,192',
    '153,102,255',
    '255,159,64',
    '92,255,7',
    '73,73,30',
    '255,30,230',
    '77,64,255',
    '100,100,100',
];

function generateDataGridRowsColors(ifSelected) {
    let classes = {};
    for (let k in DATAGRID_VALUES_COLORS) {
        classes = {...classes,
            border: 0,
            [`& .MuiDataGrid-row.datagrid-colors--${k}` + (ifSelected ? '.Mui-selected' : '')]: {
                backgroundColor: `rgba(${DATAGRID_VALUES_COLORS[k]}, 0.09)`,
            },
            '& .MuiCheckbox-root svg': {
                width: 16,
                height: 16,
                backgroundColor: 'transparent',
                border: '1px solid #d9d9d9',
                borderRadius: 2
            },
            '& .MuiCheckbox-root svg path': {
                color: '#fff',
                position: 'relative',
                top: '-10px',
                left: '-10px',
                width: '20px',
                height: '20px',
            },
            [`& .datagrid-colors--${k} .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg`]: {
                backgroundColor: `rgba(${DATAGRID_VALUES_COLORS[k]}, 0.7)`,
                borderColor: `rgba(${DATAGRID_VALUES_COLORS[k]}, 0.9)`,
            },
            '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
                position: 'absolute',
                display: 'table',
                border: '2px solid #fff',
                borderTop: 0,
                borderLeft: 0,
                transform: 'rotate(45deg) translate(-50%,-50%)',
                opacity: 1,
                transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
                content: '""',
                top: '50%',
                left: '39%',
                width: 5.71428571,
                height: 9.14285714,
            },
            '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
                width: 8,
                height: 8,
                backgroundColor: '#1890ff',
                transform: 'none',
                top: '39%',
                border: 0,
            }
        };
    }
    return classes;
}

// noinspection JSUnusedLocalSymbols
const useColoredDataGridStyles = makeStyles((theme) =>
    createStyles({
        root: {
            ...generateDataGridRowsColors(false)
        }
    })
);

// noinspection JSUnusedLocalSymbols
const useColoredIfSelectedDataGridStyles = makeStyles((theme) =>
    createStyles({
        root: {
            ...generateDataGridRowsColors(true)
        }
    })
);