
export function computeElementWidth(textContent, styles) {
    const div = document.createElement('div');
    div.textContent = textContent;
    div.style.position = 'fixed';
    div.style.left = '-999px';
    div.style.top = '-999px';
    div.style.zIndex = '0';
    div.style.visibility = 'hidden';
    for (let k in styles) {
        div.style[k] = styles[k];
    }
    document.body.append(div);
    const textWidth = div.offsetWidth;
    div.remove();
    return textWidth;
}
