import {Link, NavLink} from "react-router-dom";
import AppConsts from "./AppConsts";
import React, {useContext, useState} from "react";
import "./Header.scss"
import {PathsMap} from "./PageRouting";
import {Avatar, Menu, MenuItem, Paper, Tooltip} from "@mui/material";
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import {UserContext} from "../user-auth/UserProvider";
import {useHistory} from "react-router";
import {stringAvatar, stringToAvatarColor} from "../utils/avatar";
import {t} from "i18next";
import {AppGlobalContext} from "./AppGlobals";

export const HEADER_HEIGHT = '57px';

function Header() {
    return (
        <header className="app-header">
            <Paper elevation={2} className="app-header__fixed" square sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <div className="app-header__content">
                    <div className="app-header-logo">
                        <Link to={PathsMap.HOME} className="app-header-logo__link">
                            <MonitorHeartIcon color="error" className="app-header-logo__icon" />
                            <span className="app-header-logo__text">{AppConsts.NAME}</span>
                        </Link>
                    </div>
                    <nav className="app-header-menu">
                        <UserContext.Consumer>
                            {(user) => <UserInfo user={user} />}
                        </UserContext.Consumer>
                    </nav>
                </div>
            </Paper>
        </header>
    );
}

function UserInfo({user}) {
    const history = useHistory();

    const [menuAnchor, setMenuAnchor] = useState(null);
    const menuOpen = Boolean(menuAnchor);
    const handleOpenMenu = (e) => {
        setMenuAnchor(e.target);
    };
    const handleCloseMenu = () => {
        setMenuAnchor(null);
    };

    const handleSignout = () => {
        handleCloseMenu();
        history.push(PathsMap.SIGN_OUT);
    };

    const handleGotoSettings = () => {
        handleCloseMenu();
        history.push(PathsMap.USER_SETTINGS);
    };

    const {categories} = useContext(AppGlobalContext);

    if (!user.logged) {
        return (
            <ul className="app-header-menu__ul">
                <li className="app-header-menu__li">
                    <NavLink to={PathsMap.SIGN_UP} className="app-header-menu_link" activeClassName="app-header-menu_link--active">
                        {t('sign_up.sign_up_button')}
                    </NavLink>
                </li>
            </ul>
        );
    }

    const tooltip =
        <span style={{ whiteSpace: 'pre-line' }}>
            {user.data.name} ({user.data.email})
            {user.data.roles.length > 0 && `\n${t('user.roles')}: `
                + user.data.roles.map(r => t('user.role_names.' + r)).join(", ")}
            {user.data.categoryIds.length > 0 && `\n${t('user.categories')}: `
                + user.data.categoryIds.map(id => categories.find(c => c.id === id)?.name || '?').join(", ")}
        </span>;

    return (
        <ul className="app-header-menu__ul">
            <li className="app-header-menu__li">
                <Tooltip title={tooltip}>
                    <Avatar sx={{
                        bgcolor: stringToAvatarColor(user.data.name),
                        width: 32,
                        height: 32,
                        fontSize: 14,
                        cursor: 'pointer',
                        textTransform: 'uppercase'
                    }} onClick={handleOpenMenu}>
                        {stringAvatar(user.data.name)}
                    </Avatar>
                </Tooltip>
                <Menu
                    anchorEl={menuAnchor}
                    disableScrollLock={true}
                    open={menuOpen}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClose={handleCloseMenu}>
                    <MenuItem onClick={handleGotoSettings}>{t('user_settings.settings_title')}</MenuItem>
                    <MenuItem onClick={handleSignout}>{t('sign_out.sign_out_button')}</MenuItem>
                </Menu>
            </li>
        </ul>
    );
}


export default Header;