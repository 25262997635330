import {useHistory} from "react-router";
import React, {useMemo, useState} from "react";
import {PathGenerator} from "../../app/PageRouting";
import {
    Badge,
    Divider,
    Fab,
    Menu,
    Stack,
    Switch,
    Tooltip,
    Typography
} from "@mui/material";
import {
    AddOutlined,
    CallSplitOutlined,
    DeleteOutlined,
    MoreHorizOutlined, PeopleAltOutlined, AssignmentOutlined, SaveOutlined, ShareOutlined,
    VisibilityOutlined
} from "@mui/icons-material";
import {MenuItemAdorned} from "../../components/MenuItemAdorned";
import {useFormShareDialog} from "../../form-dialogs/FormShareDialog";
import {useEditOpenState} from "./editOpenState";
import {useFormEditorPathsDialog} from "./FormEditorPathsDialog";
import {useFormDeleteDialog} from "../../form-dialogs/FormDeleteDialog";
import {useQuizSettingsDialog} from "./FormEditorQuizDialog";
import {useFormEditorCollaboratorsDialog} from "./FormEditorCollaboratorsDialog";
import {t} from "i18next";

export function FormEditorMenu(props) {
    const form = props.form;
    const sendSaveRequest = props.sendSaveRequest;
    const saveDisabled = props.saveDisabled;
    const managers = props.managers;
    const errors = props.errors;
    const errorsManager = props.errorsManager;

    // Tools menu
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const history = useHistory();

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleSaveTestForm = () => {
        if (saveDisabled) {
            history.push(PathGenerator.createFormResponse(form.id));
        } else {
            sendSaveRequest()
                .then(hasErrors => {
                    if (!hasErrors) {
                        history.push(PathGenerator.createFormResponse(form.id));
                    }
                })
                .catch(() => {
                });
        }
    };

    const handleSaveButton = (e) => {
        sendSaveRequest().catch(() => {
        });
        e.preventDefault();
    };

    // Dialogs
    const [openShareDialog] = useFormShareDialog(form);
    const [openPathsDialog] = useFormEditorPathsDialog(form);
    const [deleteDialog, openDeleteDialog] = useFormDeleteDialog(form, () => {
        managers.form.resetDirty();
    });
    const [quizDialog, openQuizDialog] = useQuizSettingsDialog(form, managers, errors, errorsManager);

    const [collaboratorsDialog, openCollaboratorsDialog] = useFormEditorCollaboratorsDialog(form, managers, errors);
    const collaboratorsTotalErrors = errorsManager.countErrors("permissions");

    const quizTotalErrors = useMemo(() =>
        errorsManager.countErrors("topics")
        + errorsManager.countErrors("questionsQuiz"), [errorsManager]);

    const questionsTotalErrors = useMemo(() =>
        errorsManager.countErrors("questions"), [errorsManager]);

    const menuTotalErrors = collaboratorsTotalErrors;

    // Open state
    const editOpenState = useEditOpenState(form.id, (open) => managers.form.editOpen(open, true));

    return (
        <>
            <Stack spacing={2} direction="row" sx={{
                position: 'fixed',
                bottom: 24,
                right: 24,
            }}>
                <Tooltip title={t('form_editor.add_question_n', {n: form.questions.length + 1})} arrow>
                    <Fab onClick={managers.form.addQuestion}>
                        <Badge badgeContent={questionsTotalErrors} color={questionsTotalErrors > 0 ? "error" : "primary"}>
                            <AddOutlined/>
                        </Badge>
                    </Fab>
                </Tooltip>
                <Tooltip title={t('form_editor.edit_score_quiz')} arrow>
                    <Fab onClick={openQuizDialog}>
                        <Badge badgeContent={quizTotalErrors} color={quizTotalErrors > 0 ? "error" : "primary"}>
                            <AssignmentOutlined/>
                        </Badge>
                    </Fab>
                </Tooltip>
                <Fab onClick={handleOpenMenu}>
                    <Badge badgeContent={menuTotalErrors} color={menuTotalErrors > 0 ? "error" : "primary"}>
                        <MoreHorizOutlined/>
                    </Badge>
                </Fab>
                <Tooltip title={saveDisabled ? t('common.saved') : t('common.save')} arrow>
                    <span>
                        <Fab color="primary" aria-label="Save" onClick={handleSaveButton} disabled={saveDisabled}>
                            <SaveOutlined/>
                        </Fab>
                    </span>
                </Tooltip>
            </Stack>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                disableScrollLock={true}
                transformOrigin={{horizontal: 'right', vertical: 'bottom'}}
                anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                slotProps={{
                    paper: {
                        style: {
                            minWidth: 330
                        }
                    }
                }}>
                <MenuItemAdorned startAdornment={<Switch checked={form.open} size="small" />} MenuItemProps={{
                    onClick: () => editOpenState(!form.open)
                }}>
                    <Typography color={form.open ? 'text.primary' : 'error'}>
                        {form.open ? t('form.accepting_responses') : t('form.rejecting_responses')}
                    </Typography>
                </MenuItemAdorned>
                <Divider />
                <MenuItemAdorned startIcon={ShareOutlined} MenuItemProps={{
                    onClick: () => {
                        openShareDialog();
                        handleCloseMenu();
                    },
                    disabled: !saveDisabled
                }}>
                    {t('form.share_form')}
                </MenuItemAdorned>
                <MenuItemAdorned startIcon={DeleteOutlined} MenuItemProps={{
                    onClick: () => {
                        openDeleteDialog();
                        handleCloseMenu();
                    }
                }}>
                    {t('form.delete_form')}
                </MenuItemAdorned>
                <MenuItemAdorned startIcon={PeopleAltOutlined} startIconErrors={collaboratorsTotalErrors} MenuItemProps={{
                    onClick: () => {
                        openCollaboratorsDialog();
                        handleCloseMenu();
                    }
                }}>
                    {t('form_editor.edit_collaborators')} ({form.permissions.length - 1})
                </MenuItemAdorned>
                <Divider />
                <MenuItemAdorned startIcon={CallSplitOutlined} MenuItemProps={{
                    onClick: () => {
                        openPathsDialog();
                        handleCloseMenu();
                    }
                }}>
                    {t('form_editor.response_paths_graph')}
                </MenuItemAdorned>
                <MenuItemAdorned startIcon={VisibilityOutlined} MenuItemProps={{
                    onClick: handleSaveTestForm
                }}>
                    {saveDisabled ? t('form_editor.test_form') : t('form_editor.save_and_test_form')}
                </MenuItemAdorned>
            </Menu>
            {quizDialog}
            {deleteDialog}
            {collaboratorsDialog}
        </>
    );
}