import rehypeKatex from "rehype-katex";
import rehypeExternalLinks from "rehype-external-links";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import ReactMarkdown from "react-markdown";
import React from "react";
import 'katex/dist/katex.min.css'
import './embedded-markdown.css'

export function EmbeddedMarkdown({content}) {
    return <ReactMarkdown
        children={content}
        rehypePlugins={[rehypeKatex, [rehypeExternalLinks, {target: '_blank'}]]}
        remarkPlugins={[remarkGfm, remarkMath]}
        className="embedded-markdown" />;
}