import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {FormEditorValidation} from "./FormEditorValidation";
import {Alert, Box, Chip, Grid, IconButton, Paper, Stack, Tabs, TextField, Typography} from "@mui/material";
import {
    ArrowDownwardOutlined,
    ArrowUpwardOutlined,
    DeleteOutlined, EditOutlined,
    ExitToAppOutlined, SpellcheckOutlined
} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {debounce} from "../../utils/debounce";
import {TAB_ERROR_STYLE, ICON_STYLES, Theme} from "../../app/Theme";
import {QUESTION} from "../../utils/formUtils";
import Tab from "@mui/material/Tab";
import {FormEditorExitConditions} from "./FormEditorExitConditions";
import {FormEditorType} from "./FormEditorType";
import {useMdEditorDialog} from "../../components/MdEditorDialog";
import {t} from "i18next";

export function FormEditorQuestion(props) {
    const question = props.question;
    const managers = props.managers;
    const errorsManager = props.errorsManager;
    const maxPosition = props.maxPosition;
    const errors = props.errors;
    const legacy = props.legacy;

    const questionsManager = managers.questions;
    const errorsPrefix = `questions[${question.ref}]`;

    const questionHasErrors = useMemo(() =>
        errorsManager.hasErrors(errorsPrefix), [errorsManager, errorsPrefix]);
    const exitConditionsHaveErrors = useMemo(() =>
        errorsManager.hasErrors(errorsPrefix + ".exitConditions"), [errorsManager, errorsPrefix]);
    const constraintsHaveErrors = useMemo(() =>
        errorsManager.hasErrors(errorsPrefix + ".constraints"), [errorsManager, errorsPrefix]);

    // Type question prop
    const typeQuestionProp = useMemo(() => ({
        ref: question.ref,
        type: question.type,
        choices: question.choices
    }), [question.ref, question.type, question.choices]);

    // Exit condition question prop
    const exitConditionQuestionProp = useMemo(() => ({
        ref: question.ref,
        type: question.type,
        choices: question.choices,
        defaultExitRef: question.defaultExitRef,
        exitConditions: question.exitConditions,
        possibleExitRefs: question.possibleExitRefs,
        position: question.position
    }), [
        question.ref, question.choices, question.defaultExitRef,
        question.exitConditions, question.position,
        question.possibleExitRefs, question.type]);

    // Constraints question prop
    const constraintsQuestionProp = useMemo(() => ({
        ref: question.ref,
        type: question.type,
        choices: question.choices,
        constraints: question.constraints
    }), [question.ref, question.type, question.choices, question.constraints]);

    // Tabs
    const getTabsList = useCallback((possibleExitRefs) => {
        const values = [];
        if (possibleExitRefs.length > 0) {
            values.push('exitConditions');
        }
        values.push('validation');
        let hash = values.join('-');
        return {values, hash};
    }, []);

    const [tabs, setTabs] = useState(() => {
        const tabsList = getTabsList(question.possibleExitRefs);
        return {
            hash: tabsList.hash,
            list: tabsList.values,
            active: tabsList.values[0]
        };
    });

    useEffect(() => {
        const tabsList = getTabsList(question.possibleExitRefs);
        setTabs(tabs => {
            if (tabsList.hash === tabs.hash) {
                return tabs;
            }
            return {
                ...tabs,
                hash: tabsList.hash,
                list: tabsList.values,
                active: tabsList.values.includes(tabs.active) ? tabs.active : tabsList.values[0]
            };
        });
    }, [getTabsList, question.possibleExitRefs]);

    const handleTabChange = useCallback((event, newTab) => {
        setTabs(tabs => ({...tabs, active: newTab}));
    }, []);

    const showExitConditions = useMemo(() => tabs.list.includes('exitConditions'), [tabs]);

    return (
        <Paper
            variant={questionHasErrors ? "elevation" : "outlined"}
            elevation={questionHasErrors ? 12 : 0}
            sx={{mt: 3, pt: 4, pb: 1, px: 0}}>
            <Box sx={{px: 3, mb: 2}}>
                {(errors?.[errorsPrefix] || []).map((error, errorKey) =>
                    <Alert severity="error" key={errorKey} sx={{mb: 3}}>{error}</Alert>)}
                <Grid container sx={{width: '100%'}} justifyContent="space-between">
                    <Grid item>
                        <Box>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Chip variant="filled" color="primary" label={question.position} sx={{fontWeight: 500, fontSize: 18}} />
                                <Typography variant="body" color="text.secondary">/</Typography>
                                <Typography variant="body" color="text.secondary">{maxPosition}</Typography>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item>
                        {question.position > 1 &&
                        <IconButton sx={{ml: 2}} aria-label={t('common.move_up')}
                                    onClick={() => questionsManager.move(question.ref, 'up')}>
                            <ArrowUpwardOutlined/>
                        </IconButton>}
                        {question.position < maxPosition &&
                        <IconButton sx={{ml: 2}} aria-label={t('common.move_down')}
                                    onClick={() => questionsManager.move(question.ref, 'down')}>
                            <ArrowDownwardOutlined/>
                        </IconButton>}
                        <Button startIcon={<DeleteOutlined/>} color="error"
                                onClick={() => questionsManager.delete(question.ref)}
                                sx={{ml: 2}}>
                            {t('common.delete')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{px: 3}}>
                <FormEditorQuestionTitleDescription
                    question={question}
                    questionsManager={questionsManager}
                    errors={errors}
                    errorsPrefix={errorsPrefix}
                    errorsManager={errorsManager}
                />
            </Box>
            <Box sx={{px: 3, mt: 4}}>
                <FormEditorTypeMemo
                    legacy={legacy}
                    question={typeQuestionProp}
                    errorsManager={errorsManager}
                    managers={managers}
                    errorsPrefix={errorsPrefix}
                    errors={errors}/>
            </Box>
            <Box sx={{borderBottom: 1, borderColor: 'divider', px: 3, mt: 3, mb: 1}}>
                <Tabs value={tabs.active} onChange={handleTabChange}>
                    {showExitConditions &&
                    <Tab
                         label={
                             <Stack direction="row" spacing={1} alignItems="center">
                                 <ExitToAppOutlined fontSize="small"/>
                                 <Typography >
                                     {question.exitConditions.length > 0
                                         ? t('question.exit_conditions') + ` (${question.exitConditions.length})`
                                         : t('question.exit_conditions')}
                                 </Typography>
                             </Stack>}
                         value="exitConditions"
                         style={exitConditionsHaveErrors ? TAB_ERROR_STYLE : {}}/>}
                    <Tab label={
                             <Stack direction="row" spacing={1} alignItems="center">
                                 <SpellcheckOutlined fontSize="small"/>
                                 <Typography >
                                     {question.constraints.length > 0
                                         ? t('question.validation') + ` (${question.constraints.length})`
                                         : t('question.validation')}
                                 </Typography>
                             </Stack>}
                         value="validation"
                         style={constraintsHaveErrors ? TAB_ERROR_STYLE : {}}/>
                </Tabs>
            </Box>
            <Box sx={{p: 3}}>
                {showExitConditions &&
                    <div hidden={tabs.active !== "exitConditions"}>
                        <FormEditorExitConditionsMemo
                            formManager={errorsManager}
                            question={exitConditionQuestionProp}
                            legacy={legacy}
                            managers={managers}
                            errorsPrefix={errorsPrefix}
                            errors={errors}/>
                    </div>}
                <div hidden={tabs.active !== "validation"}>
                    <FormEditorValidationMemo
                        legacy={legacy}
                        formManager={errorsManager}
                        question={constraintsQuestionProp}
                        managers={managers}
                        errorsPrefix={errorsPrefix}
                        errors={errors} />
                </div>
            </Box>
        </Paper>
    );
}

function FormEditorQuestionTitleDescription({question, questionsManager, errors, errorsPrefix, errorsManager}) {
    const descriptionRef = useRef(null);
    const [descriptionDialogComponent, openDescriptionDialog] = useMdEditorDialog({
        title: t('question.description'),
        content: () => descriptionRef.current?.value,
        onChange: (value) => {
            descriptionRef.current.value = value;
        },
        onChangeDebounced: (value) => {
            errorsManager.resetErrors(`${errorsPrefix}.description`);
            questionsManager.editDescription(question.ref, value);
        }
    });

    return (<>
        <TextField
            error={!!errors?.[`${errorsPrefix}.title`]}
            helperText={errors?.[`${errorsPrefix}.title`]?.join(' - ')}
            defaultValue={question.title}
            variant="standard"
            label={t('question.title')}
            onChange={(e) => {
                errorsManager.resetErrors(`${errorsPrefix}.title`);
                debounce(e, () => questionsManager.editTitle(question.ref, e.target.value));
            }}
            inputProps={{
                style: {fontSize: Theme.typography.h2.fontSize},
                maxLength: QUESTION.TITLE_MAX_LENGTH
            }}
            sx={{mb: 3}}
            fullWidth/>
        <TextField
            defaultValue={question.description}
            inputRef={descriptionRef}
            error={!!errors?.[`${errorsPrefix}.description`]}
            helperText={errors?.[`${errorsPrefix}.description`]?.join(' - ')}
            variant="standard"
            label={t('question.description')}
            onChange={(e) => {
                errorsManager.resetErrors(`${errorsPrefix}.description`);
                debounce(e, () => questionsManager.editDescription(question.ref, e.target.value));
            }}
            InputProps={{
                startAdornment:
                    <IconButton
                        onClick={() => {
                            openDescriptionDialog();
                        }}
                        onMouseDown={(e) => {
                            e.preventDefault();
                        }}
                        sx={{mr: 1}}>
                        <EditOutlined sx={ICON_STYLES.icon16} color="primary" />
                   </IconButton>
            }}
            maxRows={4}
            inputProps={{maxLength: QUESTION.DESCRIPTION_MAX_LENGTH}}
            multiline
            fullWidth />
        {descriptionDialogComponent}
    </>);
}

const FormEditorValidationMemo = React.memo(FormEditorValidation);
const FormEditorExitConditionsMemo = React.memo(FormEditorExitConditions);
const FormEditorTypeMemo = React.memo(FormEditorType);

