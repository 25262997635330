import {useCallback, useMemo, useState} from "react";

export function getDefaultErrorsMapper(fields) {
    return ({details, message}) => ({
        key: 'field' in details && fields.includes(details.field) ? details.field : 'main',
        message: message
    });
}

export function useErrors(options = {}) {
    const [state, setState] = useState({});

    const mapper = useMemo(() => typeof options.mapper === 'function'
        ? options.mapper
        : () => 'main', [options]);

    const setErrors = useCallback((errors) => {
        setState((_state) => {
            const newErrorsState = {};
            errors.forEach(e => {
                const {key, message} = e.clientError ? e : mapper(e);
                if (newErrorsState.hasOwnProperty(key)) {
                    newErrorsState[key].push(message);
                } else {
                    newErrorsState[key] = [message];
                }
            });
            if (Object.keys(_state).length === 0
                && Object.keys(newErrorsState).length === 0) {
                return _state;
            }
            return newErrorsState;
        });
    }, [setState, mapper]);

    const resetErrors = useCallback((selector = undefined) => {
        if (typeof selector === 'string') {
            setState(_state => {
                if (selector in _state) {
                    _state = {..._state};
                    delete _state[selector];
                }
                return _state;
            });
        } else {
            setState((_state) => {
                return Object.keys(_state).length === 0 ? _state : {};
            });
        }
    }, []);

    const countErrors = useCallback((selector) => {
        if (selector in state) {
            return 1;
        }
        let count = 0;
        for (let key in state) {
            if (key.startsWith(selector + '.') || key.startsWith(selector + '[')) {
                count++;
            }
        }
        return count;
    }, [state]);

    const hasErrors = useCallback((selector) => {
        return countErrors(selector) > 0;
    }, [countErrors]);

    return {
        errors: state,
        countErrors,
        setErrors,
        resetErrors,
        hasErrors
    };
}