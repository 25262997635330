import {Container} from "@mui/material";
import React, {useEffect} from "react";
import AppConsts from "./AppConsts";
import Box from "@mui/material/Box";
import Header from "./Header";

export function Page(props) {
    const title = typeof props.title === 'string' ? props.title : "";

    useEffect(() => {
        document.title = title.length
            ? title + " | " + AppConsts.NAME
            : AppConsts.NAME;
    }, [title]);

    const showHeader = !('noHeader' in props) || !props.noHeader;

    let content = (
        <>
            {showHeader && <Header />}
            {props.children}
        </>
    );

    if (!('noContainer' in props) || !props.noContainer) {
        const maxWidth = 'maxWidth' in props ? props.maxWidth : 'lg';

        content =
            <Container sx={{py: {sm: 2, lg: 4}}} maxWidth={maxWidth}>
                {content}
            </Container>
    }

    if ('centered' in props && props.centered) {
        content =
            <Box sx={{flexGrow: true, display: 'flex', alignItems:' center', minHeight: "100vh"}}>
                <Box sx={{width: 1}}>
                    {content}
                </Box>
            </Box>
    }

    return content;
}