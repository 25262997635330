import {Dialog, Grow} from "@mui/material";
import React, {useCallback, useMemo, useState} from "react";

export function DialogWrapper(options = {}) {
    const [open, setOpen] = useState(false);
    const setOpened = useCallback(() => setOpen(true), []);
    const setClosed = useCallback(() => setOpen(false), []);

    const DialogComponent = options.component;
    const dialog = useMemo(() =>
        <Dialog
            open={open}
            onClose={setClosed}
            scroll="body"
            TransitionComponent={Grow}
            maxWidth={options.maxWidth || 'sm'}
            fullWidth={options.fullWidth || true}
            sx={{
                '& .MuiDialog-scrollBody': {
                    overflowY: 'scroll',
                }
            }}>
            <DialogComponent onClose={setClosed} {...options.componentProps} />
        </Dialog>,
        [open, setClosed, options.maxWidth, options.fullWidth, options.componentProps]);
    
    return [dialog, setOpened, setClosed];
}