import {UrlGenerator} from "../app/PageRouting";
import React from "react";
import {useDialog} from "../providers/DialogProvider";
import {DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography} from "@mui/material";
import {ArrowBackOutlined} from "@mui/icons-material";
import {ShareButtons} from "../components/ShareButtons";
import LoadingButton from "@mui/lab/LoadingButton";

function FormShareDialog({form}) {
    const {closeDialog} = useDialog();
    const url = UrlGenerator.createFormResponse(form.id);

    return <>
        <DialogTitle>
            <Stack direction="row" alignItems="center">
                <IconButton onClick={closeDialog} sx={{mr: 1}}>
                    <ArrowBackOutlined/>
                </IconButton>
                Share
            </Stack>
        </DialogTitle>
        <DialogContent>
            <Typography variant="p" component="p" sx={{mb: 3}}>
                Share {form.title} on…
            </Typography>
            <ShareButtons url={url} />
        </DialogContent>
        <DialogActions>
            <LoadingButton onClick={closeDialog} color="neutral">Close</LoadingButton>
        </DialogActions>
    </>;
}

export function useFormShareDialog(form) {
    const {openDialog, closeDialog} = useDialog();
    const open = () => {
        openDialog({
            children: <FormShareDialog form={form} />,
            maxWidth: 'sm',
            fullWidth: true
        });
    };
    return [open, closeDialog];
}