import { useEffect, useRef } from 'react';

const useBeforeUnload = (handler) => {
    const eventListenerRef = useRef();

    useEffect(() => {
        eventListenerRef.current = (event) => {
            const returnValue = handler();

            if (typeof returnValue === 'string') {
                event.preventDefault();

                // Handle legacy `event.returnValue` property
                // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
                return (event.returnValue = returnValue);
            }
        };
    }, [handler]);

    useEffect(() => {
        const eventListener = (event) => {
            eventListenerRef.current(event);
        };
        window.addEventListener('beforeunload', eventListener);
        return () => {
            window.removeEventListener('beforeunload', eventListener);
        };
    }, []);
};

export default useBeforeUnload;