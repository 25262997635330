import {Autocomplete, CircularProgress, TextField} from "@mui/material";
import {t} from "i18next";
import React, {useContext, useEffect, useRef, useState} from "react";
import {autocompleteStyles} from "../app/Theme";
import {httpClient} from "../utils/httpClient";
import AppConsts from "../app/AppConsts";
import {logError} from "../utils/logging";
import {AppGlobalContext} from "../app/AppGlobals";

export function useInitialLocations() {
    let [locations, setLocations] = useState([]);

    useEffect(() => {
        httpClient.get(`${AppConsts.API_PATH}/locations/search`).then(response => {
            if (response.data.locations) {
                setLocations(response.data.locations.map(loc => ({
                    id: loc.id,
                    name: loc.name,
                    abbr: loc.abbr
                })));
            }
        }).catch((e) => {
            logError(e);
        });
    }, []);

    return {locations};
}

export function LocationsAutocomplete({ value, onChange, label = null, helperText = null, error = false }) {
    const classes = autocompleteStyles();
    const {locations} = useContext(AppGlobalContext);
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [loading, setLoading] = useState(false);
    const xhrTimeout = useRef(null);

    useEffect(() => {
        setFilteredLocations(locations ?? []);
    }, [locations]);

    const searchLocations = (e) => {
        clearTimeout(xhrTimeout.current);
        setLoading(true);
        xhrTimeout.current = setTimeout(() => {
            httpClient.get(`${AppConsts.API_PATH}/locations/search`, {
                params: {
                    query: e ? e.target.value : ""
                }
            }).then(response => {
                setLoading(false);
                if (response.data.locations) {
                    setFilteredLocations(response.data.locations.map(loc => ({
                        id: loc.id,
                        name: loc.name,
                        abbr: loc.abbr
                    })));
                }
            }).catch((e) => {
                setLoading(false);
                logError(e);
            });
        }, 350);
    };

    return <Autocomplete
        disablePortal
        options={filteredLocations}
        value={value}
        onChange={(e, value) => {
            if (!value) {
                setFilteredLocations(locations);
            }
            onChange(value);
        }}
        getOptionLabel={(option) => option.name + " (" + option.abbr + ")"}
        classes={{ paper: classes.paper }}
        filterOptions={(x) => x}
        openOnFocus={true}
        noOptionsText={t('common.no_results')}
        renderInput={(params) => (
            <TextField
                {...params}
                label={label}
                placeholder={t('location.name')}
                onChange={(e)=> searchLocations(e)}
                error={error}
                helperText={helperText}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: loading
                        ? <CircularProgress color="inherit" size={20} /> : params.InputProps?.endAdornment
                }}
            />
        )}
    />
}