import React, {useEffect, useRef} from 'react';
import {DialogContent, DialogTitle, IconButton, Stack} from "@mui/material";
import {useDialog} from "../../providers/DialogProvider";
import {ArrowBackOutlined} from "@mui/icons-material";
import {geConditionsDescription, QUESTION} from "../../utils/formUtils";
import {Alert} from "@mui/lab";
import {computeElementWidth} from "../../utils/element";
import {lowercaseFirstLetter, truncateString} from "../../utils/strings";
import {Theme} from "../../app/Theme";
import cytoscape from "cytoscape";
import dagre from 'cytoscape-dagre';
import nodeHtmlLabel from 'cytoscape-node-html-label';
import {t} from "i18next";

cytoscape.use(dagre);
cytoscape.use(nodeHtmlLabel);
cytoscape.warnings(false);

export function useFormEditorPathsDialog(form) {
    const {openDialog, closeDialog} = useDialog();
    const open = () => {
        openDialog({
            children: <FormEditorPathsDialog form={form} />,
            maxWidth: 'md',
            fullWidth: true
        });
    };
    return [open, closeDialog];
}

function FormEditorPathsDialog({form}) {
    const {closeDialog} = useDialog();
    const elements = generateGraphElements(form.questions);

    return <>
        <DialogTitle>
            <Stack direction="row" alignItems="center">
                <IconButton onClick={closeDialog} sx={{mr: 1}}>
                    <ArrowBackOutlined/>
                </IconButton>
                {t('form_editor.response_paths_graph')}
            </Stack>
        </DialogTitle>
        {form.questions.length
            ? <PathsGraph elements={elements} />
            : <DialogContent>
                <Alert severity="warning">Form is empty, add at least one question to generate a path graph.</Alert>
            </DialogContent>}
    </>;
}

function PathsGraph({elements}) {
    const cytoscapeContainer = useRef(null);

    useEffect(() => {
        if (!cytoscapeContainer.current) {
            return;
        }

        let cy = cytoscape({
            container: cytoscapeContainer.current,
            elements: elements,
            style: GRAPH_STYLE,
            wheelSensitivity: 0.2,
            minZoom: 0.2,
            maxZoom: 1.5
        });

        // noinspection JSUnresolvedFunction
        cy.nodeHtmlLabel([
            graphPositionLabelStyle('.withPosition.unconnected', Theme.palette.error.light),
            graphPositionLabelStyle('.withPosition.connected', Theme.palette.primary.light),
            graphPositionLabelStyle('.withPosition.first', Theme.palette.primary.light)
        ]);

        (() => {
            let offset = 0;
            function animateEdges() {
                if (offset >= 100) {
                    offset = 0;
                }
                cy.edges().animate({
                    style: {
                        lineDashOffset: --offset
                    },
                    duration: 500,
                    complete: animateEdges
                });
            }
            animateEdges();
        })();

        cy.layout({
            name: 'dagre',
            nodeSep: 150,
            edgeSep: 150,
            rankSep: 170,
            rankDir: 'TB',
        }).run();
    }, [cytoscapeContainer, elements]);

    // noinspection CheckTagEmptyBody
    return <div ref={cytoscapeContainer} style={{height: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 150}}></div>
}

const generateGraphElements = (questions) => {
    const elements = [];
    const targetCounterOf = {};

    elements.push({
        data: {
            id: 'end',
            name: t('form_editor.end')
        },
        classes: ['end']
    });
    targetCounterOf['end'] = 0;

    const generateExitEdge = (questions, question, exitRef) => {
        let sourceRef = 'q-' + question.ref;
        let targetRef;

        let nextQuestion;
        if (exitRef === QUESTION.REF.NEXT) {
            nextQuestion = questions.find(q2 => q2.position === question.position + 1);
        } else if (exitRef !== QUESTION.REF.END) {
            nextQuestion = questions.find(q2 => q2.ref === exitRef);
        }
        targetRef = nextQuestion ? 'q-' + nextQuestion.ref : 'end';

        if (targetRef in targetCounterOf) {
            targetCounterOf[targetRef]++;
        } else {
            targetCounterOf[targetRef] = 1;
        }

        return {
            data: {
                source: sourceRef,
                target: targetRef
            }
        };
    };

    questions.forEach(question => {
        const nodeId = 'q-' + question.ref;

        elements.push({
            data: {
                id: nodeId,
                name: question.title.length ? truncateString(question.title, 30) : 'Untitled',
                position: question.position,
            },
            classes: ['withPosition']
        });

        if (!(nodeId in targetCounterOf)) {
            targetCounterOf[nodeId] = 0;
        }

        const edge = generateExitEdge(questions, question, question.defaultExitRef);
        edge.data.label = lowercaseFirstLetter(question.exitConditions.length
            ? t('form_editor.otherwise_go_to')
            : t('form_editor.go_always_to'));
        elements.push(edge);

        question.exitConditions.forEach(exitCondition => {
            const edge = generateExitEdge(questions, question, exitCondition.exitRef);
            edge.data.label = truncateString(geConditionsDescription(question.type, exitCondition.matchConditions), 50);
            elements.push(edge);
        });
    });

    for (let id in targetCounterOf) {
        let element = elements.find(e => e.data.id === id);
        if (element.data.position === 1) {
            element.classes.push('first');
        } else if (targetCounterOf[id] < 1) {
            element.classes.push('unconnected');
        } else {
            element.classes.push('connected');
        }
    }
    return elements;
};

const GRAPH_STYLE = [
    {
        selector: 'node',
        style: {
            'label': 'data(name)',
            'shape': 'round-rectangle',
            'width': (node) => {
                const textWidth = computeElementWidth(node.data().name, {
                    fontFamily: Theme.typography.fontFamily,
                    fontSize: Theme.typography.fontSize,
                    fontWeight: Theme.typography.fontWeightMedium
                });
                return Math.max(textWidth, 100) + 52 + 'px';
            },
            'height': '42px',
            'font-family': Theme.typography.fontFamily,
            'font-size': Theme.typography.fontSize,
            'font-weight': Theme.typography.fontWeightMedium,
            'text-valign': 'center',
            'text-halign': 'center',
            'text-margin-x': 18,
            'background-color': '#fff',
            'border-width': '1px',
            'border-color': '#a4b6c7',
            'color': '#000',
            'padding': '0 0 0 100px'
        }
    }, {
        selector: 'node.unconnected',
        style: {
            'border-width': 2,
            'border-color': Theme.palette.error.light
        }
    }, {
        selector: 'node.end',
        style: {
            'background-color': Theme.palette.success.light,
            'border-color': Theme.palette.success.dark,
            'color': '#fff',
            'font-weight': 700,
            'shape': 'ellipse',
            'width': '52px',
            'height': '52px',
            'text-margin-x': 0
        }
    }, {
        selector: 'edge',
        style: {
            'width': 2,
            'line-color': '#8a9eb0',
            'line-style': 'dashed',
            'line-dash-pattern': [6, 3],
            'line-dash-offset': 0,
            'curve-style': 'bezier',
            'source-label': 'data(label)',
            'source-text-rotation': 'autorotate',
            'source-text-offset': (edge) => {
                return 16 + computeElementWidth(edge.data().label, {
                    'font-family': Theme.typography.fontFamily,
                    'font-size': '13px',
                    'font-weight': Theme.typography.fontWeightMedium,
                });
            },
            'source-text-margin-x': -12,
            'font-family': Theme.typography.fontFamily,
            'font-size': '13px',
            'font-weight': Theme.typography.fontWeightMedium,
            'color': '#203141',
            'arrow-scale': 1.2,
            'target-arrow-shape': 'triangle',
            'target-arrow-color': '#6a7c8d'
        }
    }
];

const graphPositionLabelStyle = (query, color) => {
    return {
        query: query,
        valign: 'center',
        halign: 'left',
        valignBox: 'center',
        halignBox: 'center',
        tpl: function(data) {
            return '<div style="position:relative;right:-22px;line-height:32px;min-width:32px;box-sizing:border-box;' +
                'font-weight:500;color:#fff;border-radius:6px;background:' + color + ';text-align:center;">' + data.position + '</div>';
        }
    };
};