import React, {useRef, useState} from 'react';
import {
    Alert, Avatar, Box, Checkbox, CircularProgress, Collapse,
    DialogContent,
    DialogTitle, Divider, Fade, IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText, Stack, TextField, Tooltip, Typography
} from "@mui/material";
import {
    AddOutlined,
    ArrowBackOutlined, DeleteOutlined, ExpandLess, ExpandMore
} from "@mui/icons-material";
import {DialogWrapper} from "../../components/DialogWrapper";
import cytoscape from "cytoscape";
import edgehandles from "cytoscape-edgehandles";
import cxtmenu from "cytoscape-cxtmenu";
import {stringAvatar, stringToAvatarColor} from "../../utils/avatar";
import {useUser} from "../../user-auth/UserProvider";
import AppConsts from "../../app/AppConsts";
import {logError} from "../../utils/logging";
import {TransitionGroup} from "react-transition-group";
import {httpClient} from "../../utils/httpClient";
import {t} from "i18next";

cytoscape.use(edgehandles);
cytoscape.use(cxtmenu);
cytoscape.warnings(false);

export function useFormEditorCollaboratorsDialog(form, managers, errors) {
    return DialogWrapper({
        component: FormEditorCollaboratorsDialog,
        componentProps: {form: form, managers: managers, errors: errors},
        maxWidth: 'sm',
        fullWidth: true
    });
}

function FormEditorCollaboratorsDialog({form, managers, onClose, errors}) {
    const user = useUser();

    return <>
        <DialogTitle>
            <Stack direction="row" alignItems="center">
                <IconButton onClick={onClose} sx={{mr: 1}}>
                    <ArrowBackOutlined/>
                </IconButton>
                {t('form_editor.edit_collaborators')}
            </Stack>
        </DialogTitle>
        <DialogContent>
            {(errors?.['permissions'] || []).map((error, errorKey) =>
                <Alert severity="error" key={errorKey} sx={{mb: 1}}>{error}</Alert>)}
            <List dense sx={{width: '100%'}}>
                <TransitionGroup>
                {form.permissions.map(permission =>
                    <Fade in={true} key={permission.user.id}>
                        <Box>
                            <CollaboratorMenuItem
                                you={user.logged && permission.user.id === user.data.id}
                                permission={permission}
                                managers={managers} />
                        </Box>
                    </Fade>)}
                </TransitionGroup>
            </List>
            <Divider sx={{mt: 2, mb: 4}} />
            <ColloratorAdd managers={managers} />
        </DialogContent>
    </>;
}

function CollaboratorMenuItem({permission, you, managers}) {
    const [collapsed, setCollpsed] = useState(true);

    return (<>
    <ListItem
        disablePadding
        onClick={() => setCollpsed(!collapsed)}
        secondaryAction={collapsed ? <ExpandMore /> : <ExpandLess />}
    >
        <ListItemButton>
            <ListItemAvatar>
                <Avatar sx={{
                    bgcolor: stringToAvatarColor(permission.user.name)
                }}>
                    {stringAvatar(permission.user.name)}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={permission.user.name + (you ? " (you)" : "")} secondary={permission.user.email} />
        </ListItemButton>
    </ListItem>
    <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
            <CollaboratorPermissionType
                text={t('form_editor.can_edit_delete_form')}
                onClick={() => managers.permissions.editPermissionType(
                    permission.user.id, 'canEditForm', !permission.canEditForm)}
                checked={permission.canEditForm}
                readOnly={permission.readOnly} />
            <CollaboratorPermissionType
                text={t('form_editor.can_view_responses')}
                onClick={() => managers.permissions.editPermissionType(
                    permission.user.id, 'canViewResponses', !permission.canViewResponses)}
                checked={permission.canViewResponses}
                readOnly={permission.readOnly} />
            <CollaboratorPermissionType
                text={t('form_editor.can_edit_read_status')}
                onClick={() => managers.permissions.editPermissionType(
                    permission.user.id, 'canEditResponses', !permission.canEditResponses)}
                checked={permission.canEditResponses}
                readOnly={permission.readOnly} />
            <CollaboratorPermissionType
                text={t('form_editor.can_delete_responses')}
                onClick={() => managers.permissions.editPermissionType(
                    permission.user.id, 'canDeleteResponses', !permission.canDeleteResponses)}
                checked={permission.canDeleteResponses}
                readOnly={permission.readOnly} />
            <ListItemButton
                disabled={permission.readOnly}
                sx={{ pl: {xs: 0, sm: 7}, py: 0 }}
                onClick={() => {
                    managers.permissions.delete(permission.user.id);
                }}>
                <ListItemIcon>
                    <Box sx={{width: '42px', justifyContent: 'center', py: '10px', display: "flex", alignItems: "center"}}>
                        <DeleteOutlined sx={{fontSize: 24}} />
                    </Box>
                </ListItemIcon>
                <ListItemText primary={t('form_editor.remove_as_collaborator')} />
            </ListItemButton>
        </List>
    </Collapse>
    </>);
}

function CollaboratorPermissionType({text, checked, readOnly, onClick}) {
    return (
    <ListItemButton sx={{ pl: {xs: 0, sm: 7}, py: 0 }} onClick={onClick} disabled={readOnly}>
        <ListItemIcon>
            <Checkbox value={true} disabled={readOnly} checked={checked} />
        </ListItemIcon>
        <ListItemText primary={text} />
    </ListItemButton>
    );
}

function ColloratorAdd({managers}) {
    const user = useUser();
    const [searchValue, setSearchValue] = useState("");
    const [showResults, setShowResults] = React.useState(false);
    const [results, setResults] = React.useState([]);
    const [loading, setLoading] = useState(false);

    const handleAddCollaborator = (user) => {
        managers.permissions.add({
            id: user.id,
            email: user.email,
            name: user.name
        });
        setSearchValue("");
        setResults([]);
        setShowResults(false);
    };

    const xhrTimeout = useRef(null);

    const handleOnChange = (e) => {
        let value = e.target.value;

        clearTimeout(xhrTimeout.current);
        setSearchValue(value);

        if (value === "") {
            setLoading(false);
            setShowResults(false);
        } else {
            setLoading(true);
            setShowResults(true);
            xhrTimeout.current = setTimeout(() => {
                httpClient.get(`${AppConsts.API_PATH}/users/search`, {
                    params: {
                        query: value,
                        role: "EDITOR"
                    }
                }).then(response => {
                    setLoading(false);
                    if (response.data.users) {
                        setResults(response.data.users
                            .filter(u => !user.logged || user.data.id !== u.id)
                            .map(u => ({
                                id: u.id,
                                name: u.name,
                                email: u.email
                            })));
                    } else {
                        setResults([]);
                    }
                }).catch((e) => {
                    logError(e);
                    setLoading(false);
                });
            }, 350);
        }
    };

    return (<>
        <TextField
            label={t('form_editor.add_a_collaborator_by_email')}
            onChange={(e) => handleOnChange(e)}
            fullWidth
            value={searchValue}
            InputProps={{
                endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null
            }}
        />
        {showResults &&
            (results.length ?
            <List component="div" disablePadding>
                <TransitionGroup>
                    {results.map(u =>
                        <Fade key={u.id} in={true}>
                            <Box>
                                <ListItem secondaryAction={
                                    <Tooltip title={t('form_editor.add_as_collaborator')}>
                                        <IconButton onClick={() => handleAddCollaborator(u)}>
                                            <AddOutlined color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                }>
                                    <ListItemAvatar>
                                        <Avatar sx={{
                                            bgcolor: stringToAvatarColor(u.name)
                                        }}>
                                            {stringAvatar(u.name)}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={u.name} secondary={u.email} />
                                </ListItem>
                            </Box>
                        </Fade>)}
                </TransitionGroup>
            </List>
            :
            <Box sx={{pt: 3}}>
                <Typography color="text.secondary">{t('common.no_results')}</Typography>
            </Box>)}
    </>);
}