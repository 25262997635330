import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import {NavLink} from "react-router-dom";
import {useRef, useState} from "react";
import {Alert, Collapse, Grow, IconButton, Stack} from "@mui/material";
import {TransitionGroup} from "react-transition-group";
import {useUser} from "./UserProvider";
import {LoadingButton} from "@mui/lab";
import {PathsMap, UrlGenerator} from "../app/PageRouting";
import AppConsts from "../app/AppConsts";
import {getDefaultErrorsMapper, useErrors} from "../hooks/useErrors";
import {logError} from "../utils/logging";
import {Page} from "../app/Page";
import {useHistory} from "react-router";
import Paper from "@mui/material/Paper";
import {useTranslation} from 'react-i18next';
import {httpClient} from "../utils/httpClient";
import {
    ArrowBack as ArrowBackIcon,
    EmailOutlined,
    LocalHospitalOutlined
} from "@mui/icons-material";
import MediumButton from "../components/MediumButton";

export function SignIn() {
    const ssoEnabled = useRef(!!process.env.REACT_APP_OAUTH_CLIENT_ID);

    const [modeState, setModeState] = useState(ssoEnabled.current ? 'init' : 'email');
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const user = useUser();
    const {errors, setErrors, resetErrors} = useErrors({
        mapper: getDefaultErrorsMapper(['email', 'password'])
    });

    const handleSubmit = (event) => {
        setLoading(true);

        event.preventDefault();
        const data = new FormData(event.currentTarget);

        httpClient.post(`${AppConsts.API_PATH}/users/auth/signin`, {
            email: data.get('email'),
            password: data.get('password')
        }).then(response => {
            setLoading(false);

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                setErrors([]);
                user.set({
                    tokens: {
                        accessToken: response.data.accessToken,
                        refreshToken: response.data.refreshToken,
                        expiresAt: response.data.expiresAt
                    }
                }).then(() => {
                    setTimeout(() => history.push(PathsMap.HOME), 0);
                });
            }
        }).catch((e) => {
            setLoading(false);
            logError(e);
        });
    };

    const handleSSOLogin = () => {
        const params = {
            client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
            redirect_uri: UrlGenerator.oauthCallback(),
            response_type: 'code',
            scope: 'openid profile email',
            access_type: 'offline',
            state: 'standard_oauth',
            prompt: 'consent'
        };
        const queryString = new URLSearchParams(params).toString();
        window.location.assign(process.env.REACT_APP_OAUTH_AUTH_URL + '?' + queryString);
    };

    return (
        <Page title={t('sign_in.sign_in_title')} maxWidth="sm" centered>
            <Paper sx={{p: 4}} variant="outlined">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h2">
                        {t('sign_in.sign_in_title')}
                    </Typography>

                    <Collapse in={modeState === 'init'}>
                        <MediumButton
                            type="submit"
                            variant="outlined"
                            sx={{mt: 4}}
                            fullWidth
                            onClick={handleSSOLogin}
                            disabled={!ssoEnabled.current}
                        >
                            <Stack spacing={2} alignItems="center" direction="row">
                                <LocalHospitalOutlined sx={{fontSize: 32}} />
                                <Typography>{t('sign_in.sign_in_with_sso')}</Typography>
                            </Stack>
                        </MediumButton>
                        <MediumButton
                            type="submit"
                            variant="outlined"
                            sx={{mt: 2}}
                            fullWidth
                            onClick={() => setModeState("email")}
                        >
                            <Stack spacing={2} alignItems="center" direction="row">
                                <EmailOutlined sx={{fontSize: 32}} />
                                <Typography>{t('sign_in.sign_in_with_email')}</Typography>
                            </Stack>
                        </MediumButton>
                    </Collapse>

                    <Collapse in={modeState === 'email'}>
                        {ssoEnabled.current &&
                            <IconButton onClick={() => setModeState("init")} sx={{mr: 1}}>
                                <ArrowBackIcon/>
                            </IconButton>}
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 2, width: 1}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={t('user.email_address')}
                                name="email"
                                autoComplete="email"
                                autoFocus
                                error={!!errors?.email}
                                helperText={errors?.email}
                                onChange={() => resetErrors('email')}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t('user.password')}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                error={!!errors?.password}
                                helperText={errors?.password}
                                onChange={() => resetErrors('password')}
                            />
                            <TransitionGroup>
                                {errors?.main && errors.main.map((error, errorKey) =>
                                    <Grow in={true} key={errorKey}>
                                        <Alert severity="error" sx={{mt: 2}}>
                                            {error}
                                        </Alert>
                                    </Grow>)}
                            </TransitionGroup>
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                loading={loading}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {t('sign_in.sign_in_button')}
                            </LoadingButton>
                            <Grid container>
                                <Grid item xs>
                                    <Link to={PathsMap.RESET_PWD} component={NavLink} variant="body2">
                                        {t('sign_in.forgot_password_question')}
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link to={PathsMap.SIGN_UP} component={NavLink} variant="body2">
                                        {t('sign_in.don_t_have_an_account_question')}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>

                </Box>
            </Paper>
        </Page>
    );
}
