import React from "react";
import {Slide, Snackbar, Alert, Button} from "@mui/material";

const SnackbarContext = React.createContext(null);

export const useSnackbar = () => React.useContext(SnackbarContext);

export default function SnackbarProvider({children}) {
    const [snackbar, setSnackbar] = React.useState(null);

    const createSnackbar = (option) => {
        setSnackbar({autoHideDuration: 6000, type: null, ...option, open: true});
    };

    const closeSnackbar = () => {
        setSnackbar(snackbar => {
            if (snackbar && snackbar.onClose) {
                snackbar.onClose();
            }
            return null;
        });
    };

    const contextValue = React.useRef({createSnackbar, closeSnackbar});

    const action = !snackbar?.action ? <></> :
        <Button color="secondary" size="small" onClick={() => {
            snackbar.action.onClick();
            closeSnackbar();
        }}>
            {snackbar.action.text}
        </Button>;

    return (
        <SnackbarContext.Provider value={contextValue.current}>
            {children}
            {snackbar &&
                (snackbar.type === null ?
                    <Snackbar
                        open={snackbar.open}
                        message={snackbar.message}
                        action={action}
                        onClose={closeSnackbar}
                        autoHideDuration={snackbar.autoHideDuration}
                        TransitionComponent={Slide}/> :
                    <Snackbar
                        open={snackbar.open}
                        onClose={closeSnackbar}
                        action={action}
                        autoHideDuration={snackbar.autoHideDuration}
                        TransitionComponent={Slide}>
                        <Alert onClose={closeSnackbar} severity={snackbar.type} sx={{width: '100%'}} elevation={4}>
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                )
            }
        </SnackbarContext.Provider>
    );
}