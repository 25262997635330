import {
    AccessTimeOutlined,
    CalendarTodayOutlined,
    CheckBoxOutlined,
    EventNoteOutlined, GradeOutlined, MailOutline,
    RadioButtonCheckedOutlined,
    Person2Outlined,
    Link as LinkIcon,
    DesignServicesOutlined,
    ShortTextOutlined, TextsmsOutlined, WrapTextOutlined
} from "@mui/icons-material";
import AppConsts from "../app/AppConsts";
import {t} from "i18next";

export const FORM = {
    TITLE_MAX_LENGTH: 140,
    DESCRIPTION_MAX_LENGTH: 10000,
    THEME_2_PALETTE: {
        DEFAULT: "default",
        BLUE: "blue",
        GREEN: "green",
        ORANGE: "orange",
        PURPLE: "purple",
        RED: "red",
        TEAL: "teal",
        LIGHT_GREEN: "lightGreen",
        BLUE_GREY: "blueGrey",
        DEEP_ORANGE: "deepOrange",
        INDIGO: "indigo"
    },
    THEMES_ID: {
        DEFAULT: "DEFAULT",
        BLUE: "BLUE",
        GREEN: "GREEN",
        ORANGE: "ORANGE",
        PURPLE: "PURPLE",
        RED: "RED",
        TEAL: "TEAL",
        LIGHT_GREEN: "LIGHT_GREEN",
        BLUE_GREY: "BLUE_GREY",
        DEEP_ORANGE: "DEEP_ORANGE",
        INDIGO: "INDIGO"
    },
    THEMES_DETAILS: [
        {
            id: "DEFAULT",
            name: AppConsts.NAME
        }, {
            id: "BLUE",
            name: "Blue"
        }, {
            id: "GREEN",
            name: "Green"
        }, {
            id: "ORANGE",
            name: "Orange"
        }, {
            id: "PURPLE",
            name: "Purple"
        }, {
            id: "RED",
            name: "Red"
        }, {
            id: "TEAL",
            name: "Teal"
        }, {
            id: "LIGHT_GREEN",
            name: "Light green"
        }, {
            id: "BLUE_GREY",
            name: "Blue grey"
        }, {
            id: "DEEP_ORANGE",
            name: "Deep orange"
        }, {
            id: "INDIGO",
            name: "Indigo"
        }
    ]
}

export const QUESTION = {
    TITLE_MAX_LENGTH: 140,
    DESCRIPTION_MAX_LENGTH: 10000,
    RESPONSE_MAX_LENGTH: 1000,
    PRIMARY_FIELD_ID: 1,
    SECONDARY_FIELD_ID: 2,
    REF: {
        NEXT: "NEXT",
        END: "END"
    },
    TYPES: {
        TEXT: "TEXT",
        MULTILINE_TEXT: "MULTILINE_TEXT",
        SINGLE_CHOICE: "SINGLE_CHOICE",
        MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
        MEASURE: "MEASURE",
        DATE: "DATE",
        DATETIME: "DATETIME",
        RATING: "RATING",
        TIME: "TIME",
        URL: "URL",
        EMAIL: "EMAIL",
        PATIENT: "PATIENT",
        STATEMENT: "STATEMENT"
    },
    TYPES_DETAILS: [
        {
            id: 'TEXT',
            icon: ShortTextOutlined
        }, {
            id: 'MULTILINE_TEXT',
            icon: WrapTextOutlined
        }, {
            id: 'SINGLE_CHOICE',
            icon: RadioButtonCheckedOutlined
        }, {
            id: 'MULTIPLE_CHOICE',
            icon: CheckBoxOutlined
        },{
            id: "MEASURE",
            icon: DesignServicesOutlined
        }, {
            id: 'DATE',
            icon: CalendarTodayOutlined
        }, {
            id: 'DATETIME',
            icon: EventNoteOutlined
        }, {
            id: 'TIME',
            icon: AccessTimeOutlined
        }, {
            id: 'RATING',
            icon: GradeOutlined
        }, {
            id: 'URL',
            icon: LinkIcon
        }, {
            id: 'EMAIL',
            icon: MailOutline
        },{
            id: 'PATIENT',
            icon: Person2Outlined
        }, {
            id: 'STATEMENT',
            icon: TextsmsOutlined
        }
    ],
    MATCH_CONDITION: {
        TYPES: {
            TEXT_IS_NOT_EMPTY: "TEXT_IS_NOT_EMPTY",
            TEXT_IS_EMPTY: "TEXT_IS_EMPTY",
            TEXT_IS_EQUAL: "TEXT_IS_EQUAL",
            TEXT_IS_NOT_EQUAL: "TEXT_IS_NOT_EQUAL",
            TEXT_CONTAINS: "TEXT_CONTAINS",
            TEXT_DOES_NOT_CONTAIN: "TEXT_DOES_NOT_CONTAIN",
            TEXT_BEGINS_WITH: "TEXT_BEGINS_WITH",
            TEXT_ENDS_WITH: "TEXT_ENDS_WITH",
            TEXT_HAS_MIN_N_CHARS: "TEXT_HAS_MIN_N_CHARS",
            TEXT_HAS_N_CHARS: "TEXT_HAS_N_CHARS",
            TEXT_HAS_MAX_N_CHARS: "TEXT_HAS_MAX_N_CHARS",
            CHOICES_NOT_EMPTY: "CHOICES_NOT_EMPTY",
            CHOICE_IS_SELECTED: "CHOICE_IS_SELECTED",
            CHOICE_IS_NOT_SELECTED: "CHOICE_IS_NOT_SELECTED",
            MAX_N_CHOICES_SELECTED: "MAX_N_CHOICES_SELECTED",
            N_CHOICES_SELECTED: "N_CHOICES_SELECTED",
            MIN_N_CHOICES_SELECTED: "MIN_N_CHOICES_SELECTED",
            DATETIME_IS_NOT_EMPTY: "DATETIME_IS_NOT_EMPTY",
            DATETIME_IS_BEFORE: "DATETIME_IS_BEFORE",
            DATETIME_IS_EQUAL: "DATETIME_IS_EQUAL",
            DATETIME_IS_NOT_EQUAL: "DATETIME_IS_NOT_EQUAL",
            DATETIME_IS_AFTER: "DATETIME_IS_AFTER",
            DATE_IS_NOT_EMPTY: "DATE_IS_NOT_EMPTY",
            DATE_IS_BEFORE: "DATE_IS_BEFORE",
            DATE_IS_EQUAL: "DATE_IS_EQUAL",
            DATE_IS_NOT_EQUAL: "DATE_IS_NOT_EQUAL",
            DATE_IS_AFTER: "DATE_IS_AFTER",
            TIME_IS_NOT_EMPTY: "TIME_IS_NOT_EMPTY",
            TIME_IS_BEFORE: "TIME_IS_BEFORE",
            TIME_IS_EQUAL: "TIME_IS_EQUAL",
            TIME_IS_NOT_EQUAL: "TIME_IS_NOT_EQUAL",
            TIME_IS_AFTER: "TIME_IS_AFTER",
            RATING_IS_NOT_EMPTY: "RATING_IS_NOT_EMPTY",
            RATING_IS_EQUAL_OR_HIGHER_THAN: "RATING_IS_EQUAL_OR_HIGHER_THAN",
            RATING_IS_EQUAL_OR_LOWER_THAN: "RATING_IS_EQUAL_OR_LOWER_THAN"
        }
    }
};

export const LEGACY_QUESTION = {
    TYPES: [
        QUESTION.TYPES.SINGLE_CHOICE,
        QUESTION.TYPES.MULTIPLE_CHOICE,
        QUESTION.TYPES.MEASURE,
        QUESTION.TYPES.DATE,
        QUESTION.TYPES.STATEMENT
    ],
    REQUIRED_CONSTRAINT_TYPES: [
        QUESTION.MATCH_CONDITION.TYPES.TEXT_IS_NOT_EMPTY,
        QUESTION.MATCH_CONDITION.TYPES.CHOICES_NOT_EMPTY,
        QUESTION.MATCH_CONDITION.TYPES.DATETIME_IS_NOT_EMPTY,
        QUESTION.MATCH_CONDITION.TYPES.DATE_IS_NOT_EMPTY,
        QUESTION.MATCH_CONDITION.TYPES.TIME_IS_NOT_EMPTY,
        QUESTION.MATCH_CONDITION.TYPES.RATING_IS_NOT_EMPTY
    ],
    EQUAL_CONSTRAINT_TYPES: [
        QUESTION.MATCH_CONDITION.TYPES.TEXT_IS_EQUAL,
        QUESTION.MATCH_CONDITION.TYPES.CHOICE_IS_SELECTED,
        QUESTION.MATCH_CONDITION.TYPES.DATE_IS_EQUAL
    ]
};

export function getRequiredConstraintTypes(questionType) {
    if (questionType === QUESTION.TYPES.TEXT
        || questionType === QUESTION.TYPES.MULTILINE_TEXT
        || questionType === QUESTION.TYPES.EMAIL
        || questionType === QUESTION.TYPES.URL
        || questionType === QUESTION.TYPES.MEASURE
        || questionType === QUESTION.TYPES.PATIENT) {
        return [QUESTION.MATCH_CONDITION.TYPES.TEXT_IS_NOT_EMPTY];
    }

    if (questionType === QUESTION.TYPES.SINGLE_CHOICE
        || questionType === QUESTION.TYPES.MULTIPLE_CHOICE) {
        return [QUESTION.MATCH_CONDITION.TYPES.CHOICES_NOT_EMPTY];
    }

    if (questionType === QUESTION.TYPES.DATETIME) {
        return [QUESTION.MATCH_CONDITION.TYPES.DATETIME_IS_NOT_EMPTY];
    }

    if (questionType === QUESTION.TYPES.DATE) {
        return [QUESTION.MATCH_CONDITION.TYPES.DATE_IS_NOT_EMPTY];
    }

    if (questionType === QUESTION.TYPES.TIME) {
        return [QUESTION.MATCH_CONDITION.TYPES.TIME_IS_NOT_EMPTY];
    }
    if (questionType === QUESTION.TYPES.RATING) {
        return [QUESTION.MATCH_CONDITION.TYPES.RATING_IS_NOT_EMPTY];
    }

    return [];
}

export function geConditionsDescription(questionType, conditions) {
    const matchTypes = getQuestionsMatchTypes(questionType);

    const textConditions = [];
    conditions.forEach(condition => {
        let textKey = matchTypes.find(type => type.value === condition.type).text.optional;
        let textType = t(textKey).replace("…", "");
        let textPattern = getFormattedConditionPattern(condition.type, condition.pattern);
        textConditions.push(textType + " <" + textPattern + ">");
    });

    return t("common.if") + " " + textConditions.join(` ${t("common.and")} `);
}

export function getFormattedConditionPattern(type, pattern) {
    if ((type === QUESTION.MATCH_CONDITION.TYPES.DATETIME_IS_BEFORE
        || type === QUESTION.MATCH_CONDITION.TYPES.DATETIME_IS_EQUAL
        || type === QUESTION.MATCH_CONDITION.TYPES.DATETIME_IS_NOT_EQUAL
        || type === QUESTION.MATCH_CONDITION.TYPES.DATETIME_IS_AFTER)) {
        return new Date(pattern).toLocaleDateString();
    }
    if (type === QUESTION.MATCH_CONDITION.TYPES.DATE_IS_BEFORE
        || type === QUESTION.MATCH_CONDITION.TYPES.DATE_IS_EQUAL
        || type === QUESTION.MATCH_CONDITION.TYPES.DATE_IS_NOT_EQUAL
        || type === QUESTION.MATCH_CONDITION.TYPES.DATE_IS_AFTER) {
        return new Date(pattern).toLocaleDateString();
    }
    if (type === QUESTION.MATCH_CONDITION.TYPES.TIME_IS_BEFORE
        || type === QUESTION.MATCH_CONDITION.TYPES.TIME_IS_EQUAL
        || type === QUESTION.MATCH_CONDITION.TYPES.TIME_IS_NOT_EQUAL
        || type === QUESTION.MATCH_CONDITION.TYPES.TIME_IS_AFTER) {
        return new Date(pattern).toLocaleTimeString();
    }
    return pattern;
}

export function getQuestionsMatchTypes(questionType) {
    if (questionType === QUESTION.TYPES.TEXT
        || questionType === QUESTION.TYPES.MULTILINE_TEXT
        || questionType === QUESTION.TYPES.EMAIL
        || questionType === QUESTION.TYPES.URL
        || questionType === QUESTION.TYPES.MEASURE) {
        return [
            {
                value: QUESTION.MATCH_CONDITION.TYPES.TEXT_IS_NOT_EMPTY,
                text: {
                    optional: 'form_editor.condition_types.is_not_empty',
                    required: 'form_editor.condition_types.is_required'
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.TEXT_IS_EMPTY,
                text: {
                    optional: 'form_editor.condition_types.is_empty',
                    required: 'form_editor.condition_types.must_be_empty'
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.TEXT_IS_EQUAL,
                text: {
                    optional: "form_editor.condition_types.is",
                    required: "form_editor.condition_types.must_be"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.TEXT_IS_NOT_EQUAL,
                text: {
                    optional: "form_editor.condition_types.is_not",
                    required: "form_editor.condition_types.must_not_be"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.TEXT_HAS_MIN_N_CHARS,
                text: {
                    optional: "form_editor.condition_types.has_minimum_n_chars",
                    required: "form_editor.condition_types.must_have_minimum_n_chars"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.TEXT_HAS_N_CHARS,
                text: {
                    optional: "form_editor.condition_types.has_n_chars",
                    required: "form_editor.condition_types.must_have_n_chars"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.TEXT_HAS_MAX_N_CHARS,
                text: {
                    optional: "form_editor.condition_types.has_maximum_n_chars",
                    required: "form_editor.condition_types.must_have_maxiumum_n_char"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.TEXT_CONTAINS,
                text: {
                    optional: "form_editor.condition_types.contains",
                    required: "form_editor.condition_types.must_contain"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.TEXT_DOES_NOT_CONTAIN,
                text: {
                    optional: "form_editor.condition_types.does_not_contain",
                    required: "form_editor.condition_types.must_not_contain"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.TEXT_BEGINS_WITH,
                text: {
                    optional: "form_editor.condition_types.begins_with",
                    required: "form_editor.condition_types.must_begin_with"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.TEXT_ENDS_WITH,
                text: {
                    optional: "form_editor.condition_types.ends_with",
                    required: "form_editor.condition_types.must_end_with"
                }
            }
        ];
    }

    if (questionType === QUESTION.TYPES.PATIENT) {
        return [
            {
                value: QUESTION.MATCH_CONDITION.TYPES.TEXT_IS_NOT_EMPTY,
                text: {
                    optional: 'form_editor.condition_types.is_not_empty',
                    required: 'form_editor.condition_types.is_required'
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.TEXT_IS_EMPTY,
                text: {
                    optional: 'form_editor.condition_types.is_empty',
                    required: 'form_editor.condition_types.must_be_empty'
                }
            }
        ];
    }

    if (questionType === QUESTION.TYPES.SINGLE_CHOICE) {
        return [
            {
                value: QUESTION.MATCH_CONDITION.TYPES.CHOICES_NOT_EMPTY,
                text: {
                    optional: "form_editor.condition_types.has_any_choice_selected",
                    required: "form_editor.condition_types.choice_required"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.CHOICE_IS_SELECTED,
                text: {
                    optional: "form_editor.condition_types.is_selected",
                    required: "form_editor.condition_types.must_be_selected"
                }
            }
        ];
    }

    if (questionType === QUESTION.TYPES.MULTIPLE_CHOICE) {
        return [
            {
                value: QUESTION.MATCH_CONDITION.TYPES.CHOICES_NOT_EMPTY,
                text: {
                    optional: "form_editor.condition_types.is_empty",
                    required: "form_editor.condition_types.is_required"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.CHOICE_IS_SELECTED,
                text: {
                    optional: "form_editor.condition_types.is_selected",
                    required: "form_editor.condition_types.must_be_selected"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.CHOICE_IS_NOT_SELECTED,
                text: {
                    optional: "form_editor.condition_types.is_not_selected",
                    required: "form_editor.condition_types.must_not_be_selected"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.MAX_N_CHOICES_SELECTED,
                text: {
                    optional: "form_editor.condition_types.maximum_n_choices_selected",
                    required: "form_editor.condition_types.maximum_n_choices_must_be"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.N_CHOICES_SELECTED,
                text: {
                    optional: "form_editor.condition_types.n_choices_selected",
                    required: "form_editor.condition_types.n_choices_must_be_selected"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.MIN_N_CHOICES_SELECTED,
                text: {
                    optional: "form_editor.condition_types.minimum_n_choices_selected",
                    required: "form_editor.condition_types.minimum_n_choices_must_be"
                }
            }
        ];
    }

    if (questionType === QUESTION.TYPES.DATETIME) {
        return [
            {
                value: QUESTION.MATCH_CONDITION.TYPES.DATETIME_IS_NOT_EMPTY,
                text: {
                    optional: "form_editor.condition_types.date_time_is_not_empty",
                    required: "form_editor.condition_types.date_time_is_required"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.DATETIME_IS_BEFORE,
                text: {
                    optional: "form_editor.condition_types.date_time_is_before",
                    required: "form_editor.condition_types.date_time_must_be_before"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.DATETIME_IS_EQUAL,
                text: {
                    optional: "form_editor.condition_types.date_time_is_equal",
                    required: "form_editor.condition_types.date_time_must_be_equal"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.DATETIME_IS_NOT_EQUAL,
                text: {
                    optional: "form_editor.condition_types.date_time_is_not_equal",
                    required: "form_editor.condition_types.date_time_must_not_be_equal"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.DATETIME_IS_AFTER,
                text: {
                    optional: "form_editor.condition_types.date_time_is_after",
                    required: "form_editor.condition_types.date_time_must_be_after"
                }
            }
        ].concat(
            getQuestionsMatchTypes(QUESTION.TYPES.DATE)
                .filter(t => t.value !== QUESTION.MATCH_CONDITION.TYPES.DATE_IS_NOT_EMPTY));
    }

    if (questionType === QUESTION.TYPES.DATE) {
        return [
            {
                value: QUESTION.MATCH_CONDITION.TYPES.DATE_IS_NOT_EMPTY,
                text: {
                    optional: "form_editor.condition_types.date_is_not_empty",
                    required: "form_editor.condition_types.date_is_required"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.DATE_IS_BEFORE,
                text: {
                    optional: "form_editor.condition_types.date_is_before",
                    required: "form_editor.condition_types.date_must_be_before"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.DATE_IS_EQUAL,
                text: {
                    optional: "form_editor.condition_types.date_is_equal",
                    required: "form_editor.condition_types.date_must_be_equal"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.DATE_IS_NOT_EQUAL,
                text: {
                    optional: "form_editor.condition_types.date_is_not_equal",
                    required: "form_editor.condition_types.date_must_not_be_equal"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.DATE_IS_AFTER,
                text: {
                    optional: "form_editor.condition_types.date_is_after",
                    required: "form_editor.condition_types.date_must_be_after"
                }
            }
        ];
    }

    if (questionType === QUESTION.TYPES.TIME) {
        return [
            {
                value: QUESTION.MATCH_CONDITION.TYPES.TIME_IS_NOT_EMPTY,
                text: {
                    optional: "form_editor.condition_types.time_is_not_empty",
                    required: "form_editor.condition_types.is_required"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.TIME_IS_BEFORE,
                text: {
                    optional: "form_editor.condition_types.time_is_before",
                    required: "form_editor.condition_types.time_must_be_before"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.TIME_IS_EQUAL,
                text: {
                    optional: "form_editor.condition_types.time_is_equal",
                    required: "form_editor.condition_types.time_must_be_equal"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.TIME_IS_NOT_EQUAL,
                text: {
                    optional: "form_editor.condition_types.time_is_not_equal",
                    required: "form_editor.condition_types.time_must_not_be_equal"
                }
            }, {
                value: QUESTION.MATCH_CONDITION.TYPES.TIME_IS_AFTER,
                text: {
                    optional: "form_editor.condition_types.time_is_after",
                    required: "form_editor.condition_types.time_must_be_after"
                }
            }
        ];
    }

    if (questionType === QUESTION.TYPES.RATING) {
        return [
            {
                value: QUESTION.MATCH_CONDITION.TYPES.RATING_IS_NOT_EMPTY,
                text: {
                    optional: "form_editor.condition_types.is_not_empty",
                    required: "form_editor.condition_types.is_required"
                }
            },
            {
                value: QUESTION.MATCH_CONDITION.TYPES.RATING_IS_EQUAL_OR_HIGHER_THAN,
                text: {
                    optional: "form_editor.condition_types.is_equal_or_higher_than",
                    required: "form_editor.condition_types.must_be_equal_or_higher_than"
                }
            },
            {
                value: QUESTION.MATCH_CONDITION.TYPES.RATING_IS_EQUAL_OR_LOWER_THAN,
                text: {
                    optional: "form_editor.condition_types.rating_is_equal_or_lower_than",
                    required: "form_editor.condition_types.must_be_equal_or_lower_than"
                }
            }
        ];
    }

    return [];
}